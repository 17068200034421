import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import ChatPanel from '../../../Shared/Chat/ChatPanel';
import { getActivityChatListForCandidateAction } from '../../../../redux/entities/entities.actions';
import { getCandidatePageActivitiesRequestObject } from '../../../../selectors/activity.selectors';
import { getToken } from '../../../../selectors/app.selectors';
import FastSearchPanelActivityList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelActivityList';
import { setUiActivityAddDataAction } from '../../../../redux/ui/ui.actions';
import { EActivityModule } from '../../../../definitions/entities.definition';
import { setCandidatePageActivityFilterAction } from '../../../../redux/candidatePage/candidatePage.actions';
import { setVacancyPageSuggestionToAddActivityAction } from '../../../../redux/vacancyPage/vacancyPage.actions';

interface IProps {
    candidateId: number;
    suggestionId?: number;
}

const VacancyActivityChatPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;

    const filter = useSelector((store: IStore) => store.candidatePage.activityFilter);
    const sorting = useSelector((store: IStore) => store.candidatePage.activitySorting);
    const loading = useSelector((store: IStore) => store.ui.activitiesLoading);
    const chat = useSelector((store: IStore) => store.entities.activityChat.byCandidate[candidateId]);
    const requestObject = useSelector(getCandidatePageActivitiesRequestObject);
    const [initialLoaded, setInitialLoaded] = useState(false);
    const total = useSelector((store: IStore) => store.entities.activityChat.byCandidate[candidateId]?.total);
    const reload = useSelector((store: IStore) => store.ui.reloadActivityGrids);

    const loadChat = () => {
        if (loading) {
            return;
        }
        dispatch(getActivityChatListForCandidateAction(token, candidateId, requestObject));
        setInitialLoaded(true);
    }

    useEffect(() => {
        if (reload && !loading) {
            dispatch(getActivityChatListForCandidateAction(token, candidateId, requestObject));
        }
    }, [reload]);

    useEffect(() => {
        if (!initialLoaded) {
            return;
        }
        loadChat();
    }, [JSON.stringify(filter), JSON.stringify(sorting)]);

    useEffect(() => {
        loadChat();
    }, []);

    if (!chat) {
        return <><CustomBackdropLoadingInsideDiv open={true}/></>
    }

    return <>
        <CustomBackdropLoadingInsideDiv open={loading}/>
        <div className={"padding5"}>
            <FastSearchPanelActivityList
                fastSearch={''}
                setFastSearch={(fastSearch) => dispatch(setCandidatePageActivityFilterAction({fastSearch}))}
                addAction={() => {
                    if (props.suggestionId) {
                        dispatch(setVacancyPageSuggestionToAddActivityAction(props.suggestionId));
                        return;
                    }

                    dispatch(setUiActivityAddDataAction({
                        add: true,
                        module: EActivityModule.candidate,
                        candidateId
                    }))
                }}
            />
        </div>
        <ChatPanel
            chat={chat}
            total={total}
            onReload={() => dispatch(getActivityChatListForCandidateAction(token, candidateId, requestObject))}
        />
    </>;
};

export default VacancyActivityChatPanel;
