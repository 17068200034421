import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EEntities, ERecruitingStatus, IRecruitingListModel } from '../../../../definitions/entities.definition';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { getToken } from '../../../../selectors/app.selectors';
import { darkest } from '../../../../theme/theme';
import CustomTabContainer from '../../../../components/CustomTabContainer/CustomTabContainer';
import { ClientApi } from '../../../../requests/ClientApi';
import { getRecruitingGroupedListRouteConfig } from '../../../../requests/routes';
import RecruitingList from '../../../RecruitingPage/components/RecruitingList';

interface IProps {
    year: number;
    month?: number;
    employeeId?: number;
}

const ChartSelectionRecruitingRecruitingView: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const year = props.year;
    const month = props.month;
    const employeeId = props.employeeId;

    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [contracts, setContracts] = useState<IRecruitingListModel[]>([]);
    const [suggestions, setSuggestions] = useState<IRecruitingListModel[]>([]);
    const [interviews, setInterviews] = useState<IRecruitingListModel[]>([]);
    const [aborts, setAborts] = useState<IRecruitingListModel[]>([]);

    const load = () => {
        if (loading) {
            return;
        }
        setLoading(true);
        ClientApi.request(getRecruitingGroupedListRouteConfig, {
            token,
            month,
            year,
            employeeId
        }).then((result) => {
            setContracts(result.contracts);
            setAborts(result.aborts);
            setInterviews(result.interviews);
            setSuggestions(result.suggestions);
            setReload(false);
            setLoading(false);
        }).catch(() => {
            setReload(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        load();
    }, [dispatch, year, month]);

    useEffect(() => {
        if (!reload) {
            return;
        }
        load();
    }, [reload]);

    return <>
        <CustomTabContainer
            roundBorder
            color={darkest}
            style={{
                borderLeft: '4px solid var(--brainz-darkest)',
                borderRight: '4px solid var(--brainz-darkest)',
                borderBottom: '4px solid var(--brainz-darkest)'
            }}
            tabs={[{
                label: <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.ThumbUp)}
                    &nbsp;
                    Vermittlungen ({contracts.length})
                </div>,
                component:
                    <>
                        <RecruitingList
                            status={ERecruitingStatus.STATUS_CONTRACT}
                            recruitings={contracts}
                            entity={EEntities.RecruitingsContract}
                            count={contracts.length}
                            total={contracts.length}
                        />
                    </>
            }, {
                label: <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.ThumbDown)}
                    &nbsp;
                    Absagen ({aborts.length})
                </div>,
                component: <>
                    <RecruitingList
                        status={ERecruitingStatus.STATUS_ABORT}
                        recruitings={aborts}
                        entity={EEntities.RecruitingsAbort}
                        count={aborts.length}
                        total={aborts.length}
                    />
                </>
            }, {
                label: <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.ThumbDown)}
                    &nbsp;
                    Vorschläge ({suggestions.length})
                </div>,
                component: <>
                    <RecruitingList
                        status={ERecruitingStatus.STATUS_SUGGEST}
                        recruitings={suggestions}
                        entity={EEntities.RecruitingsSuggest}
                        count={suggestions.length}
                        total={suggestions.length}
                    />
                </>
            }, {
                label: <div className={"flexContainerRow alignVerticalCenter"}>
                    {IconFactory.getIconComponent(EIcons.ThumbDown)}
                    &nbsp;
                    Interviews ({interviews.length})
                </div>,
                component: <>
                    <RecruitingList
                        status={ERecruitingStatus.STATUS_INTERVIEW}
                        recruitings={interviews}
                        entity={EEntities.RecruitingsInterview}
                        count={interviews.length}
                        total={interviews.length}
                    />
                </>
            }]}/>
        </>;
};

export default ChartSelectionRecruitingRecruitingView;
