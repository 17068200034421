import React, { PropsWithChildren } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.light,
        },
        basePage: {
            height: "100%",
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            marginLeft: 58
        },
    })
);

const BasePage: React.FC<PropsWithChildren<any>> = (props) => {
    const styles = useStyles();
    const className = styles.basePage;

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row"
            }}
            className={className}
        >
            <div
                id={"basePage"}
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    marginTop: 65,
                    height: "93%",
                    background: 'var(--brainz-darker)',
                }}
            >
                {props.children}
            </div>
        </div>
    );
};

export default React.memo(BasePage);
