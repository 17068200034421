import React from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { consumeError, consumeInfo, consumeSuccess } from '../../redux/error/error.actions';
import { translate } from '../../translation/translate.utils';

type TAlertSeverity = "success" | "info" | "warning" | "error";

interface IProps {
    hasError: boolean;
    message?: string;
    hasSuccess: boolean;
    hasInfo: boolean;
}

const CustomSnackbar: React.FC<IProps> = (props) => {
    if (!props.hasError && !props.hasSuccess && !props.hasInfo) {
        return null;
    }

    const dispatch = useDispatch();
    let severity = "error" as TAlertSeverity;
    let message = props.message;
    const onClose = () => {
        if (props.hasError) {
            dispatch(consumeError());
        }

        if (props.hasSuccess) {
            dispatch(consumeInfo());
        }

        if (props.hasInfo) {
            dispatch(consumeSuccess());
        }
    };

    if (props.hasInfo) {
        severity = "info";
    }

    if (props.hasSuccess) {
        severity = "success";
        message = message || translate('misc.dataSaveSuccess');
    }

    if (!message) {
        return null;
    }

    if (message === 'Cv Condition not valid') {
        return null;
    }

    return (
        <div>
            <Snackbar open={true} autoHideDuration={5000} onClose={onClose} onClick={onClose}>
                <MuiAlert elevation={6} variant="filled" severity={severity}>
                    {message}
                </MuiAlert>
            </Snackbar>
        </div>
    );
};

export default CustomSnackbar;
