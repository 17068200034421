import { asyncActionCreator } from '../../utils/actions.utils';
import { ClientApi } from '../../requests/ClientApi';
import { getDashboardDataRouteConfig } from '../../requests/routes';
import {
    ISetDashboardPageBirthdayCandidatesViewOpen,
    ISetDashboardPageBirthdayCompanyContactsViewOpen,
    ISetDashboardPageChartSelectionRecruitingsViewOpen,
    ISetDashboardPageFilterAction,
    ISetDashboardPageGoalBurnUpChartSettings,
    ISetDashboardPagePieChartsGroupedByCommission,
    ISetDashboardPageRecruitingsOfLast7DaysViewOpen,
    ISetDashboardPageSetChartSelection,
    ISetDashboardPageSortingOptionAction,
    ISetDashboardPageTopSuggestionsViewOpen,
    ISetDashboardPageYourAppointmentsViewOpen,
    ISetDashboardPageYourRecruitingsViewOpen,
} from '../../definitions/redux/actions.definition';
import {
    EDashboardPageTable,
    IChartSelection,
    IDashboardFilterRecruitingsOfLast7Days,
    IGoalBurnUpChartSettings,
} from '../../definitions/dashboardPage.definitions';
import { ESortingOptions } from '../../definitions/components.definitions';

export const DASHBOARD_PAGE_SET_OPEN_APPLICATIONS_VIEW_OPEN = "DASHBOARD_PAGE_SET_OPEN_APPLICATIONS_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_NEW_CANDIDATES_VIEW_OPEN = "DASHBOARD_PAGE_SET_NEW_CANDIDATES_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_TOP_SUGGESTIONS_VIEW_OPEN = "DASHBOARD_PAGE_SET_TOP_SUGGESTIONS_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_BIRTHDAY_CANDIDATES_VIEW_OPEN = "DASHBOARD_PAGE_SET_BIRTHDAY_CANDIDATES_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_BIRTHDAY_COMPANY_CONTACTS_VIEW_OPEN = "DASHBOARD_PAGE_SET_BIRTHDAY_COMPANY_CONTACTS_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_YOUR_APPOINTMENTS_VIEW_OPEN = "DASHBOARD_PAGE_SET_YOUR_APPOINTMENTS_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_YOUR_RECRUITINGS_VIEW_OPEN = "DASHBOARD_PAGE_SET_YOUR_RECRUITINGS_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_RECRUITINGS_OF_LAST_7_DAYS_VIEW_OPEN = "DASHBOARD_PAGE_SET_RECRUITINGS_OF_LAST_7_DAYS_VIEW_OPEN";

export const DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA = "DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA";
export const DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_FULFILLED = "DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_FULFILLED";
export const DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_PENDING = "DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_PENDING";
export const DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_REJECTED = "DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA_REJECTED";

export const DASHBOARD_PAGE_SET_GOAL_BURN_UP_CHART_SETTINGS = "DASHBOARD_PAGE_SET_GOAL_BURN_UP_CHART_SETTINGS"
export const DASHBOARD_PAGE_SET_PIE_CHARTS_GROUPED_BY_COMMISSION = "DASHBOARD_PAGE_SET_PIE_CHARTS_GROUPED_BY_COMMISSION";
export const DASHBOARD_PAGE_SET_CHART_SELECTION = "DASHBOARD_PAGE_SET_CHART_SELECTION";
export const DASHBOARD_PAGE_SET_CHART_SELECTION_RECRUITINGS_VIEW_OPEN = "DASHBOARD_PAGE_SET_CHART_SELECTION_RECRUITINGS_VIEW_OPEN";
export const DASHBOARD_PAGE_SET_SORTING = "DASHBOARD_PAGE_SET_SORTING";
export const DASHBOARD_PAGE_SET_FILTER = "DASHBOARD_PAGE_SET_FILTER";


export const setDashboardPageSortingOptionAction = (table: EDashboardPageTable, propertyToSort: string, sortOption: ESortingOptions): ISetDashboardPageSortingOptionAction => ({
    type: DASHBOARD_PAGE_SET_SORTING,
    payload: {
        table,
        propertyToSort,
        sortOption,
    },
});

export const setDashboardPageFilterAction = (table: EDashboardPageTable, filter: IDashboardFilterRecruitingsOfLast7Days): ISetDashboardPageFilterAction => ({
    type: DASHBOARD_PAGE_SET_FILTER,
    payload: {
        table,
        filter
    },
});

export const setDashboardPageChartSelectionAction = (chartSelection: IChartSelection): ISetDashboardPageSetChartSelection => ({
    type: DASHBOARD_PAGE_SET_CHART_SELECTION,
    payload: chartSelection,
});
export const setDashboardPageTopSuggestionsViewOpenAction = (open: boolean): ISetDashboardPageTopSuggestionsViewOpen => ({
    type: DASHBOARD_PAGE_SET_TOP_SUGGESTIONS_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageBirthdayCandidatesViewOpenAction = (open: boolean): ISetDashboardPageBirthdayCandidatesViewOpen => ({
    type: DASHBOARD_PAGE_SET_BIRTHDAY_CANDIDATES_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageBirthdayCompanyContactsViewOpenAction = (open: boolean): ISetDashboardPageBirthdayCompanyContactsViewOpen => ({
    type: DASHBOARD_PAGE_SET_BIRTHDAY_COMPANY_CONTACTS_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageYourAppointmentsViewOpenAction = (open: boolean): ISetDashboardPageYourAppointmentsViewOpen => ({
    type: DASHBOARD_PAGE_SET_YOUR_APPOINTMENTS_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageYourRecruitingsViewOpenAction = (open: boolean): ISetDashboardPageYourRecruitingsViewOpen => ({
    type: DASHBOARD_PAGE_SET_YOUR_RECRUITINGS_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageRecruitingsOfLast7DaysViewOpenAction = (open: boolean): ISetDashboardPageRecruitingsOfLast7DaysViewOpen => ({
    type: DASHBOARD_PAGE_SET_RECRUITINGS_OF_LAST_7_DAYS_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageChartSelectionRecruitingsViewOpenAction = (open: boolean): ISetDashboardPageChartSelectionRecruitingsViewOpen => ({
    type: DASHBOARD_PAGE_SET_CHART_SELECTION_RECRUITINGS_VIEW_OPEN,
    payload: open,
});
export const setDashboardPageBurnUpSettingsAction = (settings: IGoalBurnUpChartSettings): ISetDashboardPageGoalBurnUpChartSettings => ({
    type: DASHBOARD_PAGE_SET_GOAL_BURN_UP_CHART_SETTINGS,
    payload: settings,
});
export const setDashboardPagePieChartGroupedByCommissionAction = (groupedByCommission: boolean): ISetDashboardPagePieChartsGroupedByCommission => ({
    type: DASHBOARD_PAGE_SET_PIE_CHARTS_GROUPED_BY_COMMISSION,
    payload: groupedByCommission,
});

/**
 * ANALYTICS
 */
export const setDashboardPageEmployeeDataAction = (token: string) =>
    asyncActionCreator(DASHBOARD_PAGE_ANALYTICS_GET_EMPLOYEE_DATA, () =>
        ClientApi.request(getDashboardDataRouteConfig, { token })
    );
