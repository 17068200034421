import React, { useEffect, useState } from 'react';
import { translate } from '../../translation/translate.utils';
import CustomTabContainer from '../../components/CustomTabContainer/CustomTabContainer';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getInvoiceListAction, getInvoiceListOpenAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import {
    getBillingPageInvoiceListRequestObject,
    getInvoiceListFromStore,
    getInvoiceOpenListFromStore,
} from '../../selectors/billing.selectors';
import InvoiceGenerationDialog from './components/InvoiceGenerationDialog';
import AddPaymentDialog from './components/components/AddPaymentDialog';
import BillingPageDashboard from './components/BillingPageDashboard';
import { EBillingPageTabs } from '../../definitions/billingPage.definitions';
import {
    setBillingPageActiveTabAction,
    setBillingPageAddInvoiceDialogAction,
    setBillingPageInvoiceGenerationDialogAction,
    setBillingPageInvoiceListFilterAction,
} from '../../redux/billingPage/billingPage.actions';
import { themePurple } from '../../theme/theme';
import { INFINITE_SCROLL_INTERVAL } from '../../utils/components.utils';
import InvoiceList from './components/InvoiceList';
import { IFutureInvoice } from '../../definitions/entities.definition';
import { ClientApi } from '../../requests/ClientApi';
import { getFutureInvoiceListRouteConfig } from '../../requests/routes';
import FutureInvoiceList from './components/FutureInvoiceList';
import TopBarButton from '../../components/ListAndPreview/TopBarButton';
import { EIcons } from '../../components/Icons/IconFactory';
import { Badge } from '@material-ui/core';

const BillingPage: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);

    const countBillable = useSelector((store: IStore) => store.dashboardPage.employeeData.countBillableInvoices);
    const activeTab = useSelector((store: IStore) => store.billingPage.activeTab);
    const invoiceSorting = useSelector((store: IStore) => JSON.stringify(store.billingPage.sortingInvoiceList));
    const invoiceFilter = useSelector((store: IStore) => JSON.stringify(store.billingPage.filterInvoiceList));
    const reloadInvoiceList = useSelector((store: IStore) => store.billingPage.reloadInvoiceList);
    const invoices = useSelector(getInvoiceListFromStore);
    const invoicesOpen = useSelector(getInvoiceOpenListFromStore);
    const loading = useSelector((store: IStore) => store.ui.invoicesLoading);
    const filter = useSelector((store: IStore) => store.billingPage.filterInvoiceList);
    const totalRecords = useSelector((store: IStore) => store.entities.invoice.total);
    const requestObject = useSelector(getBillingPageInvoiceListRequestObject);
    const [futureInvoices, setFutureInvoices] = useState<IFutureInvoice[]>([]);
    const [futureSum, setFutureSum] = useState(0);
    const [loadingFutureInvoices, setLoadingFutureInvoices] = useState(false);
    const openSum = useSelector((store: IStore) => store.billingPage.openSum);

    const loadFutureInvoices = () => {
        setLoadingFutureInvoices(true);
        ClientApi.request(getFutureInvoiceListRouteConfig, {
            token
        }).then((result) => {
            setLoadingFutureInvoices(false);
            setFutureInvoices(result.records)
            setFutureSum(result.sum);
        });
    }

    useEffect(() => {
        onReloadOpen();
        loadFutureInvoices();
    }, [dispatch]);

    useEffect(() => {
        onReload();
    }, [dispatch, invoiceSorting, invoiceFilter]);

    useEffect(() => {
        if (reloadInvoiceList) {
            onReload();
            onReloadOpen();
            loadFutureInvoices();
        }
    }, [reloadInvoiceList]);

    const scrollCallback = () => {
        if (filter.limit && filter.limit < totalRecords) {
            dispatch(setBillingPageInvoiceListFilterAction({
                start: 0,
                limit: filter.limit + INFINITE_SCROLL_INTERVAL,
            }));
        }
    }

    const onReload = () => {
        dispatch(getInvoiceListAction(token, requestObject));
    }
    const onReloadOpen = () => {
        dispatch(getInvoiceListOpenAction(token));
    }

    return (
        <>
            <InvoiceGenerationDialog />
            <AddPaymentDialog />
            <div className={"marginTop5 marginLeft5 flexContainerColumn flex1"} style={{overflow: 'hidden'}}>
                <CustomTabContainer
                    color={themePurple.palette.primary.main}
                    activeTab={activeTab}
                    onTabChange={(tabId: EBillingPageTabs) =>dispatch(setBillingPageActiveTabAction(tabId))}
                    tabs={[{
                        label: translate('pages.dashboard.title'),
                        component: (<BillingPageDashboard />),
                    }, {
                        label: 'Offen (' + invoicesOpen.length + ')',
                        component: <>
                            <div className={"padding5 flex paddingTop10 flexAutoGrow gap10"} style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}}>
                                <TopBarButton
                                    onClick={() => dispatch(setBillingPageAddInvoiceDialogAction(true))}
                                    icon={EIcons.Add}
                                    label={'Rechnung manuell erstellen'}
                                />
                                <Badge color={"error"} badgeContent={countBillable.toString()}>
                                    <TopBarButton
                                        onClick={() => dispatch(setBillingPageInvoiceGenerationDialogAction(true))}
                                        icon={EIcons.Money}
                                        label={'Fällige Rechnungen erstellen'}
                                    />
                                </Badge>
                            </div>
                            <InvoiceList
                                onReload={onReloadOpen}
                                loading={loading}
                                invoices={invoicesOpen}
                                disableOrder
                                sum={openSum}
                            />
                        </>,
                    }, {
                        label: 'Ausstehend (' + futureInvoices.length + ')',
                        component: <FutureInvoiceList
                            invoices={futureInvoices}
                            onReload={loadFutureInvoices}
                            loading={loadingFutureInvoices}
                            sum={futureSum}
                        />,
                    }, {
                        label: 'Archiv',
                        component: <InvoiceList
                            onReload={onReload}
                            loading={loading}
                            hideStatusColumn
                            invoices={invoices}
                            scrollCallback={scrollCallback}
                            totalRecords={totalRecords}
                        />,
                    }]}
                />
            </div>
        </>
    );
}

export default BillingPage;
