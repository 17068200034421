import { IApiBaseFilter, ISorting } from './app.definitions';
import {
    ECandidateLevel,
    ECandidateSource,
    EEducationLevel,
    EHomeOfficeDesire,
    ICandidateAddress,
    ICandidateLanguage,
    ICandidateMailAddress,
    ICandidatePhoneNumber,
    ICandidateSkill,
    ICandidateUrl,
    ICompanyLocation,
    ICompanyLocationPartial,
    ICvParseResult,
    IEmployeePartial,
    ILanguage,
    ISkill,
    IVacancyPartial,
    TNoticePeriod,
    TPartTimeFullTime,
    TSalutation,
    TWorkExperience,
} from './entities.definition';
import { ESortingOptions } from './components.definitions';
import { IRecruitingFilter, IRecruitingFilterMenu } from './recruitingPage.definitions';
import { FileObject } from 'material-ui-dropzone';
import { IWebVacancyFilter, IWebVacancyFilterMenu } from './webVacancyPage.definitions';
import {
    EDateRangeMode,
    IDateRangeFilterApiProperties,
    IRecruitingInformationFilterApiProperties,
    IRecruitingInformationFilterMenu,
} from './filter.definitions';

export const preloadedCandidatePageDateRangeFilter: IDateRangeFilterApiProperties = {
    property: 'created',
    mode: EDateRangeMode.all,
    dateFrom: undefined,
    dateTo: undefined,
    lastDateRangeCount: undefined
}

export const preloadedCandidatePageFilter: ICandidateFilterApiProperties = {
    start: 0,
    limit: 30,
    skillIds: [],
    languageIds: [],
    optionalSkillIds: [],
    dateRangeFilter: preloadedCandidatePageDateRangeFilter,
    homeOfficeDesire: []
}

export const preloaededCandidatePageFilterMenu: ICandidateFilterMenu = {
    skills: [],
    optionalSkills: [],
    languages: [],
    onlyFollowups: false,
    responsibleEmployee: null,
    dateRangeFilter: preloadedCandidatePageDateRangeFilter,
    homeOfficeDesire: []
}



export interface ICandidateForm {
    name: string,
    firstName: string,
    externalReference: string,
    freelancer: boolean,
    salutation: TSalutation,
    title: string,
    birthDate: null|string,
    salaryUnit: 'year'|'month',
    expectedSalary: number,
    expectedSalaryTo: number,
    homeOfficeDesire: EHomeOfficeDesire|null,
    noticePeriod: null|TNoticePeriod,
    leader: boolean,
    yearsOfWorkExperience: null|TWorkExperience,
    desiredJobDescription: string,
    currentJobDescription: string,
    hasDriverLicense?: boolean,
    wishDateOfStart: string,
    partTimeFullTime: null|TPartTimeFullTime;
    skills: ICandidateSkill[];
    languages: ICandidateLanguage[];
    mailAddresses: ICandidateMailAddress[];
    phoneNumbers: ICandidatePhoneNumber[];
    urls: ICandidateUrl[];
    addresses: ICandidateAddress[];
    comment: string;
    responsible?: IEmployeePartial;
    topCandidate: boolean;
    topInCommunication: boolean;
    moveWillingness: string;
    typus: string;
    proudOf: string;
    thoughtsAboutTeamAndAppreciation: string;
    flexibility: string;
    newsletterEnabled: boolean;
    source: ECandidateSource;
    followupDate: string|null;
    imagePath?: string;
    imageUrl?: string;
    followupComment: string;
    applicationVacancy: null|IVacancyPartial;
    topInPotential: boolean;
    numberOfEmployers?: number;
    level?: ECandidateLevel,
    educationLevel?: EEducationLevel;
}

export const initialCandidateFormState: ICandidateForm = {
    firstName: '',
    leader: false,
    birthDate: null,
    comment: '',
    currentJobDescription: '',
    desiredJobDescription: '',
    expectedSalary: 0,
    expectedSalaryTo: 0,
    flexibility: '',
    followupComment: '',
    freelancer: false,
    homeOfficeDesire: null,
    moveWillingness: '',
    name: '',
    topCandidate: false,
    newsletterEnabled: true,
    noticePeriod: null,
    partTimeFullTime: "FULL_TIME",
    proudOf: '',
    salutation: 'mr',
    source: ECandidateSource.homepage,
    thoughtsAboutTeamAndAppreciation: '',
    title: '',
    topInCommunication: false,
    typus: '',
    wishDateOfStart: '',
    yearsOfWorkExperience: null,
    languages: [],
    skills: [],
    salaryUnit: 'year',
    applicationVacancy: null,
    topInPotential: false,
    externalReference: '',
    urls: [],
    addresses: [],
    mailAddresses: [],
    phoneNumbers: [],
    imageUrl: undefined,
    numberOfEmployers: undefined,
    level: undefined,
    educationLevel: undefined,
    imagePath: undefined,
    responsible: undefined,
    followupDate: null,
    hasDriverLicense: undefined
}

/*export const initialCandidateFormState: ICandidateForm = {
    firstName: 'Daniel',
    leader: false,
    birthDate: '1988-09-11',
    comment: 'test',
    currentJobDescription: 'test',
    desiredJobDescription: 'test',
    expectedSalary: 10,
    expectedSalaryTo: 30,
    flexibility: 'test',
    followupComment: '',
    freelancer: false,
    homeOfficeDesire: EHomeOfficeDesire.complete,
    moveWillingness: 'test',
    name: 'Beier22',
    topCandidate: true,
    educationLevel: EEducationLevel.education,
    level: ECandidateLevel.professional,
    numberOfEmployers: 4,
    newsletterEnabled: true,
    noticePeriod: '4_weeks',
    partTimeFullTime: "FULL_TIME",
    proudOf: 'test',
    salutation: 'mr',
    source: ECandidateSource.homepage,
    thoughtsAboutTeamAndAppreciation: 'asfd',
    title: 'dipl. ing.',
    topInCommunication: true,
    typus: 'test',
    wishDateOfStart: '',
    yearsOfWorkExperience: 'more_than_10',
    languages: [],
    skills: [],
    salaryUnit: 'year',
    applicationVacancy: null,
    topInPotential: false,
    externalReference: '',
    urls: [{
        id: 0,
        type: 'linkedIn',
        url: 'linkedin.de/beier',
        comment: 'Test'
    }],
    addresses: [{
        id: 0,
        address: 'Eibenweg 6',
        comment: 'Test',
        city: 'Salzbergen',
        zipCode: '48499',
        default: true
    }],
    mailAddresses: [{
        id: 0,
        mailAddress: 'beier-daniel@gmx.net',
        default: true,
        comment: 'test'
    }],
    phoneNumbers: [{
        id: 0,
        phoneNumber: '01725665344',
        default: true,
        comment: 'test'
    }]
}*/

export const preloadedStateCandidatePage: ICandidatePageState = {
    reloadList: false,
    reloadRecruitingsOfCandidate: false,
    reloadProcessesOfCandidate: false,
    formFieldsIgnoredForValidation: [],
    recruitingSorting: {
        'created': ESortingOptions.Descending
    },
    initialCandidatesLoaded: false,
    sorting: {
        'candidate.created': ESortingOptions.Descending
    },
    filter: preloadedCandidatePageFilter,
    filterMenu: preloaededCandidatePageFilterMenu,
    birthdayWishMissing: false,
    addDialogOpen: false,
    candidateToDelete: 0,
    currentDocumentId: 0,
    documentSorting: {},
    activitySorting: {
        dateTime: ESortingOptions.Descending,
    },
    activityToDelete: 0,
    filterRecruitingList: {
        start: 0,
        limit: 50,
    },
    filterMenuRecruitingList: {},
    activityFilter: {
        start: 0,
        limit: 50
    },
    sortingVacancyList: {
        'created': ESortingOptions.Descending
    },
    filterVacancyList: {
        start: 0,
        limit: 50,
        showHidden: false
    },
    filterMenuVacancyList: {
        showHidden: false
    },
    createBirthdayMailForCandidate: 0,
    candidateForm: initialCandidateFormState
};

export enum ECandidateEditViewTabs {
    data,
    professions,
    vacancySearch,
    recruitingList,
    activityList,
    documentList
}

export interface ICandidatePageState {
    candidateForm: ICandidateForm;
    reloadList: boolean;
    reloadRecruitingsOfCandidate: boolean;
    reloadProcessesOfCandidate: boolean;
    formFieldsIgnoredForValidation: string[];
    importFromCvParser?: ICvParseResult;
    createBirthdayMailForCandidate: number;
    importCvFile?: FileObject;
    activeTabCandidateEditView?: ECandidateEditViewTabs,
    recruitingSorting: ISorting;
    initialCandidatesLoaded: boolean;
    sorting: ISorting;
    filter: ICandidateFilterApiProperties;
    filterMenu: ICandidateFilterMenu;
    birthdayWishMissing: boolean;
    candidateToDelete: number;
    addDialogOpen: boolean;
    currentDocumentId: number;
    documentSorting: ISorting;
    activitySorting: ISorting;
    activityFilter: IApiBaseFilter;
    activityToDelete: number;
    filterRecruitingList: IRecruitingFilter;
    filterMenuRecruitingList: IRecruitingFilterMenu;
    sortingVacancyList: ISorting;
    filterVacancyList: Partial<IWebVacancyFilter>,
    filterMenuVacancyList: Partial<IWebVacancyFilterMenu>,
}

export interface ICandidateFilterApiProperties extends Partial<IApiBaseFilter> {
    hasDocuments?: boolean;
    topCandidate?: boolean;
    topInCommunication?: boolean;
    salutation?: string;
    skillIds: number[];
    optionalSkillIds: number[];
    leader?: boolean;
    yearsOfWorkExperience?: string;
    distanceSearchCompanyLocationId?: number;
    distanceSearchKm?: number;
    ageFrom?: number;
    ageTo?: number;
    responsibleEmployeeId?: number;
    jobDescription?: string;
    salaryFrom?: number;
    salaryTo?: number;
    languageIds: number[];
    searchForVacancyId?: number;
    searchForWebVacancyId?: number;
    onlyFollowups?: boolean;
    source?: ECandidateSource;
    isFreelancer?: boolean;
    hasDriverLicense?: boolean;
    homeOfficeDesire: EHomeOfficeDesire[];
    dateRangeFilter: IDateRangeFilterApiProperties;
    recruitingFilter?: IRecruitingInformationFilterApiProperties;
}

export interface ICandidateFilterMenu {
    hasDocuments?: boolean;
    topCandidate?: boolean;
    topInCommunication?: boolean;
    hasDriverLicense?: boolean;
    salutation?: TSalutation;
    skills: ISkill[];
    optionalSkills: ISkill[];
    leader?: boolean;
    yearsOfWorkExperience?: string;
    homeOfficeDesire: EHomeOfficeDesire[];
    distanceSearchCompanyLocation?: ICompanyLocation|ICompanyLocationPartial;
    distanceSearchKm?: number;
    jobDescription?: string;
    ageFrom?: number;
    ageTo?: number;
    responsibleEmployee: null|IEmployeePartial;
    onlyFollowups: boolean;
    salaryFrom?: number;
    salaryTo?: number;
    languages: ILanguage[]
    source?: ECandidateSource;
    isFreelancer?: boolean;
    dateRangeFilter: IDateRangeFilterApiProperties;
    recruitingFilter?: IRecruitingInformationFilterMenu;
}
