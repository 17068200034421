import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../definitions/redux/store.definitions';
import {
    resetCandidatePageFieldsForIgnoreValidation,
    setCandidatePageAddDialogAction,
    setCandidatePageFormAction,
} from '../../../redux/candidatePage/candidatePage.actions';
import CustomDialogHeader from '../../../components/CustomDialog/CustomDialogHeader';
import { resetFormValidation } from '../../../redux/error/error.actions';
import CustomDialog from '../../../components/CustomDialog/CustomDialog';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import CandidateProfileDataPanel from '../../EditViews/Candidate/components/CandidateProfileDataPanel';
import { initialCandidateFormState } from '../../../definitions/candidatePage.definitions';
import { getToken } from '../../../selectors/app.selectors';
import { createApiObject } from '../../../utils/candidate.utils';
import { addCandidateAction } from '../../../redux/entities/entities.actions';
import { getLoggedInEmployeeFromStore } from '../../../selectors/employee.selectors';
import { useCreateCandidateHook } from './CandidatAddView.hooks';
import { DialogActions } from '@material-ui/core';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';

const CandidateAddView: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const open = useSelector((store: IStore) => store.candidatePage.addDialogOpen);
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const fieldsIgnoredForValidation = useSelector((store: IStore) => store.candidatePage.formFieldsIgnoredForValidation);
    const candidateForm = useSelector((store: IStore) => store.candidatePage.candidateForm);
    const {duplicateCandidates} = useCreateCandidateHook(candidateForm.name, candidateForm.firstName, candidateForm.externalReference);
    const [saveDuplicate, setSaveDuplicate] = useState(false);

    const onSave = () => {
        dispatch(addCandidateAction(token, createApiObject(candidateForm, fieldsIgnoredForValidation, true)));
    }

    const onClose = () => {
        dispatch(resetFormValidation());
        dispatch(setCandidatePageAddDialogAction(false));
    };

    useEffect(() => {
        dispatch(resetCandidatePageFieldsForIgnoreValidation());
        dispatch(resetFormValidation());
        dispatch(setCandidatePageFormAction({
            ...initialCandidateFormState,
            responsible: loggedInEmployee
        }));
    }, [open]);

    if (!open) {
        return null;
    }

    return (
        <CustomDialog fullWidth maxWidth={"md"} open={open} onClose={onClose}>
            <CustomDialogHeader translationString="pages.candidate.add" onClose={onClose} />
            <div style={{ marginLeft: 10, marginRight: 5, height: "100%", overflow: "auto", backgroundColor: 'var(--brainz-darker)' }}>
                <CandidateProfileDataPanel duplicateCandidates={duplicateCandidates}/>
            </div>
            {(duplicateCandidates.length === 0 || saveDuplicate) &&
                <CustomDialogActions
                    onClose={onClose}
                    buttons={[{
                        void: onSave,
                        label: translate('misc.buttons.create')
                    }]}
                />
            }
            {duplicateCandidates.length > 0 && !saveDuplicate &&
                <DialogActions>
                    <CustomCheckboxInput checked={saveDuplicate} label={"Kandidat anlegen trotz Warnung vor Duplikat"} onChange={() => setSaveDuplicate(true)}/>
                </DialogActions>
            }
        </CustomDialog>
    );
};

export default CandidateAddView;
