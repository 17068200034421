import React from 'react';
import {
    EEntities,
    ICandidatePartial,
    IFutureInvoice,
    IRecruitingPartial,
    IVacancy,
} from '../../../definitions/entities.definition';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { EInvoiceType } from '../../../definitions/billingPage.definitions';
import InvoiceTypeCellRenderer from '../../../components/CustomCellRenderer/InvoiceTypeCellRenderer';
import RecruitingCellRenderer from '../../../components/CustomCellRenderer/RecruitingCellRenderer';
import { translate } from '../../../translation/translate.utils';
import CurrencyCellRenderer from '../../../components/CustomCellRenderer/CurrencyCellRenderer';
import { ECellAlign } from '../../../definitions/components.definitions';
import CandidateCellRenderer from '../../../components/CustomCellRenderer/CandidateCellRenderer';
import VacancyCellRenderer from '../../../components/CustomCellRenderer/VacancyCellRenderer';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';

interface IProps {
    invoices: IFutureInvoice[];
    onReload: () => void;
    loading: boolean;
    sum?: boolean;
}

const FutureInvoiceList: React.FC<IProps> = (props) => {
    const sum = props.sum;

    return (
        <>
            <CustomTable
                config={{
                    loading: props.loading,
                    entity: EEntities.BillableRecruiting,
                    columnConfig: {
                        recruiting: {
                            width: 200,
                            header: 'Prozess',
                            property: 'recruiting',
                            cellRenderer: (recruiting: IRecruitingPartial) => {
                                if (!recruiting) {
                                    return (
                                        <>
                                            {translate('misc.noInformation')}
                                        </>
                                    )
                                }
                                return <RecruitingCellRenderer recruiting={recruiting} />
                            }
                        },
                        candidate: {
                            width: 200,
                            header: translate('pages.activity.properties.candidate'),
                            property: 'recruiting.candidate',
                            cellRenderer: (candidate: ICandidatePartial) => {
                                if (!candidate) {
                                    return (
                                        <>
                                            {translate('misc.noInformation')}
                                        </>
                                    )
                                }
                                return <CandidateCellRenderer candidate={candidate} />
                            }
                        },
                        vacancy: {
                            header: translate('pages.activity.properties.vacancy'),
                            property: "recruiting.vacancy",
                            cellRenderer: (vacancy: null | IVacancy, entity) => {
                                const billableData = entity as IFutureInvoice;
                                if (billableData.vacancy) {
                                    return <VacancyCellRenderer vacancy={billableData.vacancy} />
                                }
                                if (!vacancy) {
                                    return (
                                        <>
                                            {translate('pages.candidate.initiativeApplication')}
                                        </>
                                    );
                                }
                                return <VacancyCellRenderer vacancy={vacancy} />
                            },
                        },
                        invoiceType: {
                            header: translate('pages.billing.invoiceType'),
                            property: 'invoiceType',
                            width: 200,
                            cellRenderer: (invoiceType: EInvoiceType) => {
                                return <InvoiceTypeCellRenderer invoiceType={invoiceType} />
                            }
                        },
                        shouldDate: {
                            header: translate('pages.billing.shouldDate'),
                            property: 'shouldDate',
                            width: 120,
                            cellRenderer: (date: string) => {
                                return <DateCellRenderer date={date} />
                            }
                        },
                        totalNet: {
                            align: ECellAlign.right,
                            header: translate('pages.billing.totalNet'),
                            property: 'totalNet',
                            width: 120,
                            cellRenderer: (totalNet: number) => {
                                return (<CurrencyCellRenderer value={totalNet} />)
                            },
                        }
                    },
                    sortedEntries: props.invoices,
                    onReload: props.onReload,
                    footerText: sum ? 'Summe: ' + sum.toLocaleString("de-DE", { style: "currency", currency: "EUR", maximumFractionDigits: 2 }) : undefined,
                    count: props.invoices.length
                }}
            />
        </>
    );
}

export default FutureInvoiceList;
