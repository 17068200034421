import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDueTodosOfLoggedInEmployeeFromStore } from '../../../../selectors/todo.selectors';
import TodoCard from '../../../Shared/Todo/TodoCard';
import styles from './EmployeeDueTodoView.module.css';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import Moment from 'moment';
import CustomClickableDiv from '../../../../components/CustomInput/CustomClickableDiv';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import CustomTableFooter from '../../../../components/CustomTable/CustomTableFooter';
import {
    changeTodoOrder,
    getTodoListOfEmployeeAction,
    updateTodoOrderAction,
} from '../../../../redux/entities/entities.actions';
import { translate } from '../../../../translation/translate.utils';
import { toggleDueHeaderAction } from '../../../../redux/todo/todo.actions';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { ITodo, TBrainzEntity } from '../../../../definitions/entities.definition';
import CustomDropZone from '../../../../components/CustomDragAndDrop/CustomDropZone';

const EmployeeTodoView: React.FC = () => {
    const todos = useSelector(getDueTodosOfLoggedInEmployeeFromStore);
    const todosOrder = useSelector((state: IStore) => state.entities.todo.orderOfLoggedInEmployee);
    const token = useSelector(getToken);
    const loading = useSelector((state: IStore) => state.ui.todosOfEmployeeLoading);
    const dispatch = useDispatch();
    let lastHeader = '';

    const collapsedState = useSelector((state: IStore) => state.todo.employeeDueTodoCollapsedState);

    const getHeader = (dueDateString?: string) => {
        if (!dueDateString) {
            return {
                header: 'Unterminiert',
                isOverdue: false
            };
        }

        const dueDate = Moment(dueDateString);
        const isOverdue = dueDate.isBefore(Moment(), 'date');
        const isToday = dueDate.isSame(Moment(), 'date');

        return {
            header: isOverdue ? translate('misc.overdue') : isToday ? translate('misc.moment.today') : dueDate.format('dddd DD.MM.Y'),
            isOverdue
        };
    }

    const onDragEnd = (result: DropResult) => {
        const todo = todos[result.source.index];
        if (!result.destination) {
            return;
        }

        const newOrder = todosOrder;
        const [reorderedItem] = newOrder.splice(result.source.index, 1);
        newOrder.splice(result.destination.index, 0, reorderedItem);

        dispatch(updateTodoOrderAction(
            token, {
                id: todo.id,
                ranking: todos[result.destination.index].ranking,
                dueDate: todo.dueDate ? todos[result.destination.index].dueDate : undefined,
            }
        ));
        dispatch(changeTodoOrder(newOrder));
    };

    const renderAdditional = (entity: TBrainzEntity) => {
        const todo = entity as ITodo;
        const {header, isOverdue} = getHeader(todo.dueDate);
        const isCollapsed = collapsedState[header];

        const toRender = (
            <>
                {header !== lastHeader &&
                    <CustomClickableDiv className={styles.expandContainer} onClick={() => {
                        dispatch(toggleDueHeaderAction(header));
                    }}>
                        {
                            isCollapsed
                                ? IconFactory.getIconComponent(EIcons.ExpandMore)
                                : IconFactory.getIconComponent(EIcons.ExpandLess)
                        }
                        <div className={styles.expandLabel}>
                            { header }
                        </div>
                    </CustomClickableDiv>
                }
            </>
        )
        lastHeader = header;

        return toRender;
    }

    const renderTodo = (entity: TBrainzEntity, index: number) => {
        const todo = entity as ITodo;
        const {header, isOverdue} = getHeader(todo.dueDate);
        const isCollapsed = collapsedState[header];

        const toRender = (
            <>
                { !isCollapsed &&
                    <TodoCard
                        key={index}
                        todo={todo}
                        formatDueDate={isOverdue ? 'datetime' : 'time'}
                    />
                }
            </>
        )
        lastHeader = header;

        return toRender;
    }

    const isDragDisabled = (entity: TBrainzEntity) => {
        const todo = entity as ITodo;
        return todo.dueDate !== null && !todo.flexibleTime;
    }

    return (
        <>
            <div className={styles.root}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <CustomDropZone
                        id={"todoDropZone"}
                        items={todos}
                        renderItem={renderTodo}
                        renderAdditional={renderAdditional}
                        isDragDisabled={isDragDisabled}
                        rootClassName={"gap5 flexContainerColumn margin5"}
                    />
                </DragDropContext>
            </div>
            <CustomTableFooter
                count={todos.length}
                loading={loading}
                totalCount={todos.length}
                onReload={() => {
                    dispatch(getTodoListOfEmployeeAction(token));
                }}
            />
        </>
    )
};

export default EmployeeTodoView;
