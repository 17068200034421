import React from 'react';
import { getProcessAggregationListByCandidateAction } from '../../../../../redux/entities/entities.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../../selectors/app.selectors';
import { useCandidateProcessListHook } from './CandidateProcessList.hooks';
import ProcessList from '../../../../Shared/ProcessList/ProcessList';

interface IProps {
    candidateId: number;
}

const CandidateProcessList: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const candidateId = props.candidateId;

    const { loading, processes, total} = useCandidateProcessListHook(candidateId);

    return <ProcessList
        processes={processes}
        total={total}
        loading={loading}
        onReload={() => dispatch(getProcessAggregationListByCandidateAction(token, candidateId))}
    />
}

export default CandidateProcessList;
