import React, { useCallback, useEffect, useState } from 'react';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import {
    ELanguageLevel,
    ICandidateLanguage,
    ICandidateListModel,
    IEmployeePartial,
    IEntitySkill,
    IPluginCandidate,
    ISkillPartial,
} from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import SalutationDropDown from '../../../../components/CustomInput/CustomDropDownInput/SalutationDropDown';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import CustomNumberInput from '../../../../components/CustomInput/CustomNumberInput';
import YearsOfWorkExperienceDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/YearsOfWorkExperienceDropDown';
import HomeOfficeDesireDropDown from '../../../../components/CustomInput/CustomDropDownInput/HomeOfficeDesireDropDown';
import PartTimeFullTimeDropDown from '../../../../components/CustomInput/CustomDropDownInput/PartTimeFullTimeDropDown';
import NoticePeriodDropDown from '../../../../components/CustomInput/CustomDropDownInput/NoticePeriodDropDown';
import YesNoShowAllDropDown from '../../../../components/CustomInput/CustomDropDownInput/YesNoShowAllDropDown';
import moment from 'moment';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import { EIcons } from '../../../../components/Icons/IconFactory';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    setCandidatePageAddDialogAction,
    setCandidatePageFormAction,
    setCandidatePageToggleFieldForIgnoreValidation,
} from '../../../../redux/candidatePage/candidatePage.actions';
import { API_URL } from '../../../../requests/routes';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import CandidateInformationColumnCellRenderer
    from '../../../../components/CustomCellRenderer/CandidateInformationColumnCellRenderer';
import { EEntityView } from '../../../../definitions/ui.definitions';
import YearOrMonthDropDown from '../../../../components/CustomInput/CustomDropDownInput/YearOrMonthDropDown';
import VacancyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/VacancyAutocomplete';
import LegacyEmployeeAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import CandidateSourceDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateSourceDropDown';
import CandidateLevelDropDown from '../../../../components/CustomInput/CustomDropDownInput/CandidateLevelDropDown';
import EducationLevelDropDown from '../../../../components/CustomInput/CustomDropDownInput/EducationLevelDropDown';
import { sortSkills } from '../../../../utils/skills.utils';
import CandidateSkillElement from './components/CandidateSkillElement';
import { Chip, debounce, Divider, InputAdornment } from '@material-ui/core';
import CandidateEditLanguagesView from './components/CandidateEditLanguagesView';
import SkillAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/SkillAutocomplete';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';
import LanguageAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/LanguageAutocomplete';
import { addOnBrainzPluginHandler } from '../../../../utils/components.utils';
import { registerError } from '../../../../redux/error/error.actions';
import CustomBackdropLoadingInsideDiv from '../../../../components/CustomBackdrop/CustomBackdropLoadingInsideDiv';
import CandidateMailListView from './components/CandidateMailListView';
import { ICandidateForm } from '../../../../definitions/candidatePage.definitions';
import CandidateAddressListView from './components/CandidateAddressListView';
import CandidatePhoneListView from './components/CandidatePhoneListView';
import CandidateUrlListView from './components/CandidateUrlListView';
import CustomInfo from '../../../../components/CustomComponent/CustomInfo';
import { setUiEditView } from '../../../../redux/ui/ui.actions';

interface IProps {
    candidateId?: number;
    changedFieldsByCvParser?: string[];
    duplicateCandidates?: ICandidateListModel[];
}

const CandidateProfileDataPanel: React.FC<IProps> = (props) => {
    const token = useSelector(getToken);
    const candidateId = props.candidateId;
    const initialCandidateForm = useSelector((store: IStore) => store.candidatePage.candidateForm);
    const changedFieldsByCvParser = props.changedFieldsByCvParser || [];
    const dispatch = useDispatch();
    const errors = useSelector(getValidationErrorsFromStore);
    const fieldsIgnoredForValidation = useSelector((store: IStore) => store.candidatePage.formFieldsIgnoredForValidation);
    const duplicateCandidates = props.duplicateCandidates || [];
    const [candidateLanguageToEdit, setCandidateLanguageToEdit] = useState<ICandidateLanguage|undefined>();
    const [importInProgress, setImportInProgress] = useState(false);
    const [candidateForm, setCandidateForm] = useState<ICandidateForm>(initialCandidateForm);
    const [salaryAsRange, setSalaryAsRange] = useState(Boolean(candidateForm.expectedSalaryTo > 0));
    const [hideInfo, setHideInfo] = useState(false);
    const saveToStore = (form: ICandidateForm) => {dispatch(setCandidatePageFormAction(form));}
    const saveToStoreHandler = useCallback(debounce(saveToStore, 300), []);
    const setCandidateFormPartial = (candidateFormChange: Partial<ICandidateForm>) => {
        const form = {
            ...candidateForm,
            ...candidateFormChange
        };
        setCandidateForm(form);
        saveToStoreHandler(form);
    }

    useEffect(() => {
        setCandidateForm(initialCandidateForm);
    }, [
        initialCandidateForm.mailAddresses,
        initialCandidateForm.phoneNumbers,
        initialCandidateForm.addresses,
        initialCandidateForm.urls
    ])


    addOnBrainzPluginHandler((event: MessageEvent) => {
        setImportInProgress(false);

        if (!event.data.success) {
            dispatch(registerError('URL konnte nicht ausgelesen werden  '));
            return;
        }

        const importData: IPluginCandidate = event.data.data;

        if (importData.mailAddress && candidateForm.mailAddresses.filter((mailAddress) => mailAddress.mailAddress === importData.mailAddress).length === 0) {
            //TODO
        }

        if (importData.websiteUrl && candidateForm.urls.filter((url) => url.url === importData.websiteUrl).length === 0) {
            //TODO
        }

        if (importData.city && candidateForm.addresses.filter((address) => address.city === importData.city).length === 0) {
            //TODO
        }

        if (importData.imageUrl) {
            setCandidateFormPartial({imageUrl: importData.imageUrl});
        }
    });

    const onRemoveSkill = (skill: ISkillPartial) => {
        setCandidateFormPartial({
            skills: candidateForm.skills.filter((candidateSkill: IEntitySkill) => !candidateSkill.skill || skill.id !== candidateSkill.skill.id)
        });
    };

    const onRemoveLanguage = (removeId: number) => {
        setCandidateFormPartial({
            languages: candidateForm.languages.filter((candidateLanguage: ICandidateLanguage) => removeId !== candidateLanguage.id)
        })
    };

    const onSetTopSkill = (skill: ISkillPartial) => {
        setCandidateFormPartial({
            skills: candidateForm.skills.map((candidateSkill: IEntitySkill) => {
                if (candidateSkill.skill && candidateSkill.skill.id === skill.id) {
                    return {
                        ...candidateSkill,
                        importantSkill: !candidateSkill.importantSkill
                    }
                }
                return candidateSkill;
            })
        })
    }

    return (
        <>
            <CandidateEditLanguagesView
                onClose={() => setCandidateLanguageToEdit(undefined)}
                open={Boolean(candidateLanguageToEdit)}
                language={candidateLanguageToEdit}
                onSave={(candidateLanguageUpdate) => {
                    setCandidateFormPartial({
                        languages: candidateForm.languages.map((candidateLanguage) => {
                            if (candidateLanguage.language.id === candidateLanguageUpdate.language.id) {
                                return candidateLanguageUpdate;

                            }
                            return candidateLanguage;
                        })
                    });
                    setCandidateLanguageToEdit(undefined);
                }}
            />
            <CustomValidationErrors translationKey={'pages.candidate'}/>
            <div className={"flexContainerColumn gap5 padding10 overflow"}>
                <CustomBackdropLoadingInsideDiv open={importInProgress}/>
                {candidateForm.imagePath &&
                    <div className={"flexContainerRow flex1 alignVerticalCenter"}>
                        <img
                            src={API_URL + "/parsed-image/preview?file=" + candidateForm.imagePath + "&Authorization=" + token}
                            height={200}
                        />
                        <CustomClickableIcon
                            tooltip={"Bild entfernen"}
                            onClick={() => setCandidateFormPartial({imagePath: undefined})}
                            icon={EIcons.Delete}
                        />
                    </div>
                }
                {candidateForm.imageUrl &&
                <div className={"flexContainerRow flex1 alignVerticalCenter"}>
                    <img
                        src={candidateForm.imageUrl}
                        height={200}
                    />
                    <CustomClickableIcon
                        tooltip={"Bild entfernen"}
                        onClick={() => setCandidateFormPartial({imageUrl: undefined})}
                        icon={EIcons.Delete}
                    />
                </div>
                }
                <h4 style={{margin:5}}>Besonderheiten</h4>
                <div className={"flexContainerRow flex1 gap5"}>
                    <CustomCheckboxInput
                        labelTranslation={"pages.candidate.properties.topCandidate"}
                        checked={candidateForm.topCandidate}
                        onChange={() => setCandidateFormPartial({topCandidate: !candidateForm.topCandidate})}
                    />
                    <CustomCheckboxInput
                        label={<>Gute Verbindung /<br/>Gute Kommunikation</>}
                        checked={candidateForm.topInCommunication}
                        onChange={() => setCandidateFormPartial({topInCommunication: !candidateForm.topInCommunication})}
                    />
                    <CustomCheckboxInput
                        label={<>Hohes Potential/<br/>Großes Talent</>}
                        checked={candidateForm.topInPotential}
                        onChange={() => setCandidateFormPartial({topInPotential: !candidateForm.topInPotential})}
                    />
                </div>
                <h4 style={{margin:5}}>Persönliche Daten</h4>
                <div className={"flexContainerRow flex1 gap10"}>
                    <SalutationDropDown
                        onChange={(event: any) => setCandidateFormPartial({salutation: event.target.value})}
                        value={candidateForm.salutation}
                        required={true}
                        error={Boolean(errors.salutation)}
                    />
                    <CustomTextField
                        label={translate('pages.companyContact.properties.title')}
                        value={candidateForm.title}
                        width={100}
                        onChange={(value) => setCandidateFormPartial({title: value})}
                    />
                    <CustomTextField
                        error={Boolean(errors.firstName)}
                        required={true}
                        value={candidateForm.firstName}
                        onChange={(value) => setCandidateFormPartial({firstName: value})}
                        label={translate('pages.candidate.properties.firstName')}
                    />
                    <CustomTextField
                        error={Boolean(errors.name)}
                        required={true}
                        value={candidateForm.name}
                        onChange={(value) => setCandidateFormPartial({name: value})}
                        label={translate('pages.candidate.properties.name')}
                    />
                    {candidateForm.externalReference &&
                        <CustomTextField
                            value={candidateForm.externalReference}
                            onChange={(value) => setCandidateFormPartial({externalReference: value})}
                            label={translate('pages.candidate.properties.externalReference')}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" onClick={(event) => {
                                        event.stopPropagation();
                                        window.open(`https://www.arbeitsagentur.de/bewerberboerse/suche?was=${candidateForm.externalReference}&angebotsart=1`);
                                    }}>
                                        <img width={22} src={'/images/arbeitsagentur.png'} style={{cursor: 'pointer'}} title={'Profil der Arbeitsagentur öffnen'}/>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    }
                    <CustomDatePicker
                        required
                        label={changedFieldsByCvParser.includes('birthDate') ? <b style={{color: 'var(--brainz-color-green)'}}>{translate('pages.candidate.properties.birthDate')}</b> : translate('pages.candidate.properties.birthDate')}
                        value={candidateForm.birthDate ? new Date(candidateForm.birthDate) : undefined}
                        onChange={(value) => {
                            if (!value) {
                                setCandidateFormPartial({birthDate: undefined});
                                return;
                            }
                            setCandidateFormPartial({birthDate: moment(value).format("Y-MM-DD")});
                        }}
                        error={Boolean(errors.birthDate) && !fieldsIgnoredForValidation.includes('birthDate')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('birthDate')}
                                label={"ohne " + translate('pages.candidate.properties.birthDate') + " speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('birthDate'))}
                            />
                        </>}
                    />
                </div>
                {duplicateCandidates.length > 0 && !hideInfo &&
                <>
                    <div className={"flexContainerRow"}>
                        <CustomInfo buttonConfig={{
                            action: () => setHideInfo(true),
                            text: 'Ausblenden'
                        }}>
                            <h4 style={{margin: 5}}>Kandidat/en mit gleichen Namen</h4>
                            <div className={'formContent'} style={{marginLeft: 5}}>Klicken sie auf den Kandidaten um diesen zu bearbeiten/zusammenzuführen.</div>
                            <div className={"flexContainerRow flexWrap flex1 gap10"}>
                                {duplicateCandidates.map((candidate) => <>
                                        <div
                                            className={"flexContainerRow CandidateInformationPanel"}
                                            onClick={() => {
                                                dispatch(setUiEditView(EEntityView.candidate, candidate.id))
                                                dispatch(setCandidatePageAddDialogAction(false));
                                            }}
                                        >
                                            <CandidateInformationColumnCellRenderer
                                                key={candidate.id}
                                                showDetails
                                                token={token}
                                                candidate={candidate}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </CustomInfo>
                    </div>
                </>
                }
                <h4 style   ={{margin:5}}>Kontaktdaten</h4>
                <div className={"flexContainerRow"}>
                    <div className={"flexContainerColumn flex1"}>
                        <h5 style={{margin: 5}}>E-Mail Adressen</h5>
                        {(Boolean(errors.mailAddresses) && !fieldsIgnoredForValidation.includes('mailAddresses')) &&
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('mailAddresses')}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('mailAddresses'))}
                                label={
                                    <span style={{color: '#f44336'}} className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Kandidaten ohne E-Mail speichern</span>
                                }
                            />
                        }
                        <CandidateMailListView
                            candidateId={candidateId}
                            records={candidateForm.mailAddresses}
                        />
                    </div>
                    <Divider orientation={"vertical"}/>
                    <div className={"flexContainerColumn flex1"}>
                        <h5 style={{margin: 5}}>Telefon-Nummern</h5>
                        {(Boolean(errors.phoneNumbers) && !fieldsIgnoredForValidation.includes('phoneNumbers')) &&
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('phoneNumbers')}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('phoneNumbers'))}
                                label={
                                    <span style={{color: '#f44336'}} className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Kandidaten ohne Telefon-Nummer speichern</span>
                                }
                            />
                        }
                        <CandidatePhoneListView
                            candidateId={candidateId}
                            records={candidateForm.phoneNumbers}
                        />
                    </div>
                    <Divider orientation={"vertical"}/>
                    <div className={"flexContainerColumn flex1"}>
                        <h5 style={{margin: 5}}>Urls</h5>
                        {(Boolean(errors.urls) && !fieldsIgnoredForValidation.includes('urls')) &&
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('urls')}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('urls'))}
                                label={
                                    <span style={{color: '#f44336'}} className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Kandidaten ohne Url speichern</span>
                                }
                            />
                        }
                        <CandidateUrlListView
                            candidateId={candidateId}
                            records={candidateForm.urls}
                        />
                    </div>
                </div>
                <Divider orientation={"horizontal"}/>
                <div className={"flexContainerColumn flex1"}>
                    <h5 style={{margin: 5}}>Adressen</h5>
                    {(Boolean(errors.addresses) && !fieldsIgnoredForValidation.includes('addresses')) &&
                    <CustomCheckboxInput
                        checked={fieldsIgnoredForValidation.includes('addresses')}
                        onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('addresses'))}
                        label={
                            <span style={{color: '#f44336'}} className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Kandidaten ohne Adresse speichern</span>
                        }
                    />
                    }
                    <CandidateAddressListView
                        candidateId={candidateId}
                        records={candidateForm.addresses}
                    />
                </div>
                <Divider orientation={"horizontal"}/>
                <h4 style={{margin:5}}>Berufliche Informationen</h4>
                <CustomTextField
                    required
                    label={translate('pages.candidate.properties.currentJobDescription')}
                    error={Boolean(errors.currentJobDescription)}
                    value={candidateForm.currentJobDescription}
                    onChange={(value) => setCandidateFormPartial({currentJobDescription: value})}
                />
                <CustomTextField
                    label={translate('pages.candidate.properties.desiredJobDescription')}
                    value={candidateForm.desiredJobDescription}
                    onChange={(value) => setCandidateFormPartial({desiredJobDescription: value})}
                />
                <div className={"flexContainerRow flex1 gap10"}>
                    <CustomDatePicker
                        label={translate('pages.candidate.properties.wishDateOfStart')}
                        value={candidateForm.wishDateOfStart ? new Date(candidateForm.wishDateOfStart) : undefined}
                        onChange={(value) => {
                            if (!value) {
                                setCandidateFormPartial({wishDateOfStart: undefined});
                                return;
                            }
                            setCandidateFormPartial({wishDateOfStart: moment(value).format("Y-MM-DD")});
                        }}
                        flex
                    />
                    <CustomNumberInput
                        required
                        label={"Anz. Arbeitgeber"}
                        error={Boolean(errors.numberOfEmployers) && !fieldsIgnoredForValidation.includes('numberOfEmployers')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('numberOfEmployers')}
                                label={"ohne Anz. Arbeitgeber speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('numberOfEmployers'))}
                            />
                        </>}
                        value={candidateForm.numberOfEmployers}
                        onChange={(value) => setCandidateFormPartial({numberOfEmployers: value})}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <CandidateLevelDropDown
                        onChange={(level) => setCandidateFormPartial({level})}
                        // @ts-ignore-next-line
                        value={candidateForm.level}
                        required
                        error={Boolean(errors.level) && !fieldsIgnoredForValidation.includes('level')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('level')}
                                label={"ohne Erfahrungs-Grad speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('level'))}
                            />
                        </>}
                    />
                    <EducationLevelDropDown
                        onChange={(educationLevel) => setCandidateFormPartial({educationLevel})}
                        // @ts-ignore-next-line
                        value={candidateForm.educationLevel}
                        required
                        error={Boolean(errors.educationLevel) && !fieldsIgnoredForValidation.includes('educationLevel')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('educationLevel')}
                                label={"ohne Bildungs-Grad speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('educationLevel'))}
                            />
                        </>}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <YearOrMonthDropDown
                        onChange={(value) => setCandidateFormPartial({salaryUnit: value})}
                        value={candidateForm.salaryUnit}
                    />
                    <CustomNumberInput
                        value={candidateForm.expectedSalary}
                        suffix={candidateForm.salaryUnit === 'year' ? " k €" : " €"}
                        onChange={(value) => setCandidateFormPartial({expectedSalary: value})}
                        label={salaryAsRange ? 'von' : translate('pages.candidate.salary')}
                        max={candidateForm.salaryUnit === 'year' ? 999 : undefined}
                    />
                    {salaryAsRange ?
                        <CustomNumberInput
                            value={candidateForm.expectedSalaryTo}
                            onClear={() => {
                                setSalaryAsRange(false);
                                setCandidateFormPartial({expectedSalaryTo: undefined})
                            }}
                            suffix={candidateForm.salaryUnit === 'year' ? " k €" : " €"}
                            onChange={(value) => setCandidateFormPartial({expectedSalaryTo: value})}
                            label={translate('pages.candidate.salaryTo')}
                            max={candidateForm.salaryUnit === 'year' ? 999 : undefined}
                        />
                        :
                        <CustomCheckboxInput
                            checked={salaryAsRange}
                            label={<>Gehaltsspanne<br/>angeben</>}
                            onChange={() => setSalaryAsRange(true)}
                        />
                    }
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <NoticePeriodDropDown
                        onChange={(event) => {
                            if (event.target.value === 0) {
                                setCandidateFormPartial({noticePeriod: undefined});
                                return;
                            }
                            setCandidateFormPartial({noticePeriod: event.target.value});
                        }}
                        // @ts-ignore-next-line
                        value={candidateForm.noticePeriod}
                    />
                    <YearsOfWorkExperienceDropDown
                        required
                        onChange={(event) => setCandidateFormPartial({yearsOfWorkExperience: event.target.value})}
                        // @ts-ignore-next-line
                        value={candidateForm.yearsOfWorkExperience}
                        error={Boolean(errors.yearsOfWorkExperience) && !fieldsIgnoredForValidation.includes('yearsOfWorkExperience')}
                        helperText={<>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('yearsOfWorkExperience')}
                                label={"ohne Arbeitserfahrung speichern"}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('yearsOfWorkExperience'))}
                            />
                        </>}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <HomeOfficeDesireDropDown
                        onChange={(homeOfficeDesire) => setCandidateFormPartial({homeOfficeDesire})}
                        // @ts-ignore-next-line
                        value={candidateForm.homeOfficeDesire}
                    />
                    <PartTimeFullTimeDropDown
                        onChange={(partTimeFullTime) =>
                            setCandidateFormPartial({partTimeFullTime})
                        }
                        // @ts-ignore-next-line
                        value={candidateForm.partTimeFullTime}
                    />
                </div>
                <div className={"flexContainerRow flex1 gap10"}>
                    <div className={"flex1 flexContainerRow"}>
                        <YesNoShowAllDropDown
                            allText={translate('misc.choose')}
                            onChange={(value) => setCandidateFormPartial({hasDriverLicense: value})}
                            value={candidateForm.hasDriverLicense}
                            label={translate('pages.candidate.properties.hasDriverLicense')}
                        />
                    </div>
                    <div className={"flex1"}>
                        <CustomCheckboxInput
                            labelTranslation={"pages.candidate.properties.leader"}
                            checked={candidateForm.leader}
                            onChange={() => setCandidateFormPartial({leader: !candidateForm.leader})}
                        />
                        <CustomCheckboxInput
                            labelTranslation={"pages.candidate.properties.freelancer"}
                            checked={candidateForm.freelancer}
                            onChange={() => setCandidateFormPartial({freelancer: !candidateForm.freelancer})}
                        />
                    </div>
                </div>
                <div className={"flexContainerColumn flex1 gap10"}>
                    {(Boolean(errors.skills) && !fieldsIgnoredForValidation.includes('skills')) &&
                        <CustomCheckboxInput
                            checked={fieldsIgnoredForValidation.includes('skills')}
                            onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('skills'))}
                            label={
                                <span style={{color: '#f44336'}} className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}>Kandidaten ohne Skills speichern</span>
                            }
                        />
                    }
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"}>
                        <h4 style={{margin: 5}}>Skills ({candidateForm.skills.length})</h4>
                    </div>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"} style={{width: 300}}>
                        <SkillAutocomplete
                            label={"Skill hinzufügen"}
                            onChange={(skill) => {
                                if (!skill) {
                                    return;
                                }

                                let skillExists = false;
                                candidateForm.skills.forEach((candidateSkill) => {
                                    if (candidateSkill.skill && candidateSkill.skill.id === skill.id) {
                                        skillExists = true;
                                    }
                                });

                                if (skillExists) {
                                    return;
                                }

                                setCandidateFormPartial({skills: [
                                    ...candidateForm.skills, {
                                        skill: skill,
                                        id: - (skill.id),
                                        count: 0,
                                        importantSkill: false,
                                        addedByParser: true,
                                        identifier: 'candidate'
                                    }
                                ]})
                            }}
                            clearOnSelect
                            value={null}
                        />
                    </div>
                    <div className={"flexContainerRow flexAutoGrow overflow "} style={{alignItems: 'start'}}>
                        <div className={"flexContainerRow flexAutoGrow gap5 flexWrap"}>
                            {sortSkills(candidateForm.skills).map((candidateSkill, index) =>
                                <CandidateSkillElement
                                    key={index}
                                    skill={candidateSkill}
                                    onRemove={onRemoveSkill}
                                    onSetTop={onSetTopSkill}
                                />
                            )}
                        </div>
                    </div>
                    <div className={"flexContainerRow flexAutoGrow gap10"}>
                        {(Boolean(errors.languages) && !fieldsIgnoredForValidation.includes('languages')) &&
                        <>
                            <CustomCheckboxInput
                                checked={fieldsIgnoredForValidation.includes('languages')}
                                onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('languages'))}
                                label={
                                    <span
                                        style={{color: '#f44336'}}
                                        className={"MuiTypography-root MuiFormControlLabel-label MuiTypography-body1"}
                                    >
                                    Kandidat ohne Sprachen speichern
                                </span>
                                }/>
                        </>
                        }
                    </div>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow gap5 flexWrap"}>
                        <h4 style={{margin: 5}}>Sprachen ({candidateForm.languages.length})</h4>
                    </div>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"} style={{width: 300}}>
                        <LanguageAutocomplete
                            label={"Sprache hinzufügen"}
                            onChange={(language) => {
                                if (!language) {
                                    return;
                                }

                                let languageExists = false;
                                candidateForm.languages.forEach((candidateLanguage) => {
                                    if (candidateLanguage.language.id === language.id) {
                                        languageExists = true;
                                    }
                                });

                                if (languageExists) {
                                    return;
                                }

                                setCandidateFormPartial({languages: [
                                    ...candidateForm.languages, {
                                        language,
                                        level: ELanguageLevel.unknown,
                                        addedByParser: true
                                    }
                                ]});
                            }}
                            size={"small"}
                            value={null}
                        />
                    </div>
                    <div className={"flexContainerRow flexAutoGrow gap5 flexWrap"}>
                        {candidateForm.languages.map((candidateLanguage, index) =>
                            <Chip
                                key={index}
                                color={candidateLanguage.addedByParser ? 'primary' : 'default'}
                                onClick={() => setCandidateLanguageToEdit(candidateLanguage)}
                                label={<> {candidateLanguage.language.name} ({translate('misc.languageLevelValue.' + candidateLanguage.level)})</>}
                                onDelete={() => onRemoveLanguage(candidateLanguage.id)}
                            />
                        )}
                    </div>
                </div>
                <h4 style={{margin: 5}}>Fragebogen</h4>
                <CustomTextField
                    value={candidateForm.typus}
                    label={translate('pages.candidate.properties.typus')}
                    onChange={(value) => setCandidateFormPartial({typus: value})}
                />
                <CustomTextField
                    value={candidateForm.proudOf}
                    multiline
                    rows={3}
                    label={translate('pages.candidate.properties.proudOf')}
                    onChange={(value) => setCandidateFormPartial({proudOf: value})}
                />
                <CustomTextField
                    value={candidateForm.thoughtsAboutTeamAndAppreciation}
                    rows={3}
                    multiline
                    label={translate('pages.candidate.properties.thoughtsAboutTeamAndAppreciation')}
                    onChange={(value) => setCandidateFormPartial({thoughtsAboutTeamAndAppreciation: value})}
                />
                <CustomTextField
                    value={candidateForm.flexibility}
                    rows={3}
                    multiline
                    label={translate('pages.candidate.properties.flexibility')}
                    onChange={(value) => setCandidateFormPartial({flexibility: value})}
                />
                <CustomTextField
                    multiline
                    rows={3}
                    value={candidateForm.moveWillingness}
                    onChange={(value) => setCandidateFormPartial({moveWillingness: value})}
                    label={translate('pages.candidate.properties.moveWillingness')}
                />
                <h4 style={{margin: 5}}>Interne Daten</h4>
                <div className={"flexContainerRow flex1 gap10"}>
                    <LegacyEmployeeAutocompleteDropDown
                        required
                        label={translate('misc.responsibleEmployee')}
                        value={candidateForm.responsible}
                        onChange={(event, value: IEmployeePartial) => setCandidateFormPartial({responsible: value})}
                        error={Boolean(errors.responsibleId)}
                    />
                    <CandidateSourceDropDown
                        required
                        value={candidateForm.source}
                        onChange={(event) => setCandidateFormPartial({source: event.target.value})}
                        error={Boolean(errors.source)}
                    />
                    <CustomDatePicker
                        label={<>Wiedervorlage<br/>am</>}
                        width={140}
                        value={candidateForm.followupDate ? new Date(candidateForm.followupDate) : null}
                        onChange={(value) => {
                            if (!value) {
                                setCandidateFormPartial({followupDate: null})
                                return;
                            }
                            setCandidateFormPartial({followupDate: moment(value).format("Y-MM-DD")})
                        }}
                        error={Boolean(errors.followupDate)}
                    />
                </div>
                {(candidateForm.followupDate !== null && candidateForm.followupDate !== undefined) &&
                <CustomTextField
                    multiline
                    rows={4}
                    value={candidateForm.followupComment}
                    onChange={(value) => setCandidateFormPartial({followupComment: value})}
                    label={'Kommentar zur Wiedervorlage'}
                />
                }
                <CustomTextField
                    multiline
                    rows={8}
                    value={candidateForm.comment}
                    onChange={(value) => setCandidateFormPartial({comment: value})}
                    label={translate('misc.comment')}
                />
                {!props.candidateId &&
                <div className={"flexContainerRow flex1 gap10"}>
                    <div className={"flexContainerRow flex1 gap10"}>
                        <VacancyAutocomplete
                            required
                            label={translate('pages.candidate.appliedFor')}
                            error={Boolean(errors.applicationVacancyId) && !fieldsIgnoredForValidation.includes('applicationVacancyId')}
                            helperText={<>
                                <CustomCheckboxInput
                                    checked={fieldsIgnoredForValidation.includes('applicationVacancyId')}
                                    label={"Kandidat hat sich auf keine Stelle beworben"}
                                    onChange={() => dispatch(setCandidatePageToggleFieldForIgnoreValidation('applicationVacancyId'))}
                                />
                            </>}
                            value={candidateForm.applicationVacancy}
                            onChange={(applicationVacancy) => setCandidateFormPartial({applicationVacancy})}
                        />
                    </div>
                </div>
                }
            </div>
        </>
    );
};

export default CandidateProfileDataPanel;
