import React, { PropsWithChildren, ReactElement } from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { TBrainzEntity } from '../../definitions/entities.definition';

interface IProps extends PropsWithChildren<any>{
    id: string;
    items: TBrainzEntity[];
    rootClassName?: string;
    renderItem: (entity: TBrainzEntity, index: number) => ReactElement;
    renderAdditional?: (entity: TBrainzEntity) => ReactElement;
    isDragDisabled?: (entity: TBrainzEntity) => boolean;
}

const CustomDropZone: React.FC<IProps> = (props) => {
    const placeholderHeight = props.items.length === 0 ? 20 : 1;
    return <>
        <Droppable droppableId={props.id.replace(' ', '_')} >
            {(provided) =>
                <div
                    className={props.rootClassName}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                >
                    {
                        props.items.map((entity, index) => (
                            <>
                                {props.renderAdditional && props.renderAdditional(entity) }
                                <Draggable
                                    isDragDisabled={props.isDragDisabled ? props.isDragDisabled(entity) : false}
                                    key={entity.id}
                                    draggableId={(entity.id as number).toString()}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            {props.renderItem(entity, index)}
                                        </div>
                                    )}
                                </Draggable>
                            </>
                        ))
                    }
                    <div style={{width:'100%', height: placeholderHeight, fontSize: 1}}>&nbsp;</div>
                    {provided.placeholder}
                    <div style={{width:'100%', height: placeholderHeight, fontSize: 1}}>&nbsp;</div>
                </div>
            }
        </Droppable>
    </>
}

export default CustomDropZone;
