import React from 'react';
import { Dialog } from '@material-ui/core';
import CustomDialogHeader from './CustomDialogHeader';
import CustomDialogActions from '../CustomInput/CustomDialogActions';
import CustomTypography from '../CustomTypography/CustomTypography';
import CustomDialogBody from './CustomDialogBody';
import { translate } from '../../translation/translate.utils';

interface IProps {
    open: boolean;
    onClose: () => void;
    onDelete: () => void;
    titleTranslation: string;
    bodyTranslation?: string;
    deleteTranslation?: string;
}

const CustomDeleteDialog: React.FC<IProps> = (props) => {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <CustomDialogHeader translationString={props.titleTranslation} onClose={props.onClose} />
            <CustomDialogBody>
                {props.bodyTranslation &&
                    <CustomTypography translation={props.bodyTranslation} />
                }
            </CustomDialogBody>
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: props.onDelete,
                    label: props.deleteTranslation ? translate(props.deleteTranslation) : translate('misc.buttons.delete')
                }]}
            />
        </Dialog>
    );
};

export default CustomDeleteDialog;
