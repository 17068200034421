import React from 'react';
import CustomDashboardWidgetPaper from '../../../../components/CustomPaper/CustomDashboardWidgetPaper';
import InvoicesByBranchPieChart from './InvoicesByBranchPieChart';
import PaperRow from '../../../../components/CustomPaper/PaperRow';
import { EIcons } from '../../../../components/Icons/IconFactory';
import InvoicesByEmployeePieChart from './InvoicesByEmployeePieChart';
import InvoicesByCompanyPieChart from './InvoicesByCompanyPieChart';
import CustomTypography from '../../../../components/CustomTypography/CustomTypography';
import { translate } from '../../../../translation/translate.utils';
import InvoicesByCandidateSourcePieChart from './InvoicesByCandidateSourcePieChart';

const InvoicesPieChartView: React.FC = () => {
    return (
        <>
            <CustomDashboardWidgetPaper
                icon={EIcons.Flag}
                headline={translate('pages.dashboard.contractsPieChart')}
            >
                <PaperRow>
                    <div style={{flex: 1, textAlign: "center"}}>
                        <CustomTypography variant={"overline"} value={translate('pages.dashboard.byEmployee')}/>
                        <InvoicesByEmployeePieChart />
                    </div>
                    <div style={{flex: 1, textAlign: "center"}}>
                        <CustomTypography variant={"overline"} value={translate('pages.dashboard.byCompany')}/>
                        <InvoicesByCompanyPieChart />
                    </div>
                </PaperRow>
                <PaperRow>
                    <div style={{flex: 1, textAlign: "center"}}>
                        <CustomTypography variant={"overline"} value={translate('pages.dashboard.byBranch')}/>
                        <InvoicesByBranchPieChart />
                    </div>
                    <div style={{flex: 1, textAlign: "center"}}>
                        <CustomTypography variant={"overline"} value={translate('pages.dashboard.byCandidateSource')}/>
                        <InvoicesByCandidateSourcePieChart />
                    </div>
                </PaperRow>
            </CustomDashboardWidgetPaper>
        </>
    );
}

export default InvoicesPieChartView;
