import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CustomDeleteDialog from '../../../components/CustomDialog/CustomDeleteDialog';
import { getToken } from '../../../selectors/app.selectors';
import { ClientApi } from '../../../requests/ClientApi';
import { deleteSuggestionGroupRouteConfig } from '../../../requests/routes';
import { getVacancyAction } from '../../../redux/entities/entities.actions';

interface IProps {
    open: boolean;
    vacancyId: number;
    groupId: number;
    onClose: () => void;
}

const DeleteSuggestionGroupView: React.FC<IProps> = (props) => {
    const open = props.open;
    const vacancyId = props.vacancyId;
    const groupId = props.groupId;
    const token = useSelector(getToken);
    const dispatch = useDispatch();

    const onClose = () => {
        props.onClose();
    }

    if (!open) {
        return null;
    }

    return (
        <CustomDeleteDialog
            open={open}
            onClose={onClose}
            onDelete={() => {
                ClientApi.request(deleteSuggestionGroupRouteConfig, {
                    token,
                    groupId
                }).then(() => {
                    dispatch(getVacancyAction(token, vacancyId, false))
                    onClose();
                });
            }}
            titleTranslation={"pages.vacancy.deleteSuggestionGroup"}
        />
    );
};

export default DeleteSuggestionGroupView;
