import React from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { Dialog } from '@material-ui/core';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import AddEditContractForm from './components/AddEditContractForm';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { setRecruitingPageContractToEditAction } from '../../redux/recruitingPage/recruitingPage.actions';

const EditContractView: React.FC = () => {
    const dispatch = useDispatch();
    const contractEditId = useSelector((store: IStore) => store.recruitingPage.contractEditId);
    const open = Boolean(contractEditId);

    const onClose = () => {
        dispatch(setRecruitingPageContractToEditAction(0));
    }

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} maxWidth={"md"} fullWidth onClose={onClose}>
            <CustomDialogHeader string="Vermittlung dokumentieren" onClose={onClose} />
            <CustomDialogBody>
                <AddEditContractForm contractId={contractEditId} onClose={onClose}/>
            </CustomDialogBody>
        </Dialog>
    );
};

export default EditContractView;
