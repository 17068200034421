export interface IErrorState {
    hasError: boolean;
    hasInfo: boolean;
    errorMessage: string;
    infoMessage: string;
    successMessage: string;
    success: boolean;
    validation: {
        [key: string]: string
    };
}

export const preloadedStateError: IErrorState = {
    hasError: false,
    hasInfo: false,
    errorMessage: '',
    successMessage: '',
    infoMessage: '',
    success: false,
    validation: {},
};
