import React from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { Dialog } from '@material-ui/core';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import AddEditContractForm from './components/AddEditContractForm';

interface IProps {
    open: boolean;
    candidateId: number;
    onClose: () => void;
}

const AddContractView: React.FC<IProps> = (props) => {
    const open = props.open;
    const candidateId = props.candidateId;

    const onClose = () => {
        props.onClose();
    };

    if (!open) {
        return null;
    }

    return (
        <Dialog open={open} maxWidth={"md"} fullWidth onClose={onClose}>
            <CustomDialogHeader string="Vermittlung dokumentieren" onClose={onClose} />
            <CustomDialogBody>
                <AddEditContractForm candidateId={candidateId} onClose={onClose}/>
            </CustomDialogBody>
        </Dialog>
    );
};

export default AddContractView;
