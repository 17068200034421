import React, { useEffect } from 'react';
import '../../App.css';
import '../../Battery.css';
import '../../Flex.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ERoutes, EStorageItem } from '../../definitions/app.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import ErrorBoundary from '../../components/ErrorBoundary/ErrorBoundary';
import NavigationController from '../../components/NavigationController/NavigationController';
import LogoutView from '../../components/Login/LogoutView';
import moment from 'moment';
import 'moment/locale/de';
import { useRenewPassword } from '../../App.hooks';
import { userHasRole } from '../../selectors/employee.selectors';
import { ROLE_ADMIN, ROLE_BILLING } from '../../definitions/actor.definitions';
import BasePage from './BasePage';
import DashboardPage from '../DashboardPage/DashboardPage';

import RecruitingPage from '../RecruitingPage/RecruitingPage';
import CandidatePage from '../CandidatePage/CandidatePage';
import ArchivePage from '../ArchivePage/ArchivePage';
import ProfilePage from '../ProfilePage/ProfilePage';
import CompanyPage from '../CompanyPage/CompanyPage';
import AdministrationPage from '../AdministrationPage/AdministrationPage';
import WebVacancyPage from '../WebVacancyPage/WebVacancyPage';
import BillingPage from '../BillingPage/BillingPage';
import ApplicationPage from '../ApplicationPage/ApplicationPage';
import RightDrawerMailView from './RightDrawer/RightDrawerMailView';
import RightDrawerTodoView from './RightDrawer/RightDrawerTodoView';
import RightDrawerNewsView from './RightDrawer/RightDraweNewsView';
import LeftDrawerNavigation from './LeftDrawerNavigation';
import TopBar from './TopBar/TopBar';
import { closeUiRightDrawerAction, initPinnedViews, setUiCloseCurrentEditView } from '../../redux/ui/ui.actions';
import { checkIfAnyModalIsOpen } from '../../selectors/ui.selectors';
import TopDrawerSearch from './TopDrawerSearch';
import { EEntityView, ERightDrawerViews, IEditViewState } from '../../definitions/ui.definitions';
import theme from '../../theme/theme';
import { ThemeProvider } from '@material-ui/core';
import ActivityOverviewPage from '../ActivityOverviewPage/ActivityOverviewPage';
import AppSharedEditView from '../../AppSharedEditView';
import AppLoggedInModals from '../../AppLoggedInModals';
import ReduxDelegator from '../../redux/ReduxDelegator';
import CustomBackdropLoading from '../../components/CustomBackdrop/CustomBackdropLoading';
import CustomSnackbar from '../../components/CustomSnackbar/CustomSnackbar';
import RefreshTokenController from '../../components/Login/RefreshTokenController';
import RightDrawerEntityViewContainer from './RightDrawer/RightDrawerEntityViewContainer';
import { addOnKeydownEventHandler } from '../../utils/components.utils';
import NotificationController from '../../components/NotificationController/NotificationController';
import AutoLogoutController from '../../components/AutoLogoutController/AutoLogoutController';


moment.locale('de');

const AppLoggedIn: React.FC = () => {
    const dispatch = useDispatch();
    useRenewPassword();

    const token = useSelector((store: IStore) => store.actor.token);
    const applicationHasError = useSelector((store: IStore) => store.error.hasError);
    const applicationHasInfo = useSelector((store: IStore) => store.error.hasInfo);
    const errorMessage = useSelector((store: IStore) => store.error.errorMessage);
    const infoMessage = useSelector((store: IStore) => store.error.infoMessage);
    const successMessage = useSelector((store: IStore) => store.error.successMessage);
    const applicationHasSuccess = useSelector((store: IStore) => store.error.success);
    const saving = useSelector((store: IStore) => store.ui.saving);
    const hasRoleAdmin = userHasRole(ROLE_ADMIN);
    const hasRoleBilling = userHasRole(ROLE_BILLING);

    useEffect(() => {
        window.addEventListener('beforeunload', alertUser);
        return () => {
            window.removeEventListener('beforeunload', alertUser);
        };
    });

    const alertUser = (event: any) => {
        event.preventDefault();
        event.returnValue = '';
    };

    addOnKeydownEventHandler("Escape",(e) => {
        const noDialogOpen = document.getElementsByClassName('MuiDialog-root').length === 0;
        //@ts-ignore
        const editViewOpen = document.getElementById('entity-edit-view-drawer') && document.getElementById('entity-edit-view-drawer').children[0].style.visibility !== 'hidden';
        //@ts-ignore
        const firstCloseEditView = document.getElementById('entity-edit-view-drawer') && document.getElementById('entity-edit-view-drawer').classList.contains('closeMeBeforeModal');

        if (e.target && (e.target.id === 'editableValue' || e.target.closest('#editableValue') !== null)) {
            return;
        }

        if (firstCloseEditView) {
            dispatch(setUiCloseCurrentEditView());
            e.preventDefault();
            e.stopPropagation();
            return;
        }

        if (noDialogOpen && !editViewOpen) {
            dispatch(closeUiRightDrawerAction(ERightDrawerViews.viewNews));
            dispatch(closeUiRightDrawerAction(ERightDrawerViews.viewTodos));
            dispatch(closeUiRightDrawerAction(ERightDrawerViews.viewMails));
        }

        if (noDialogOpen && editViewOpen) {
            dispatch(setUiCloseCurrentEditView());
        }
    });


    useEffect(() => {
        const storageViewJson = localStorage.getItem(EStorageItem.Views);
        if (storageViewJson !== null) {
            dispatch(initPinnedViews(
                JSON.parse(storageViewJson) as IEditViewState[]
            ));
        }
    }, [dispatch, token]);

    const anyModalOpen = useSelector(checkIfAnyModalIsOpen);

    return (
        <>
            <div className="App">
                <ErrorBoundary>
                    <ThemeProvider theme={theme}>
                        <Router>
                            <TopBar/>
                            <LeftDrawerNavigation/>
                            <RightDrawerMailView/>
                            <RightDrawerTodoView/>
                            <RightDrawerNewsView/>
                            <RightDrawerEntityViewContainer/>
                            <TopDrawerSearch />
                            <BasePage>
                                <Switch>
                                    <Route path='/candidate/:id/:title' render={() => <AppSharedEditView view={EEntityView.candidate} component={<CandidatePage/>}/>}/>
                                    <Route path='/company/:id/:title' render={() => <AppSharedEditView view={EEntityView.company} component={<CompanyPage/>}/>}/>
                                    <Route path='/vacancy/:id/:title' render={() => <AppSharedEditView view={EEntityView.vacancy} component={<RecruitingPage/>}/>}/>
                                    <Route path='/webVacancy/:id/:title' render={() => <AppSharedEditView view={EEntityView.webVacancy} component={<WebVacancyPage/>}/>}/>
                                    <Route path='/recruiting/:id/:title' render={() => <AppSharedEditView view={EEntityView.recruiting} component={<RecruitingPage/>}/>}/>
                                    <Route path='/suggestion/:id/:title' render={() => <AppSharedEditView view={EEntityView.suggestion} component={<RecruitingPage/>}/>}/>

                                    <Route path={ERoutes.Dashboard} render={() => <DashboardPage/>}/>
                                    <Route path={ERoutes.ActivityOverview} render={() => <ActivityOverviewPage/>}/>
                                    <Route path={ERoutes.Recruitings} render={() => <RecruitingPage/>}/>
                                    <Route path={ERoutes.Applications} render={() => <ApplicationPage/>}/>
                                    <Route path={ERoutes.Companies} render={() => <CompanyPage/>}/>
                                    <Route path={ERoutes.Candidates} render={() => <CandidatePage/>}/>
                                    <Route path={ERoutes.WebVacancies} render={() => <WebVacancyPage/>}/>
                                    <Route path={ERoutes.Archive} render={() => <ArchivePage/>}/>
                                    <Route path={ERoutes.Profile} render={() => <ProfilePage/>}/>
                                    {hasRoleAdmin &&
                                        <Route path={ERoutes.Administration} component={AdministrationPage}/>
                                    }
                                    {hasRoleBilling &&
                                    <>
                                        <Route path={ERoutes.Billing} component={BillingPage}/>
                                    </>
                                    }
                                </Switch>
                            </BasePage>
                            <AutoLogoutController/>
                            <NotificationController/>
                            <NavigationController/>
                            <LogoutView/>
                        </Router>
                        {anyModalOpen &&
                            <AppLoggedInModals />
                        }
                        <CustomBackdropLoading open={saving}/>
                        <CustomSnackbar
                            hasSuccess={applicationHasSuccess}
                            hasError={applicationHasError}
                            hasInfo={applicationHasInfo}
                            message={errorMessage !== '' ? errorMessage : successMessage !== '' ? successMessage : infoMessage}
                        />
                        <ReduxDelegator/>
                        <RefreshTokenController/>
                    </ThemeProvider>
                </ErrorBoundary>
            </div>
        </>
    );
};

export default AppLoggedIn;
