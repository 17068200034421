import { useSelector } from 'react-redux';
import { IStore } from '../definitions/redux/store.definitions';
import {
    IGoalBurnUpChartData,
    IGroupedRecruitingEvaluationData,
    ITargetMonthData,
} from '../definitions/dashboardPage.definitions';

export const getBurnupChartData = () : IGoalBurnUpChartData[] => {
    const chartDataByMonth = useSelector((state: IStore) => state.dashboardPage.groupedEvaluationData.byMonth);
    const chartDataByMonthSummedUp = useSelector((state: IStore) => state.dashboardPage.groupedEvaluationData.byMonthSummedUp);
    const chartDataByMonthLastYear = useSelector((state: IStore) => state.dashboardPage.groupedEvaluationDataLastYear.byMonth);
    const chartDataByMonthSummedUpLastYear = useSelector((state: IStore) => state.dashboardPage.groupedEvaluationDataLastYear.byMonthSummedUp);
    const chartDataByTarget = useSelector((state: IStore) => state.dashboardPage.target);
    const showLastYear = useSelector((state: IStore) => state.dashboardPage.goalBurnUpChartSettings.showLastYear);

    const burnUpChartDataRecords: IGoalBurnUpChartData[] = [];
    if (chartDataByMonth && chartDataByMonthSummedUp && chartDataByTarget) {
        burnUpChartDataRecords.push({
            targetCommissionMin: 0,
            targetContractsMin: 0,
            targetContractsTopGoal: 0,
            targetCommissionTopGoal: 0,
            totalCommission: 0,
            totalContracts: 0,
            monthTotalCommission: 0,
            monthTotalContracts: 0,
            month: '',
            lastYearTotalCommission: 0,
            lastYearTotalContracts: 0,
            lastYearMonthTotalCommission: 0,
            lastYearMonthTotalContracts: 0,
        });

        for (let x=1; x<=12; x++) {
            const targetData = getTargetDataByGroupId(chartDataByTarget, x);
            const totalData = getChartDataByGroupId(chartDataByMonthSummedUp, x);
            const totalMonthData = getChartDataByGroupId(chartDataByMonth, x);

            const lastYearTotalData = getChartDataByGroupId(chartDataByMonthSummedUpLastYear, x);
            const lastYearTotalMonthData = getChartDataByGroupId(chartDataByMonthLastYear, x);

            burnUpChartDataRecords.push({
                targetCommissionMin: targetData?.commissionMin,
                targetContractsMin: targetData?.contractsMin,
                targetCommissionTopGoal: targetData?.commissionTopGoal,
                targetContractsTopGoal: targetData?.contractsTopGoal,
                totalCommission: totalData?.commission,
                totalContracts: totalData?.contracts,
                monthTotalCommission: totalMonthData?.commission,
                monthTotalContracts: totalMonthData?.contracts,
                month: x.toString(),
                lastYearTotalCommission: showLastYear ? lastYearTotalData?.commission : undefined,
                lastYearTotalContracts: showLastYear ? lastYearTotalData?.contracts : undefined,
                lastYearMonthTotalCommission: showLastYear ? lastYearTotalMonthData?.commission : undefined,
                lastYearMonthTotalContracts: showLastYear ? lastYearTotalMonthData?.contracts : undefined,
            });
        }
    }

    return burnUpChartDataRecords;
}

export const getChartDataByGroupId = (evaluationDataRecords: IGroupedRecruitingEvaluationData[], groupId: number) :undefined|IGroupedRecruitingEvaluationData => {
    return evaluationDataRecords.find((data) => {
        return data.groupId === groupId;
    })
}

export const getTargetDataByGroupId = (targetRecords: ITargetMonthData[], groupId: number) :undefined|ITargetMonthData => {
    return targetRecords.find((data) => {
        return data.month === groupId;
    })
}
