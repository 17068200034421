import React from 'react';
import CustomTable from '../../components/CustomTable/CustomTable';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getCompanyListAction } from '../../redux/entities/entities.actions';
import { EEntities, IBranch, ICompany, ICompanyLocation, IEmployee } from '../../definitions/entities.definition';
import {
    setCompanyPageAddDialogOpenAction,
    setCompanyPageCompanyToDeleteAction,
    setCompanyPageFilterAction,
    setCompanyPageSortingOptionAction,
} from '../../redux/companyPage/companyPage.actions';
import { getCompaniesForCompanyTable, getCompanyPageRequestObject } from '../../selectors/company.selectors';
import { ECellAlign, ESortingOptions, ICustomTableColumnConfig } from '../../definitions/components.definitions';
import { EIcons, IconFactory } from '../../components/Icons/IconFactory';
import { translate } from '../../translation/translate.utils';
import CompanyAddView from './CompanyAddView';
import {
    getDefaultCreatedColumn,
    getDefaultLastActivityColumn,
    INFINITE_SCROLL_INTERVAL,
} from '../../utils/components.utils';
import BranchesCellRenderer from '../../components/CustomCellRenderer/BranchesCellRenderer';
import autobind from 'autobind-decorator';
import CompanyDeleteView from '../EditViews/Company/CompanyDeleteView';
import UrlCellRenderer from '../../components/CustomCellRenderer/UrlCellRenderer';
import YesNoCellRenderer from '../../components/CustomCellRenderer/YesNoCellRenderer';
import ContractsCountCellRenderer from '../../components/CustomCellRenderer/ContractsCountCellRenderer';
import { COLOR_BLUE } from '../../theme/theme';
import { setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';
import EmployeeCellRenderer from '../../components/CustomCellRenderer/EmployeeCellRenderer';
import { isOnlyShowMineModeActive } from '../../selectors/employee.selectors';

export const getColumnConfigForCompanyTable = (): ICustomTableColumnConfig => {
    return {
        crawler: {
            header: 'Crawler',
            property: "crawler",
            width: 70,
            align: ECellAlign.center,
            cellRenderer: (value: boolean) => <YesNoCellRenderer value={value} />
        },
        isCustomer: {
            header: 'Kunde',
            property: "isCustomer",
            width: 70,
            align: ECellAlign.center,
            cellRenderer: (value: boolean) => <YesNoCellRenderer value={value} />
        },
        name: {
            header: translate('pages.company.properties.name'),
            property: "name",
            flex: 1,
        },
        companyLocations: {
            header: translate('pages.webVacancy.locations'),
            property: "companyLocations",
            cellRenderer: (value: ICompanyLocation[]) => {
                if (!value) {
                    return null;
                }
                return value.map((location) => location.city).join(', ');
            }
        },
        rating: {
            header: translate('pages.company.properties.rating'),
            property: "rating",
            width: 60,
            align: ECellAlign.center,
            cellRenderer: (value: number) => {
                if (value === 1) {
                    return (<>A</>);
                }
                if (value === 2) {
                    return (<>B</>);
                }
                if (value === 3) {
                    return (<>C</>);
                }

                return (<>{translate('misc.noInformation')}</>);
            }
        },
        totalContracts: {
            header: IconFactory.getIconComponent(EIcons.EmojiEvents),
            property: "totalContracts",
            align: ECellAlign.center,
            width: 60,
            cellRenderer: (value: number, entity) => {
                const company = entity as ICompany;
                return <ContractsCountCellRenderer count={value} companyId={company.id} />
            }
        },
        successRate: {
            header: translate('pages.company.properties.successRate'),
            property: "successRate",
            align: ECellAlign.right,
            width: 110,
            cellRenderer: (value: number) => {
                if (value > 0) {
                    return (<div>{value} %</div>);
                }

                return (<div>-</div>);
            }
        },
        homepageUrl: {
            header: translate('pages.company.properties.homepageUrl'),
            property: "homepageUrl",
            width: 100,
            align: ECellAlign.center,
            cellRenderer: (url: string) => <UrlCellRenderer value={"Öffnen"} url={url} />,
        },
        branches: {
            header: translate('pages.company.properties.branches'),
            property: "branches",
            flex: 1,
            cellRenderer: (branches: IBranch[]) => <BranchesCellRenderer branches={branches} />,
        },
        created: getDefaultCreatedColumn(),
        lastActivity: getDefaultLastActivityColumn(),
        responsibleEmployee: {
            header: translate('misc.employeeShort'),
            property: "responsibleEmployee",
            width: 140,
            cellRenderer: (employee: IEmployee, entity) => {
                const company = entity as ICompany;

                if (company.shareWithEmployees.length > 0) {
                    return <>
                        <EmployeeCellRenderer employee={employee} />
                        <h1 style={{margin:0,fontWeight: "normal"}}>&nbsp;|&nbsp;</h1>
                        <div className={"flex gap5"}>
                            {company.shareWithEmployees.map((employee) => <>
                                <EmployeeCellRenderer employee={employee} />
                            </>)}
                        </div>
                    </>
                }
                return <EmployeeCellRenderer employee={employee} />;
            },
        },
    };
};

class CompanyPage extends React.PureComponent<PropsFromRedux> {
    constructor(props: PropsFromRedux) {
        super(props);
    }

    componentDidMount = () => {
        if (!this.props.initialCompaniesLoaded) {
            this.loadCompanies();
        }
    };

    @autobind
    loadCompanies() {
        this.props.getCompanyListAction(this.props.token, this.props.requestObject, true);
    }

    componentDidUpdate(prevProps: Readonly<PropsFromRedux>) {
        const needToFetch =
            JSON.stringify(this.props.sorting) !== JSON.stringify(prevProps.sorting) ||
            JSON.stringify(this.props.filter) !== JSON.stringify(prevProps.filter) ||
            this.props.onlyShowMine !== prevProps.onlyShowMine;

        if (needToFetch) {
            this.loadCompanies();
        }
    }

    editAction = (companyId: number, companyName: string) => this.props.setUiEditView(EEntityView.company, companyId);
    setDeleteViewAction = (companyId: number) => this.props.setDeleteViewAction(companyId);

    render() {
        return (
            <>
                <CustomTable
                    config={{
                        color: COLOR_BLUE,
                        entity: EEntities.Company,
                        onRowDoubleClick: (id, entity) => {
                            const company = entity as ICompany;
                            this.editAction(company.id, company.name);
                        },
                        columnConfig: this.props.columnConfig,
                        sortedEntries: this.props.sortedEntries,
                        loading: this.props.loading,
                        scrollCallback: () => {
                            if (this.props.filter.limit && this.props.filter.limit < this.props.totalRecords) {
                                this.props.setFilterAction({
                                    start: 0,
                                    limit: this.props.filter.limit + INFINITE_SCROLL_INTERVAL,
                                });
                            }
                        },
                        listActionButtons: [{
                            action: this.setDeleteViewAction,
                            translationKey: "tables.openDeleteView",
                            icon: EIcons.Delete,
                        }],
                        tableHeaderCallbacks: {
                            setSorting: (propertyToSort: string, sortOption: ESortingOptions) =>
                                this.props.setSortingOptionAction(propertyToSort, sortOption),
                        },
                        onReload: () => this.loadCompanies(),
                        count: this.props.count,
                        total: this.props.total
                    }}
                />
                <CompanyAddView />
                <CompanyDeleteView />
            </>
        );
    }
}

const mapState = (store: IStore) => ({
    count: store.entities.companies.order.length,
    total: store.entities.companies.total,
    initialCompaniesLoaded: store.companyPage.initialCompaniesLoaded,
    loading: store.ui.companiesLoading,
    token: store.actor.token,
    columnConfig: getColumnConfigForCompanyTable(),
    sorting: store.companyPage.sorting,
    requestObject: getCompanyPageRequestObject(store),
    sortedEntries: getCompaniesForCompanyTable(store),
    filter: store.companyPage.filter,
    totalRecords: store.entities.companies.total,
    onlyShowMine: isOnlyShowMineModeActive(store)
});

const mapDispatch = {
    setSortingOptionAction: setCompanyPageSortingOptionAction,
    setUiEditView,
    setAddDialogAction: setCompanyPageAddDialogOpenAction,
    setDeleteViewAction: setCompanyPageCompanyToDeleteAction,
    getCompanyListAction,
    setFilterAction: setCompanyPageFilterAction,
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(CompanyPage);
