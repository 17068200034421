import React from 'react';
import { getFormattedDateString, getFormattedDateWithDayString } from '../../utils/date.utils';
import moment from 'moment';

interface IProps {
    date: null|string;
    hideDay?: boolean;
    markOverdue?: boolean;
}

const DateCellRenderer: React.FC<IProps> = (props) => {
    const markOverdue = props.markOverdue || false;
    const hideDay = props.hideDay || false;

    if (props.date === null) {
        return null;
    }

    let color = undefined;
    if (markOverdue && moment().diff(moment(props.date), 'days') >= 1) {
        color = 'red';
    }

    if (hideDay) {
        return <div style={{color}}>{getFormattedDateString(props.date)}</div>;
    }
    return <div style={{color}}>{getFormattedDateWithDayString(props.date)}</div>;
};

export default DateCellRenderer;
