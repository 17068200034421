import { Avatar, Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from '@material-ui/core';
import React from 'react';
import { EIcons, IconFactory } from '../../../../components/Icons/IconFactory';
import { setUiLogOutDialogOpenAction } from '../../../../redux/ui/ui.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getLoggedInNameInformation } from '../../../../selectors/actor.selectors';
import { translate } from '../../../../translation/translate.utils';
import { getEmployeeImageUrl } from '../../../../utils/ui.utils';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';

const AccountMenuItem: React.FC = () => {
    const dispatch = useDispatch();
    const loggedInName = useSelector(getLoggedInNameInformation);
    const employee = useSelector(getLoggedInEmployeeFromStore);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <Tooltip title="Profil/Einstellungen/Logout">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? 'account-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                    >
                        <Avatar src={getEmployeeImageUrl(employee.id)}>{loggedInName.firstName[0]}{loggedInName.name[0]}</Avatar>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                elevation={4}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    className: "AppAccountMenu"
                }}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: -40,
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClose} className={"AppAccountMenuItem"} disabled>
                    <ListItemIcon>
                        <Avatar style={{width: 24, height: 24}} src={getEmployeeImageUrl(employee.id)}/>
                    </ListItemIcon>
                    {translate('pages.profile.title')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={() => dispatch(setUiLogOutDialogOpenAction(true))} className={"AppAccountMenuItem"}>
                    <ListItemIcon>
                        {IconFactory.getIconComponent(EIcons.Logout)}
                    </ListItemIcon>
                    {translate('pages.profile.logout')}
                </MenuItem>
            </Menu>
        </>
    );
}

export default React.memo(AccountMenuItem)
