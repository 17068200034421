import React from 'react';
import { useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import AddEditSuggestionGroupForm from './components/AddEditSuggestionGroupForm';
import { ISuggestionGroup } from '../../definitions/entities.definition';

interface IProps {
    vacancyId: number;
    groupId?: number;
    add?: boolean;
    onClose: () => void;
    onAdd?: (suggestionGroup: ISuggestionGroup) => void;
}

const AddEditSuggestionGroupView: React.FC<IProps> = (props) => {
    const groupId = props.groupId;
    const vacancyId = props.vacancyId;
    const open = useSelector((store: IStore) => props.add || Boolean(groupId));
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);

    const onClose = () => {
        props.onClose();
    };

    const onAdd = (suggestionGroup: ISuggestionGroup) => {
        if (!props.onAdd) {
            return;
        }
        props.onAdd(suggestionGroup);
    };

    if (!open || !vacancy) {
        return null;
    }

    return (
        <CustomDialog maxWidth={'sm'} onClose={onClose} open={true}>
            <CustomDialogHeader string={groupId ? "Gruppe bearbeiten/löschen" : "Gruppe hinzufügen"} onClose={onClose}/>
            <AddEditSuggestionGroupForm
                onClose={onClose}
                vacancyId={vacancyId}
                groupId={groupId}
                onAdd={onAdd}
            />
        </CustomDialog>
    );
};

export default AddEditSuggestionGroupView;

