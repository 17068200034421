import React, { useState } from 'react';
import FastSearchPanelProcessList from '../../../App/TopBar/FastSearchPanel/FastSearchPanelProcessList';
import CandidateProcessList from './components/CandidateProcessList';
import { EIcons } from '../../../../components/Icons/IconFactory';
import {
    setRecruitingPageAddSuggestionAction,
    setRecruitingPageStartRecruitingAction,
} from '../../../../redux/recruitingPage/recruitingPage.actions';
import { useDispatch } from 'react-redux';
import AddApplicationView from '../../../ApplicationPage/AddApplicationView';
import AddContractView from '../../../RecruitingPage/AddContractView';

interface IProps {
    candidateId: number;
}

const CandidateProcessPanel: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const candidateId = props.candidateId;
    const [addApplicationViewOpen, setAddApplicationViewOpen] = useState(false);
    const [addContractViewOpen, setAddContractViewOpen] = useState(false);

    return <>
        <AddApplicationView
            candidateId={candidateId}
            onClose={() => setAddApplicationViewOpen(false)}
            open={addApplicationViewOpen}
        />
        <AddContractView
            candidateId={candidateId}
            onClose={() => setAddContractViewOpen(false)}
            open={addContractViewOpen}
        />
        <div className={"flexColumn padding5"}>
            <FastSearchPanelProcessList
                fastSearch={''}
                setFastSearch={() => console.log(1)}
                furtherActionButtons={[{
                    action: () => dispatch(setRecruitingPageStartRecruitingAction({
                        open: true,
                        candidateId
                    })),
                    tooltip: 'Kandidat vorschlagen / Prozess starten',
                    icon: EIcons.ThumbsUpDown
                }, {
                    action: () => dispatch(setRecruitingPageAddSuggestionAction({
                        open: true,
                        candidateId
                    })),
                    tooltip: 'Kandidat als Favorit hinterlegen',
                    icon: EIcons.Star
                }, {
                    action: () => setAddApplicationViewOpen(true),
                    tooltip: 'Bewerbung für Kandidat anlegen',
                    icon: EIcons.AccountBox
                }, {
                    action: () => setAddContractViewOpen(true),
                    tooltip: 'Vermittlung dokumentieren',
                    icon: EIcons.Work
                }]}
            />
            <CandidateProcessList candidateId={candidateId}/>
        </div>
    </>;
}

export default React.memo(CandidateProcessPanel);
