import React, { useEffect } from 'react';
import { Chart, PieSeries, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import { EventTracker } from '@devexpress/dx-react-chart';
import { useDispatch, useSelector } from 'react-redux';
import { getToken } from '../../../../selectors/app.selectors';
import { IStore } from '../../../../definitions/redux/store.definitions';
import {
    EGroupEvaluationGroupBy,
    getInvoiceGroupedEvaluationAction,
} from '../../../../redux/entities/entities.actions';
import { getEmployeeColor } from '../../../../utils/components.utils';

const InvoicesByEmployeePieChart: React.FC = () => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const chartData = useSelector((state: IStore) => state.billingPage.groupedEvaluationData.byEmployee);
    const year = (new Date()).getFullYear();

    useEffect(() => {
        dispatch(getInvoiceGroupedEvaluationAction(
            token,
            year,
            EGroupEvaluationGroupBy.GROUP_BY_EMPLOYEE
        ));
    }, [dispatch]);

    return (
        <>
            <Chart data={chartData} height={400}>
                <PieSeries
                    valueField={"commission"}
                    argumentField="groupId"
                    pointComponent={(pointProps) => {
                        return <PieSeries.Point {...pointProps} color={getEmployeeColor(pointProps.argument)} />
                    }}
                />

                <EventTracker/>
                <Tooltip contentComponent={(props) => {
                    const data = chartData[props.targetItem.point];
                    return (
                        <>
                            <div>{data.groupTitle}</div>
                            <div>Umsatz: {data.commission && data.commission.toLocaleString("de-DE")} €</div>
                        </>
                    )
                }}/>
            </Chart>
        </>

    );
}

export default InvoicesByEmployeePieChart;
