import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { getNotifyUnreadCountAction } from '../../redux/entities/entities.actions';
import { getToken } from '../../selectors/app.selectors';
import { setInterval } from 'timers';
import { ClientApi } from '../../requests/ClientApi';
import { getNotificationsRouteConfig } from '../../requests/routes';
import { resetUiNotificationsCountAction } from '../../redux/ui/ui.actions';
import { INotification } from '../../definitions/entities.definition';
import { celebrationAnimation } from '../../utils/celebrationAnimation.utils';
import { registerSuccess } from '../../redux/error/error.actions';

type TState = {
    loading: boolean;
}

class NotificationController extends React.PureComponent<PropsFromRedux, TState> {
    constructor(props: PropsFromRedux) {
        super(props);

        this.state = {
            loading: false
        }
    }

    componentDidMount() {
        setInterval(this.tick, 600000);
        this.tick();

        document.addEventListener('mousemove', (e) => {
            if (this.props.countNotifications > 0 && !this.state.loading) {
                this.setState({loading: true});
                ClientApi.request(getNotificationsRouteConfig, {token: this.props.token}).then((result: INotification[]) => {
                    this.setState({loading: false});
                    this.props.resetUiNotificationsCountAction();

                    const notification = result[0];

                    //for now only display first notification
                    celebrationAnimation(notification.id, notification.responsibleEmployee.id, this.props.token);
                    this.props.registerSuccess(notification.text);
                });
            }
        });
    }

    tick = () => {
        this.props.getNotifyUnreadCountAction(this.props.token);
    };

    render() {
        return null;
    }
}

const mapState = (store: IStore) => ({
    token: getToken(store),
    countNotifications: store.ui.countNotifications
});

const mapDispatch = {
    registerSuccess,
    getNotifyUnreadCountAction,
    resetUiNotificationsCountAction
};

const connector = connect(mapState, mapDispatch)
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(NotificationController);
