import React, { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import styles from './CustomDashboardWidgetPaper.module.css';

interface IProps extends PropsWithChildren<any> {
    icon?: EIcons;
    headline: string;
    headlineElement?: ReactNode;
    design?: 'dark'|'light';
    style?: CSSProperties | undefined;
    onHeadlineDblClick?: () => void
}

const CustomDashboardPaper: React.FC<IProps> = (props: IProps) => {
    return (
        <>
            <div className={"flexColumn"} style={props.style}>
                <div className={props.design === 'light' ? styles.titleContainerLight : styles.titleContainer} onDoubleClick={props.onHeadlineDblClick}>
                    {props.icon &&
                    <div className={styles.titleIcon}>
                        {IconFactory.getIconComponent(props.icon, {
                            color: 'white',
                            width: "1.5em",
                            height: "1.5em",
                        })}
                    </div>
                    }
                    <div className={styles.titleText}>{props.headline}</div>
                    <div className={styles.titleButtons}>{props.headlineElement}</div>
                </div>
                <div className={props.design === 'light' ? styles.contentLight : styles.content}>
                    {props.children}
                </div>
            </div>
        </>
    );
};

export default CustomDashboardPaper;
