import { ESortingOptions } from '../../definitions/components.definitions';
import { ICompanyFilter, ICompanyFilterMenu } from '../../definitions/companyPage.definitions';
import {
    ISetCompanyPageDocumentSortingOptionAction,
    ISetCompanyPageDocumentToDeleteAction,
    ISetCompanyPageDocumentToEditAction,
    ISetCompanyPageEditViewActivityFilterAction,
    ISetCompanyPageEditViewActivitySortingOptionAction,
    ISetCompanyPageEditViewContactActivityFilterAction,
    ISetCompanyPageEditViewContactActivitySortingOptionAction,
    ISetCompanyPageEditViewInvoiceFilterAction,
    ISetCompanyPageEditViewInvoiceSortingOptionAction,
    ISetCompanyPageFastSearchAction,
    ISetCompanyPageFilterAction,
    ISetCompanyPageFilterMenuAction,
    ISetCompanyPageRecruitingListFilterAction,
    ISetCompanyPageRecruitingListFilterMenuAction,
    ISetCompanyPageSortingOptionAction,
    ISetCompanyPageVacancyListFilterAction,
} from '../../definitions/redux/actions.definition';
import { IApiBaseFilter } from '../../definitions/app.definitions';
import { IRecruitingFilter, IRecruitingFilterMenu } from '../../definitions/recruitingPage.definitions';
import { IVacancyFilter } from '../../definitions/vacancyPage.definitions';

export const COMPANY_PAGE_SET_FILTER = "COMPANY_PAGE_SET_FILTER";
export const COMPANY_PAGE_SET_SORTING_OPTION = "COMPANY_PAGE_SET_SORTING_OPTION";
export const COMPANY_PAGE_SET_FAST_SEARCH = "COMPANY_PAGE_SET_FAST_SEARCH";
export const COMPANY_PAGE_SET_ADD_DIALOG = "COMPANY_PAGE_SET_ADD_DIALOG";
export const COMPANY_PAGE_SET_COMPANY_TO_DELETE = "COMPANY_PAGE_SET_COMPANY_TO_DELETE";
export const COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_SORTING_OPTION = "COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_SORTING_OPTION";
export const COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_TO_DELETE = "COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_TO_DELETE";
export const COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION = "COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION";
export const COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER = "COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_FAST_SEARCH";
export const COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_SORTING_OPTION = "CONTACT_";
export const COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_FILTER = "COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_FAST_SEARCH";
export const COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_SORTING_OPTION = "COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_SORTING_OPTION";
export const COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_FILTER = "COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_FILTER";
export const COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACTS_SORTING_OPTION = "COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACTS_SORTING_OPTION";
export const COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACT_TO_DELETE = "COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACT_TO_DELETE";
export const COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_TO_DELETE = "COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_TO_DELETE";
export const COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_SORTING_OPTION = "COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_SORTING_OPTION";
export const COMPANY_PAGE_SET_FILTER_MENU = "COMPANY_PAGE_SET_FILTER_MENU";
export const COMPANY_PAGE_RESET_FILTER = "COMPANY_PAGE_RESET_FILTER";
export const COMPANY_PAGE_SET_RECRUITING_LIST_FILTER = "COMPANY_PAGE_SET_RECRUITING_LIST_FILTER";
export const COMPANY_PAGE_SET_RECRUITING_LIST_FILTER_MENU = "COMPANY_PAGE_SET_RECRUITING_LIST_FILTER_MENU";
export const COMPANY_PAGE_SET_CONTACT_RECRUITING_LIST_FILTER = "COMPANY_PAGE_SET_CONTACT_RECRUITING_LIST_FILTER";
export const COMPANY_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT = "COMPANY_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT";
export const COMPANY_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE = "COMPANY_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE";
export const COMPANY_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION = "COMPANY_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION";
export const COMPANY_PAGE_SET_RELOAD_COMPANY = "COMPANY_PAGE_SET_RELOAD_COMPANY";
export const COMPANY_PAGE_SET_VACANCY_LIST_FILTER = "COMPANY_PAGE_SET_VACANCY_LIST_FILTER";
export const COMPANY_PAGE_SET_CONTACT_VACANCY_LIST_FILTER = "COMPANY_PAGE_SET_CONTACT_VACANCY_LIST_FILTER";

export const setCompanyPageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCompanyPageSortingOptionAction => ({
    type: COMPANY_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCompanyPageReloadCompanyAction = () => ({
    type: COMPANY_PAGE_SET_RELOAD_COMPANY,
});

export const resetCompanyPageFilterAction = () => ({
    type: COMPANY_PAGE_RESET_FILTER,
});

export const setCompanyPageFilterAction = (filter: Partial<ICompanyFilter>): ISetCompanyPageFilterAction => ({
    type: COMPANY_PAGE_SET_FILTER,
    payload: filter,
});

export const setCompanyPageFilterMenuAction = (filter: Partial<ICompanyFilterMenu>): ISetCompanyPageFilterMenuAction => ({
    type: COMPANY_PAGE_SET_FILTER_MENU,
    payload: filter,
});

export const setCompanyPageFastSearchValueAction = (fastSearchValue: string): ISetCompanyPageFastSearchAction => ({
    type: COMPANY_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setCompanyPageAddDialogOpenAction = (open: boolean) => ({
    type: COMPANY_PAGE_SET_ADD_DIALOG,
    payload: open,
});

export const setCompanyPageCompanyToDeleteAction = (companyId: number) => ({
    type: COMPANY_PAGE_SET_COMPANY_TO_DELETE,
    payload: companyId,
});

export const setCompanyPageDocumentToEditAction = (documentId: number): ISetCompanyPageDocumentToEditAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT,
    payload: documentId,
});

export const setCompanyPageDocumentToDeleteAction = (documentId: number): ISetCompanyPageDocumentToDeleteAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_SET_DOCUMENT_TO_DELETE,
    payload: documentId,
});

export const setCompanyPageDocumentSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCompanyPageDocumentSortingOptionAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_DOCUMENT_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

/**
 * Rechnungen
 */
export const setCompanyPageInvoiceSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCompanyPageEditViewInvoiceSortingOptionAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCompanyPageInvoiceFilterAction = (filter: Partial<IApiBaseFilter>): ISetCompanyPageEditViewInvoiceFilterAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_INVOICE_FILTER,
    payload: filter
});

/**
 * Aktivitäten
 */
export const setCompanyPageActivitySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCompanyPageEditViewActivitySortingOptionAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCompanyPageActivityFilterAction = (filter: Partial<IApiBaseFilter>): ISetCompanyPageEditViewActivityFilterAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER,
    payload: filter
});

export const setCompanyPageContactActivitySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCompanyPageEditViewContactActivitySortingOptionAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCompanyPageContactActivityFilterAction = (filter: Partial<IApiBaseFilter>): ISetCompanyPageEditViewContactActivityFilterAction => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_CONTACT_ACTIVITY_FILTER,
    payload: filter
});

/**
 * KONTAKTE
 */

export const setCompanyPageCompanyContactToDeleteAction = (companyContactId: number) => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACT_TO_DELETE,
    payload: companyContactId,
});

export const setCompanyPageCompanyContactSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions) => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_COMPANY_CONTACTS_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

/**
 * ADRESSEN
 */
export const setCompanyPageLocationToDeleteAction = (activityId: number) => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_TO_DELETE,
    payload: activityId,
});

export const setCompanyPageLocationSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions) => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_LOCATION_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

/**
 * VAKANZEN
 */
export const setCompanyPageVacancySortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions) => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCompanyPageVacancyToDeleteAction = (vacancyId: number) => ({
    type: COMPANY_PAGE_SET_EDIT_VIEW_VACANCY_TO_DELETE,
    payload: vacancyId,
});

export const setCompanyPageVacancyListFilterAction = (filter: Partial<IVacancyFilter>): ISetCompanyPageVacancyListFilterAction => ({
    type: COMPANY_PAGE_SET_VACANCY_LIST_FILTER,
    payload: filter
});

export const setCompanyPageContactVacancyListFilterAction = (filter: Partial<IVacancyFilter>): ISetCompanyPageVacancyListFilterAction => ({
    type: COMPANY_PAGE_SET_CONTACT_VACANCY_LIST_FILTER,
    payload: filter
});

/**
 * PROZESSE
 */
export const setCompanyPageRecruitingListFilterAction = (filter: Partial<IRecruitingFilter>): ISetCompanyPageRecruitingListFilterAction => ({
    type: COMPANY_PAGE_SET_RECRUITING_LIST_FILTER,
    payload: filter
});

export const setCompanyPageContactRecruitingListFilterAction = (filter: Partial<IRecruitingFilter>): ISetCompanyPageRecruitingListFilterAction => ({
    type: COMPANY_PAGE_SET_CONTACT_RECRUITING_LIST_FILTER,
    payload: filter
});

export const setCompanyPageRecruitingListFilterMenuAction = (filter: Partial<IRecruitingFilterMenu>): ISetCompanyPageRecruitingListFilterMenuAction => ({
    type: COMPANY_PAGE_SET_RECRUITING_LIST_FILTER_MENU,
    payload: filter
});
