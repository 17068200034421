import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import { setVacancyPageAddSuggestionAction } from '../../redux/vacancyPage/vacancyPage.actions';
import { translate } from '../../translation/translate.utils';
import AddSuggestionForVacancyForm from './components/components/AddSuggestionForVacancyForm';

const AddSuggestionForVacancyView: React.FC = () => {
    const open = useSelector((store: IStore) => store.vacancyPage.addSuggestionView.open);
    const initialVacancyId = useSelector((store: IStore) => store.vacancyPage.addSuggestionView.vacancyId || 0);
    const currentVacancy = useSelector((store: IStore) => store.entities.vacancies.listModelById[initialVacancyId] || store.entities.vacancies.byId[initialVacancyId]);

    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setVacancyPageAddSuggestionAction({
            open: false,
            candidateId: undefined,
            vacancyId: undefined
        }));
    };

    if (!open || !currentVacancy) {
        return null;
    }

    return (
        <CustomDialog maxWidth={'md'} onClose={onClose} open={true}>
            <CustomDialogHeader string={translate("pages.vacancy.addSuggestion")} onClose={onClose}/>
            <AddSuggestionForVacancyForm vacancy={currentVacancy} onClose={onClose}/>
        </CustomDialog>
    );
};

export default AddSuggestionForVacancyView;

