import React, { useEffect, useState } from 'react';
import CustomTextField from '../../../../components/CustomInput/CustomTextField';
import { translate } from '../../../../translation/translate.utils';
import {
    EEntities,
    EHomeOfficePossibility,
    ICompanyContactPartial,
    ICompanyLocationPartial,
    ICompanyPartial,
    IEmployeePartial,
    IEntitySkill,
    ILanguage,
    ISkillPartial,
    IVacancyForApi,
    IVacancySkill,
    IVacancySkillAssign,
    IWebVacancy,
    IWebVacancyPartial,
} from '../../../../definitions/entities.definition';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomCheckboxInput from '../../../../components/CustomInput/CustomCheckboxInput';
import { getLoggedInEmployeeFromStore } from '../../../../selectors/employee.selectors';
import HomeOfficePossibilityDropDown
    from '../../../../components/CustomInput/CustomDropDownInput/HomeOfficePossibilityDropDown';
import CompanyLocationAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyLocationAutocompleteDropDown';
import { getToken, getValidationErrorsFromStore } from '../../../../selectors/app.selectors';
import CompanyContactAutocompleteDropDown
    from '../../../../components/CustomInput/CustomAutocomplete/Legacy/CompanyContactAutocompleteDropDown';
import { setUiCompanyContactAddDataAction, setUiCompanyLocationAddDataAction } from '../../../../redux/ui/ui.actions';
import { EIcons } from '../../../../components/Icons/IconFactory';
import CustomNumberInput from '../../../../components/CustomInput/CustomNumberInput';
import CustomUrlField from '../../../../components/CustomInput/CustomUrlField';
import VacancySkillElement from '../../../RecruitingPage/components/components/VacancySkillElement';
import SelectMultipleSkillView from '../../../Shared/Skill/SelectMultipleSkillView';
import CustomClickableIcon from '../../../../components/CustomInput/CustomClickableIcon';
import BillingConfigurationElement from '../../../Shared/BillingSetting/BillingConfigurationElement';
import CustomDialogActions from '../../../../components/CustomInput/CustomDialogActions';
import WebVacancyAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/WebVacancyAutocomplete';
import CompanyAutocomplete from '../../../../components/CustomInput/CustomAutocomplete/components/CompanyAutocomplete';
import EmployeeAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/EmployeeAutocomplete';
import LanguageMultipleAutocomplete
    from '../../../../components/CustomInput/CustomAutocomplete/components/LanguageMultipleAutocomplete';
import { addVacancyAction, getVacancyAction, updateVacancyAction } from '../../../../redux/entities/entities.actions';
import WebVacancyDataPanel from '../../WebVacancy/WebVacancyDataPanel';
import { Divider } from '@material-ui/core';
import {
    setVacancyPageVacancyToArchiveAction,
    setVacancyPageVacancyToDeleteAction,
} from '../../../../redux/vacancyPage/vacancyPage.actions';
import CustomValidationErrors from '../../../../components/CustomComponent/CustomValidationErrors';
import CustomDatePicker from '../../../../components/CustomInput/CustomDatePicker';
import { getDateStringForApi } from '../../../../utils/date.utils';

interface IProps {
    vacancyId?: number;
    importFromWebVacancy?: IWebVacancy;
    addForCompanyId?: number;
}

const VacancyEditForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId || 0;
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);
    const importFromWebVacancy = props.importFromWebVacancy;
    const loggedInEmployee = useSelector(getLoggedInEmployeeFromStore);
    const validationErrors = useSelector(getValidationErrorsFromStore);

    const [webVacancy, setWebVacancy] = useState<IWebVacancyPartial|null>(vacancy ? vacancy.webVacancy || null : (importFromWebVacancy || null));
    const [count, setCount] = useState(vacancy?.count || 1);
    const [title, setTitle] = useState(vacancy?.title || importFromWebVacancy?.title || '');
    const [url, setUrl] = useState(vacancy?.url || '');
    const [urlExtern, setUrlExtern] = useState(vacancy?.urlExtern || '');
    const [comment, setComment] =  useState(vacancy?.comment || '');
    const [skills, setSkills] = useState<IEntitySkill[]>(vacancy?.skills || importFromWebVacancy?.skills || []);
    const [company, setCompany] = useState<ICompanyPartial|null>(vacancy?.company || importFromWebVacancy?.company || props.addForCompanyId || null);
    const [responsibleEmployee, setResponsibleEmployee] =  useState<IEmployeePartial|null>(vacancy?.responsibleEmployee || loggedInEmployee);
    const [companyLocation, setCompanyLocation] =  useState<ICompanyLocationPartial|null>(vacancy?.companyLocation || null);
    const [companyContact, setCompanyContact] = useState<ICompanyContactPartial|null>(vacancy?.responsibleContact || null);
    const [salaryFrom, setSalaryFrom] = useState(vacancy?.salaryFrom || 0);
    const [salaryTo, setSalaryTo] = useState(vacancy?.salaryTo || 0);
    const [withLeadership, setWithLeadership] = useState(vacancy?.withLeadership || false);
    const [needDriverLicense, setNeedDriverLicense] = useState(vacancy?.needDriverLicense || false);
    const [homeOfficePossibility, setHomeOfficePossibility] = useState<EHomeOfficePossibility|null>(vacancy?.homeOfficePossibility || EHomeOfficePossibility.no);
    const [partTime, setPartTime] = useState(vacancy?.partTime || importFromWebVacancy?.partTime || false);
    const [languages, setLanguages] = useState<ILanguage[]>(vacancy?.languages || importFromWebVacancy?.languages || []);
    const [suggestionDueDate, setSuggestionDueDate] = useState(vacancy?.suggestionDueDate || null);
    const [suggestionDueSinceDate, setSuggestionDueSinceDate] = useState(vacancy?.suggestionDueSinceDate || null);
    const [addSkillViewOpen, setAddSKillViewOpen] = useState(false);
    const [orderReceivedDate, setOrderReceivedDate] = useState(vacancy?.orderReceivedDate || null);

    useEffect(() => {
        if (!vacancy) {
            return;
        }
        setCount(vacancy.count);
        setTitle(vacancy.title);
        setUrl(vacancy.url || '');
        setUrlExtern(vacancy.urlExtern || '');
        setComment(vacancy.comment);
        setSkills(vacancy.skills);
        setCompany(vacancy.company);
        setResponsibleEmployee(vacancy.responsibleEmployee);
        setCompanyLocation(vacancy.companyLocation);
        setCompanyContact(vacancy.responsibleContact || null);
        setSalaryFrom(vacancy.salaryFrom);
        setSalaryTo(vacancy.salaryTo);
        setWithLeadership(vacancy.withLeadership);
        setNeedDriverLicense(vacancy.needDriverLicense);
        setHomeOfficePossibility(vacancy.homeOfficePossibility);
        setPartTime(vacancy.partTime);
        setLanguages(vacancy.languages);
        setWebVacancy(vacancy.webVacancy || null);
        setSuggestionDueDate(vacancy.suggestionDueDate || null);
        setSuggestionDueSinceDate(vacancy.suggestionDueSinceDate || null);
    }, [vacancy]);

    useEffect(() => {
        if (!importFromWebVacancy) {
            return;
        }
        setCount(1);
        setTitle(importFromWebVacancy.title);
        setSkills(importFromWebVacancy.skills);
        setCompany(importFromWebVacancy.company);
        setPartTime(importFromWebVacancy.partTime);
        setLanguages(importFromWebVacancy.languages);
        setWebVacancy(importFromWebVacancy);
    }, [importFromWebVacancy])

    useEffect(() => {
        if (vacancyId > 0 && (!vacancy || vacancy.id !== vacancyId)) {
            dispatch(getVacancyAction(token, vacancyId, false));
        }
    }, [vacancyId]);

    const save = () => {
        const assignSkills: IVacancySkillAssign[] = skills.map((skill: IEntitySkill) => {
            return {
                id: skill.id,
                importantSkill: skill.importantSkill,
                skillId: skill.skill.id
            }
        });

        const vacancyApiObject: IVacancyForApi = {
            count: count,
            title: title,
            url: url,
            urlExtern: urlExtern,
            comment: comment,
            skills: assignSkills,
            companyId: company?.id || undefined,
            companyLocationId: companyLocation?.id || undefined,
            responsibleEmployeeId: responsibleEmployee?.id || undefined,
            withLeadership: withLeadership,
            needDriverLicense: needDriverLicense,
            salaryFrom: salaryFrom,
            salaryTo: salaryTo,
            homeOfficePossibility: homeOfficePossibility,
            responsibleContactId: companyContact?.id || undefined,
            webVacancyId: webVacancy?.id || undefined,
            partTime: partTime,
            languageIds: languages.map((language) => language.id),
            suggestionDueDate: suggestionDueDate,
            suggestionDueSinceDate: suggestionDueSinceDate,
            orderReceivedDate: orderReceivedDate
        };

        if (!vacancy) {
            dispatch(addVacancyAction(token, vacancyApiObject));
            return;
        }

        vacancyApiObject.id = vacancy.id;
        dispatch(updateVacancyAction(token, vacancyApiObject));
        return;
    }

    const onRemoveSkill = (skill: ISkillPartial) => {
        setSkills(
            skills.filter((vacancySkill: IEntitySkill) => skill.id !== vacancySkill.skill.id)
        )
    };

    const onSetImportantSkill = (skill: ISkillPartial) => {
        setSkills(skills.map((vacancySkill: IEntitySkill) => {
            if (vacancySkill.skill.id === skill.id) {
                return {
                    ...vacancySkill,
                    importantSkill: !vacancySkill.importantSkill
                }
            }
            return vacancySkill;
        }));
    }

    return <>
        <CustomValidationErrors translationKey={'pages.vacancy'}/>
        <div className={"flexContainerColumn gap5 padding10 overflow"}>
            <div className={"flexContainerRow flex1 gap10"}>
                <CompanyAutocomplete
                    required
                    value={company}
                    error={Boolean(validationErrors.companyId)}
                    helperText={validationErrors.companyId}
                    onChange={(value) => {
                        setCompany(value);
                        setCompanyLocation(null);
                    }}
                />
                <CompanyContactAutocompleteDropDown
                    onChange={(event, value: ICompanyContactPartial) => setCompanyContact(value)}
                    onAdd={company ? () => {
                        dispatch(setUiCompanyContactAddDataAction({
                            companyId: company.id
                        }));
                    } : undefined}
                    value={companyContact}
                    companyId={company?.id}
                />
                <CompanyLocationAutocompleteDropDown
                    onChange={(event, value) => setCompanyLocation(value)}
                    onAdd={company ? () => {
                        dispatch(setUiCompanyLocationAddDataAction({
                            companyId: company.id
                        }));
                    } : undefined}
                    required
                    error={Boolean(validationErrors.companyLocationId)}
                    helperText={validationErrors.companyLocationId}
                    value={companyLocation}
                    companyId={company?.id}
                    filterByCompany={true}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <EmployeeAutocomplete
                    required
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={responsibleEmployee}
                    onChange={(value) => setResponsibleEmployee(value)}
                    error={Boolean(validationErrors.responsibleEmployeeId)}
                    helperText={validationErrors.responsibleEmployeeId}
                />
                <CustomDatePicker
                    label={'Auftrag erhalten am'}
                    value={orderReceivedDate}
                    flex
                    onChange={(value) => {
                        if (value) {
                            setOrderReceivedDate(getDateStringForApi(value));
                            return;
                        }
                        setOrderReceivedDate(null);
                    }}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <div className={"flex flex1 gap10"}>
                    <CustomNumberInput
                        width={150}
                        value={count}
                        onChange={(value) => setCount(value || 1)}
                        error={Boolean(validationErrors.count)}
                        helperText={validationErrors.count}
                        label={"Anzahl Ziel-Vermittlungen"}
                    />
                    <CustomNumberInput
                        value={salaryFrom}
                        onChange={(value) => setSalaryFrom(value || 0)}
                        suffix={"k €"}
                        label={translate('pages.vacancy.salaryFrom')}
                        max={999}
                    />
                    <CustomNumberInput
                        value={salaryTo}
                        onChange={(value) => setSalaryTo(value || 0)}
                        suffix={"k €"}
                        label={translate('pages.vacancy.salaryTo')}
                        max={999}
                    />
                </div>
                <div className={"flex flex1 gap10"}>
                    <CustomDatePicker
                        label={'Nächster Vorschlag geplant bis'}
                        value={suggestionDueDate}
                        flex
                        onChange={(value) => {
                            if (value) {
                                setSuggestionDueDate(getDateStringForApi(value));
                                setSuggestionDueSinceDate(getDateStringForApi(new Date()));
                                return;
                            }
                            setSuggestionDueDate(null);
                        }}
                    />
                    {suggestionDueDate &&
                        <CustomDatePicker
                            label={'Vorschläge ab Datum berücksichtigen'}
                            value={suggestionDueSinceDate}
                            flex
                            onChange={(value) => {
                                if (value) {
                                    setSuggestionDueSinceDate(getDateStringForApi(value));
                                    return;
                                }
                                setSuggestionDueSinceDate(null);
                            }}
                        />
                    }
                </div>
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomTextField
                    value={title}
                    required={true}
                    onChange={(value) => setTitle(value)}
                    error={Boolean(validationErrors.title)}
                    helperText={validationErrors.title}
                    label={translate('pages.vacancy.properties.title')}
                />
                <WebVacancyAutocomplete
                    value={webVacancy}
                    onChange={(webVacancy) => setWebVacancy(webVacancy)}
                    companyId={company?.id}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomUrlField
                    value={urlExtern}
                    onChange={(value) => setUrlExtern(value)}
                    label={"Externe Url"}
                />
                <CustomUrlField
                    value={url}
                    onChange={(value) => setUrl(value)}
                    label={translate('pages.vacancy.properties.url')}
                    error={Boolean(validationErrors.url)}
                    helperText={validationErrors.url}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <LanguageMultipleAutocomplete
                    value={languages}
                    onChange={(languages) => setLanguages(languages)}
                    label={"Must-Have Sprache/n"}
                />
                <HomeOfficePossibilityDropDown
                    onChange={(homeOfficePossibility) => setHomeOfficePossibility(homeOfficePossibility)}
                    value={homeOfficePossibility}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomTextField
                    multiline
                    rows={8}
                    value={comment}
                    onChange={(value) => setComment(value)}
                    label={translate('misc.furtherInformation')}
                />
            </div>
            <div className={"flexContainerRow flex1 gap10"}>
                <CustomCheckboxInput
                    labelTranslation={"pages.vacancy.properties.withLeadership"}
                    checked={withLeadership}
                    onChange={() => setWithLeadership(!withLeadership)}
                />
                <CustomCheckboxInput
                    labelTranslation={"pages.vacancy.properties.needDriverLicense"}
                    checked={needDriverLicense}
                    onChange={() => setNeedDriverLicense(!needDriverLicense)}
                />
                <CustomCheckboxInput
                    labelTranslation={"pages.vacancy.partTime"}
                    checked={partTime}
                    onChange={() => setPartTime(!partTime)}
                />
            </div>
            <Divider/>
            <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"}>
                <h4 style={{margin:10, marginLeft: 0}}>Skills ({skills.length})</h4>
                <CustomClickableIcon
                    onClick={() => {
                        setAddSKillViewOpen(true);
                    }}
                    tooltip={"Skill hinzufügen"}
                    icon={EIcons.AddBox}
                />
            </div>
            <div className={"flexContainerRow"} style={{alignItems: 'start'}}>
                <div className={"flexContainerRow gap5 flexWrap"}>
                    {skills.map((vacancySkill, index) =>
                        <VacancySkillElement
                            key={index}
                            skill={vacancySkill as IVacancySkill}
                            onRemove={onRemoveSkill}
                            onSetImportant={onSetImportantSkill}
                        />
                    )}
                </div>
            </div>
            {(vacancy && vacancy.webVacancy) &&
                <>
                    <Divider/>
                    <div className={"flexContainerRow alignVerticalCenter flexAutoGrow flexWrap"}>
                        <h4 style={{margin:10, marginLeft: 0}}>Informationen aus der Stellenanzeige</h4>
                    </div>
                    <WebVacancyDataPanel webVacancyId={vacancy.webVacancy.id} />
                </>
            }
            { vacancy &&
                <>
                    <Divider/>
                    <div className={"flexContainerRow flex1 gap10"}>
                        <BillingConfigurationElement
                            billingSetting={vacancy}
                            entity={EEntities.VacancyList}
                            entityId={vacancy.id}
                        />
                    </div>
                </>
            }
            <SelectMultipleSkillView
                open={addSkillViewOpen}
                onClose={() => setAddSKillViewOpen(false)}
                setSkills={(skills) => setSkills(skills)}
                suggestForJobTitle={title}
                initialSkills={skills}
                setSkillImportant
            />
        </div>
        <CustomDialogActions
            divider
            buttons={[{
                hidden: !props.vacancyId,
                void: () => dispatch(setVacancyPageVacancyToArchiveAction(vacancyId)),
                label: 'Archivieren'
            }, {
                hidden: !props.vacancyId,
                void: () => dispatch(setVacancyPageVacancyToDeleteAction(vacancyId)),
                label: translate('misc.buttons.delete')
            }, {
                void: () => save(),
                label: translate('misc.buttons.save')
            }]}
        />
    </>;
}

export default VacancyEditForm;
