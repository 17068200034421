import { IStore } from '../../definitions/redux/store.definitions';
import { useDispatch, useSelector } from 'react-redux';
import { setVacancyPageSuggestionToShowHistoryAction } from '../../redux/vacancyPage/vacancyPage.actions';
import React from 'react';
import CustomDialogHeader from '../../components/CustomDialog/CustomDialogHeader';
import CustomDialogBody from '../../components/CustomDialog/CustomDialogBody';
import CustomDialogActions from '../../components/CustomInput/CustomDialogActions';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import CandidateActivityChatPanel from '../EditViews/Candidate/components/CandidateActivityChatPanel';

const SuggestionHistoryView: React.FC = () => {
    const open = useSelector((store: IStore) => Boolean(store.vacancyPage.suggestionToShowHistory));
    const idToShowHistory = useSelector((store: IStore) => store.vacancyPage.suggestionToShowHistory);
    const suggestion = useSelector((store: IStore) => store.entities.recruitingSuggestion.byId[idToShowHistory]);
    const dispatch = useDispatch();

    const onClose = () => {
        dispatch(setVacancyPageSuggestionToShowHistoryAction(0));
    }

    if (!open) {
        return null;
    }

    return <CustomDialog maxWidth={"md"} open={open} onClose={onClose} >
        <CustomDialogHeader string={'Favoriten-Historie'} onClose={onClose} />
        <CustomDialogBody>
            <CandidateActivityChatPanel candidateId={suggestion.candidate.id}/>
        </CustomDialogBody>
        <CustomDialogActions
            onClose={onClose}
        />
    </CustomDialog>;
};

export default SuggestionHistoryView;
