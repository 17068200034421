import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Rating } from '@material-ui/lab';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { themeOrange } from '../../theme/theme';

interface IProps {
    value?: boolean;
    onChangePin: () => void;
    tooltip: string
    icon: EIcons;
}

const RatingIcon: React.FC<IProps> = (props) => {
    return (
        <>
            <div>
                <Tooltip title={props.tooltip} placement="top-start">
                    <Rating
                        value={props.value ? 1 : 0}
                        precision={1}
                        max={1}
                        icon={IconFactory.getIconComponent(props.icon, {color: themeOrange.palette.primary.main})}
                        emptyIcon={IconFactory.getIconComponent(props.icon)}
                        onChange={props.onChangePin}
                    />
                </Tooltip>
            </div>
        </>
    );
}

export default RatingIcon;
