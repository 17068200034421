import React from 'react';
import { IBranch, TBrainzEntity } from '../../../../definitions/entities.definition';
import { getBranchListRouteConfig } from '../../../../requests/routes';
import { translate } from '../../../../translation/translate.utils';
import CustomMultipleAutocomplete from '../CustomMultipleAutocomplete';

interface IProps {
    value: IBranch[]|number[];
    onChange: (value: IBranch[]) => void;
    autoFocus?: boolean;
    error?: boolean;
    helperText?: string;
    required?: boolean;
    disabled?: boolean;
    onlyWithCompanies?: boolean;
}

const BranchMultipleAutocomplete: React.FC<IProps> = (props) => {
    const value = props.value;
    const onlyWithCompanies = props.onlyWithCompanies;

    const onChange = (value: TBrainzEntity[]) => {
        props.onChange(value as IBranch[]);
    }

    const getOptionLabel = (record: TBrainzEntity|null) => {
        return (record as IBranch).name;
    }

    return <CustomMultipleAutocomplete
        id={"branch-multiple-autocomplete"}
        label={translate('pages.company.properties.branches')}
        value={value}
        disabled={props.disabled}
        autoFocus={props.autoFocus}
        error={props.error}
        helperText={props.helperText}
        required={props.required}
        onChange={onChange}
        routeConfig={getBranchListRouteConfig}
        getOptionLabel={getOptionLabel}
        routeParams={{
            onlyWithCompanies: onlyWithCompanies
        }}
    />;
}

export default React.memo(BranchMultipleAutocomplete);
