import { IRouteConfig } from '../../../definitions/requests.definitions';
import { TBrainzEntity } from '../../../definitions/entities.definition';
import { ClientApi } from '../../../requests/ClientApi';

export const getOptionLabel = (record: TBrainzEntity|number|null, callbackFn: (record:TBrainzEntity) => string) => {
    if (!record) {
        return '';
    }

    if (typeof record === "object") {
        return callbackFn(record);
    }

    return '';
}

export const executeLoadRecordsRequest = (
    token: string,
    setLoading: (bool:boolean) => void,
    setRecords:(entities: TBrainzEntity[]) => void,
    routeConfig: IRouteConfig,
    routeParams: object,
    callback?: (entities: TBrainzEntity[]) => void
) => {
    setLoading(true);
    ClientApi.request(routeConfig, {
        token,
        ...routeParams
    }).then((result: {records: TBrainzEntity[], total: number } | TBrainzEntity[]) => {
        let records: TBrainzEntity[] = [];
        if (result.hasOwnProperty('records')) {
            //@ts-ignore
            records = result.records;
        } else {
            //@ts-ignore
            records = result;
        }

        setRecords(records);
        setLoading(false);
        if (callback) {
            callback(records);
        }
        return;
    });
}

