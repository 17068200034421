import React from 'react';
import { Badge, createStyles, Divider, Theme, Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { ERoutes } from '../../definitions/app.definitions';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import { translate } from '../../translation/translate.utils';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { IStore } from '../../definitions/redux/store.definitions';
import { darkest } from '../../theme/theme';

interface IProps {
    onClick: (route: ERoutes) => void;
    translation: string;
    icon: EIcons;
    color: string;
    customKey: number;
    route: ERoutes;
    currentRoute?: string;
    badgeContent?: number;
}

const createListItemStyles = makeStyles<Theme, { color: string }>(
    createStyles({
        all: {
            marginLeft: 5,
            marginRight: 5,
            width: 47,
            minHeight: 45,
            borderRadius: 100,
            "&:hover": {
                backgroundColor: ({ color }) => color + ' !important',
            },
            "&:hover .MuiListItemText-root": {
                color: darkest,
            },
            "&:hover .MuiSvgIcon-root": {
                color: darkest + "!important",
            },
        },
        selected: {
            backgroundColor: ({ color }) => color,
        },
    })
);

const CustomDrawerItem: React.FC<IProps> = (props) => {
    const listItemStyleClasses = createListItemStyles(props);

    const selectedRoute = useSelector((store: IStore) => store.ui.currentRoute);
    const isActive = props.route === selectedRoute;
    const badgeContent = props.badgeContent;

    const listItemClassName = isActive
        ? `${listItemStyleClasses.all} ${listItemStyleClasses.selected}`
        : listItemStyleClasses.all;

    return (
        <>
            <Tooltip title={translate(props.translation)} placement="right">
                <ListItem className={listItemClassName} onClick={() => props.onClick(props.route)} button key={props.customKey}>
                    <ListItemIcon>
                        {badgeContent ?
                            (
                                <Badge color={"error"} badgeContent={badgeContent}>
                                    {IconFactory.getIconComponent(props.icon, {
                                        color: isActive ? darkest : props.color,
                                        marginLeft: -5
                                    })}
                                </Badge>
                            ) : IconFactory.getIconComponent(props.icon, {
                                color: isActive ? darkest : props.color,
                                marginLeft: -5
                                })
                        }

                    </ListItemIcon>
                </ListItem>
            </Tooltip>
            <Divider style={{marginTop: 5, marginBottom: 5}} />
        </>
    );
};

export default CustomDrawerItem;
