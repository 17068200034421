import React from 'react';
import { EEntities } from '../../../../definitions/entities.definition';
import { translate } from '../../../../translation/translate.utils';
import { EFilterPanelType } from '../../../../definitions/filter.definitions';
import { useDispatch, useSelector } from 'react-redux';
import CustomFastSearchPanel from '../../../../components/CustomTopBarPanel/FastSearchPanel/CustomFastSearchPanel';
import { IAppBarConfig } from '../../../../definitions/ui.definitions';
import { IStore } from '../../../../definitions/redux/store.definitions';
import { setBillingPageFastSearchAction } from '../../../../redux/billingPage/billingPage.actions';
import { EBillingPageTabs } from '../../../../definitions/billingPage.definitions';

const FastSearchPanelBillingPage: React.FC = () => {
    const dispatch = useDispatch();
    const fastSearchInvoice = useSelector((store: IStore) => store.billingPage.filterInvoiceList.fastSearch);
    const billingTab = useSelector((store: IStore) => store.billingPage.activeTab);

    if (billingTab !== EBillingPageTabs.tabArchiv) {
        return null;
    }

    const configuration: IAppBarConfig = {
        entity: EEntities.Invoice,
        value: fastSearchInvoice ?? '',
        emptyValue: translate('misc.fastSearchEmptyText'),
        fastSearchCallback: (value: string) => dispatch(setBillingPageFastSearchAction(value)),
        filterPanelType: EFilterPanelType.popupMenu
    };

    return <>
        <CustomFastSearchPanel appBarConfig={configuration}/>
    </>
}

export default FastSearchPanelBillingPage;
