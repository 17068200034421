import React, { useEffect, useState } from 'react';
import { Card, CardHeader, Divider } from '@material-ui/core';
import styles from '../Todo/TodoCard.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { EProcessStatus, IProcessAggregation } from '../../../definitions/entities.definition';
import CandidateSourceRenderer from '../../../components/CustomCellRenderer/CandidateSourceRenderer';
import { IStore } from '../../../definitions/redux/store.definitions';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import DateCellRenderer from '../../../components/CustomCellRenderer/DateCellRenderer';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { EIcons } from '../../../components/Icons/IconFactory';
import { setUiEditView } from '../../../redux/ui/ui.actions';
import { EEntityView } from '../../../definitions/ui.definitions';
import { setApplicationPageEditAction } from '../../../redux/applicationPage/applicationPage.actions';
import { translate } from '../../../translation/translate.utils';
import Nl2brCellRenderer from '../../../components/CustomCellRenderer/Nl2brCellRenderer';
import CompanyCellRenderer from '../../../components/CustomCellRenderer/CompanyCellRenderer';
import { fixUrl } from '../../../utils/application.utils';
import { setRecruitingPageContractToEditAction } from '../../../redux/recruitingPage/recruitingPage.actions';

interface IProps {
    process: IProcessAggregation;
}

const getTitleHeader = (titleType: 'vacancy'|'url'|'initiative'|'text', actionOpenVacancy: () => void, url: string) => {
    if (titleType === 'vacancy') {
        return <>
            Vakanz&nbsp;
            <CustomClickableIcon onClick={actionOpenVacancy} icon={EIcons.Launch}/>
        </>;
    }

    if (titleType === 'url') {
        return <>
            Url&nbsp;
            <CustomClickableIcon onClick={() => {
                window.open(fixUrl(url));
            }} icon={EIcons.Launch}/>
        </>;
    }

    if (titleType === 'text') {
        return 'Vermittlung';
    }

    if (titleType === 'initiative') {
        return 'Initiativ-Bewerbung/Vorschlag';
    }
}

const RenderProcessListItem: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const process = props.process;
    const expandOrCollapseItems = useSelector((store: IStore) => store.ui.expandOrCollapseProcessListItems);
    const [showDetails, setShowDetails] = useState(expandOrCollapseItems === 'expand');
    const showAbortInformation = props.process.status === EProcessStatus.STATUS_DECLINE;
    const showContractInformation = props.process.status === EProcessStatus.STATUS_CONTRACT && props.process.recruiting;

    useEffect(() => {
        setShowDetails(expandOrCollapseItems === 'expand');
    }, [expandOrCollapseItems])

    return <>
        <div>
            <Card className={styles.root} style={{cursor: 'default'}}>
                <CardHeader
                    title={
                        <>
                            <div className={"flex"}>
                                <div
                                    className={"flex1 flexContainerRow cursorPointer"}
                                    onClick={() => () => setShowDetails(!showDetails)}
                                >
                                    <div className={"flexContainerColumn"} style={{width: 300}}>
                                        <div className={"flexContainerRow formLabelSmall padding5"}>
                                            {getTitleHeader(
                                                process.titleType,
                                                () => dispatch(setUiEditView(EEntityView.vacancy, process.vacancy?.id||0)),
                                                process.title
                                            )}
                                        </div>
                                        <div className={"alignVerticalCenter padding5"}>
                                            {process.title}
                                        </div>
                                        {process.company &&
                                            <>
                                                <Divider orientation={"horizontal"}/>
                                                <div className={"formLabelSmall padding5"}>Firma</div>
                                                <div className={"alignVerticalCenter padding5"}>
                                                <CompanyCellRenderer company={process.company}/>
                                                </div>
                                            </>
                                        }
                                        <Divider orientation={"horizontal"}/>
                                        <div className={"formLabelSmall padding5"}>Quelle</div>
                                        <div className={"alignVerticalCenter padding5"}>
                                            <CandidateSourceRenderer source={process.source}/>
                                        </div>
                                        <Divider orientation={"horizontal"}/>
                                        <div className={"formLabelSmall padding5"}>Beteiligte Mitarbeiter</div>
                                        <div className={"alignVerticalCenter gap5 padding5"}>
                                            {process.employees.map((employee) => <EmployeeCellRenderer size={"small"} key={employee.id} employee={employee}/> )}
                                        </div>
                                    </div>
                                    <Divider orientation={"vertical"}/>
                                    <div className={"flexContainerColumn"}>
                                        <div className={"flexContainerRow formLabelSmall padding5"}>
                                            Verknüpfte Datensätze
                                        </div>
                                        {process.contract &&
                                        <>
                                            <div className={"flexContainerRow padding5"}>
                                                Vertrag&nbsp;
                                                <CustomClickableIcon onClick={() => dispatch(setRecruitingPageContractToEditAction(process.contract.id))} icon={EIcons.Launch}/>
                                            </div>
                                        </>
                                        }
                                        {process.recruiting &&
                                            <>
                                                <div className={"flexContainerRow padding5"}>
                                                    Prozess&nbsp;
                                                    <CustomClickableIcon onClick={() => dispatch(setUiEditView(EEntityView.recruiting, process.recruiting.id))} icon={EIcons.Launch}/>
                                                </div>
                                            </>
                                        }
                                        {process.application &&
                                        <>
                                            <div className={"flexContainerRow padding5"}>
                                                Bewerbung
                                                <CustomClickableIcon onClick={() => dispatch(setApplicationPageEditAction(process.application.id))} icon={EIcons.Launch}/>
                                            </div>
                                        </>
                                        }
                                        {process.suggestion &&
                                        <>
                                            <div className={"flexContainerRow padding5"}>
                                                Favorit&nbsp;
                                                <CustomClickableIcon onClick={() => dispatch(setUiEditView(EEntityView.suggestion, process.suggestion.id))} icon={EIcons.Launch}/>
                                            </div>
                                        </>
                                        }
                                    </div>
                                    <Divider orientation={"vertical"}/>
                                    {showAbortInformation &&
                                        <div className={"flexContainerColumn padding5"}>
                                            <div className={"flexContainerRow formLabelSmall"}>
                                                Details zur Absage
                                            </div>
                                            {process.recruiting &&
                                            <>
                                                <div className={"flexContainerColumn"}>
                                                    <div>{translate('pages.recruiting.dateOfAbort')}:</div>
                                                    <div className={"formContent height100"}>
                                                        { process.recruiting.dateOfAbort && <DateCellRenderer date={process.recruiting.dateOfAbort} />}
                                                    </div>

                                                    <div>{translate('pages.recruiting.abortedBy')}:</div>
                                                    <div className={"formContent height100"}>{process.recruiting.abortedBy ? translate('misc.' + process.recruiting.abortedBy) : '-'}</div>

                                                    <div>{translate('pages.recruiting.abortComment')}:</div>
                                                    <div className={"formContent height100"}><Nl2brCellRenderer value={process.recruiting.abortComment} /></div>
                                                </div>
                                            </>
                                            }
                                            {!process.recruiting && process.suggestion &&
                                            <div className={"flexContainerColumn"}>
                                                    {process.suggestion.declineReasonComment
                                                        ? <>
                                                            <div>{translate('pages.recruiting.abortComment')}:</div>
                                                            <div className={"formContent height100"}>
                                                                <Nl2brCellRenderer
                                                                    value={process.suggestion.declineReasonComment || 'Vakanz wurde ins Archiv verschoben'}/>
                                                            </div>
                                                        </>
                                                        : <>Vakanz wurde ins Archiv verschoben</>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    }
                                    {showContractInformation &&
                                        <div className={"flexContainerColumn padding5"}>
                                            <div className={"flexContainerRow formLabelSmall"}>
                                                Details zur Vermittlung
                                            </div>
                                            {process.recruiting &&
                                            <>
                                                <div className={"flexContainerColumn"}>
                                                    <div>{translate('pages.recruiting.dateOfAbort')}:</div>
                                                    <div className={"formContent height100"}>
                                                        { process.recruiting.dateOfAbort && <DateCellRenderer date={process.recruiting.dateOfAbort} />}
                                                    </div>

                                                    <div>{translate('pages.recruiting.abortedBy')}:</div>
                                                    <div className={"formContent height100"}>{process.recruiting.abortedBy ? translate('misc.' + process.recruiting.abortedBy) : '-'}</div>

                                                    <div>{translate('pages.recruiting.abortComment')}:</div>
                                                    <div className={"formContent height100"}><Nl2brCellRenderer value={process.recruiting.abortComment} /></div>
                                                </div>
                                            </>
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </>
                    }
                    titleTypographyProps={{
                        variant: "body2"
                    }}
                    subheaderTypographyProps={{
                        variant: "body2"
                    }}
                />
            </Card>
        </div>
    </>;
}

export default RenderProcessListItem;
