import { IStore } from '../definitions/redux/store.definitions';
import { IGetRequestObject } from '../definitions/requests.definitions';
import { buildRequestObject } from './entity.selectors';
import { ISuggestion, ISuggestionsGrouped } from '../definitions/entities.definition';
import _ from 'underscore';

export const groupSuggestions = (groupBy: 'status'|'progressStatus'|'group', suggestions: ISuggestion[]): ISuggestionsGrouped => {
    if (groupBy === 'status') {
        return _.groupBy(suggestions, function (suggestion) {
            return suggestion.status;
        });
    }

    if (groupBy === 'progressStatus') {
        return _.groupBy(suggestions, function (suggestion) {
            return suggestion.progressStatus;
        });
    }

    return _.groupBy(suggestions, function (suggestion) {
        return suggestion.group.ranking + '. ' + suggestion.group.title;
    });
}

export const getSuggestionsForVacancyFromStore = (store: IStore, id: number) => {
    const suggestionsById = store.entities.recruitingSuggestion.byId;
    const suggestionIdsByVacancyId = store.entities.recruitingSuggestion.byVacancy[id] || [];

    const recruitingSuggestions: ISuggestion[] = [];

    suggestionIdsByVacancyId.forEach((suggestionId) => {
        if (suggestionsById[suggestionId]) {
            recruitingSuggestions.push(suggestionsById[suggestionId])
        }
    });

    return recruitingSuggestions;
};

export const getSuggestionsForCandidateFromStore = (store: IStore, id: number) => {
    const suggestionsById = store.entities.recruitingSuggestion.byId;
    const suggestionIdsByVacancyId = store.entities.recruitingSuggestion.byCandidate[id] || [];

    const recruitingSuggestions: ISuggestion[] = [];

    suggestionIdsByVacancyId.forEach((suggestionId) => {
        if (suggestionsById[suggestionId]) {
            recruitingSuggestions.push(suggestionsById[suggestionId])
        }
    });

    return recruitingSuggestions;
};

export const getSuggestionsForListViewFromStore = (store: IStore): ISuggestion[]  => {
    const order = store.entities.recruitingSuggestion.orderListView;
    const suggestionsById = store.entities.recruitingSuggestion.byId;

    return order.reduce((accumulator: ISuggestion[], suggestionId) => {
        accumulator.push(suggestionsById[suggestionId]);

        return accumulator;
    }, []);
};

export const getTopSuggestionsFromStore = (store: IStore): ISuggestion[] => {
    const order = store.entities.recruitingSuggestion.orderTop;
    const suggestionsById = store.entities.recruitingSuggestion.byId;

    return order.reduce((accumulator: ISuggestion[], suggestionId) => {
        accumulator.push(suggestionsById[suggestionId]);

        return accumulator;
    }, []);
};

export const getVacancyPageRequestObjectForSuggestionsInEditView = (store: IStore) => {
    const sorting = store.vacancyPage.suggestionOfVacancySorting;
    const fastSearch = store.vacancyPage.suggestionOfVacancyFilter.fastSearch;
    if (fastSearch !== undefined && fastSearch.trim() !== '') {
        return buildRequestObject(sorting, {
            fastSearch,
            start: 0,
            limit: 0
        });
    }

    return buildRequestObject(sorting, {
        ...store.vacancyPage.suggestionOfVacancyFilter,
        start: 0,
        limit: 0
    });
};

export const getVacancyPageRequestObjectForSuggestionsListView = (store: IStore, vacancyId: number) => {
    const sorting = store.vacancyPage.suggestionListViewSorting;
    const filter = store.vacancyPage.suggestionListViewFilter;
    filter.start = 0;
    filter.limit = 0;
    filter.vacancyId = vacancyId;

    return buildRequestObject(sorting, filter);
};

export const getRecruitingPageSuggestionListRequestObject = (store: IStore): Partial<IGetRequestObject> => {
    const sorting = store.recruitingPage.suggestionSorting;
    const filter = store.recruitingPage.filterTabSuggestion;

    return buildRequestObject(sorting, filter);
};
