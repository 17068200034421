import { createStyles, makeStyles, MenuItem } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IStore } from '../../../../definitions/redux/store.definitions';
import CustomMenu from '../../../CustomMenu/CustomMenu';
import CustomButton from '../../../CustomInput/CustomButton';
import { translate } from '../../../../translation/translate.utils';
import LegacyEmployeeAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyEmployeeAutocompleteDropDown';
import { ICompany, IEmployee, ISkill } from '../../../../definitions/entities.definition';
import LegacySkillAutocompleteMultipleDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacySkillAutocompleteMultipleDropDown';
import LegacyCompanyMultipleAutocompleteDropDown
    from '../../../CustomInput/CustomAutocomplete/DelegateButLegacy/LegacyCompanyMultipleAutocompleteDropDown';
import CustomCheckboxInput from '../../../CustomInput/CustomCheckboxInput';
import CustomTextField from '../../../CustomInput/CustomTextField';
import {
    resetCandidatePageVacancySearchFilterAction,
    setCandidatePageVacancySearchFilterAction,
    setCandidatePageVacancySearchFilterMenuAction,
} from '../../../../redux/candidatePage/candidatePage.actions';
import YesNoShowAllDropDown from '../../../CustomInput/CustomDropDownInput/YesNoShowAllDropDown';
import BranchMultipleAutocomplete from '../../../CustomInput/CustomAutocomplete/components/BranchMultipleAutocomplete';

interface IProps {
    anchorEl: null | any;
    removeAnchor: VoidFunction;
}

const useStyles = makeStyles(
    createStyles({
        customWidth: {
            "& ul": {
                minWidth: "500px",
            },
        },
    })
);

const CustomFilterMenuCandidateVacancySearch: React.FC<IProps> = (props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const filter = useSelector((store: IStore) => store.candidatePage.filterVacancyList);
    const filterMenu = useSelector((store: IStore) => store.candidatePage.filterMenuVacancyList);

    const submit = () => {
        dispatch(
            setCandidatePageVacancySearchFilterAction({
                fastSearch: filter.fastSearch,
                start: filter.start,
                limit: filter.limit,
                responsibleEmployeeId: filterMenu.responsibleEmployee?.id,
                branchIds: filterMenu.branches?.map(({ id }) => id),
                skillIds: filterMenu.skills?.map(({ id }) => id),
                companyIds: filterMenu.companies?.map(({ id }) => id),
                onlyWithContracts: filterMenu.onlyWithContracts,
                location: filterMenu.location,
                distance: filterMenu.distance,
                onlyPartTime: filterMenu.onlyPartTime,
                languageId: filterMenu.languages ? filterMenu.languages.id : undefined,
                remote: filterMenu.remote
            })
        );
        props.removeAnchor();
    };

    return (
        <CustomMenu className={styles.customWidth} anchorEl={props.anchorEl} removeAnchor={props.removeAnchor}>
            <MenuItem>
                <CustomButton onClick={submit} label={translate("misc.buttons.applyFilters")} />
                <CustomButton
                    color="secondary"
                    onClick={() => {
                        dispatch(resetCandidatePageVacancySearchFilterAction());
                    }}
                    label={translate("misc.buttons.clearFilters")}
                />
            </MenuItem>
            <MenuItem>
                <CustomTextField
                    clearButton
                    value={filterMenu.distance}
                    onChange={(value) =>
                        dispatch(
                            setCandidatePageVacancySearchFilterMenuAction({
                                distance: value !== "" ? parseInt(value) : undefined,
                            })
                        )
                    }
                    label={'Distanz in km'}
                />
            </MenuItem>
            <MenuItem>
                <YesNoShowAllDropDown
                    onChange={(value) =>
                        dispatch(
                            setCandidatePageVacancySearchFilterMenuAction({
                                remote: value,
                            })
                        )
                    }
                    yesText={'Nur Remote stellen'}
                    noText={'Remote stellen ausblenden'}
                    allText={'Alle Stellen anzeigen'}
                    value={filterMenu.remote}
                    label={'Remote-Stellen'}
                />
            </MenuItem>
            <MenuItem>
                <LegacySkillAutocompleteMultipleDropDown
                    value={filterMenu.skills}
                    onChange={(skills: ISkill[]) => dispatch(setCandidatePageVacancySearchFilterMenuAction({skills}))}
                />
            </MenuItem>
            <MenuItem>
                <LegacyCompanyMultipleAutocompleteDropDown
                    value={filterMenu.companies}
                    onlyWithCrawler
                    onChange={(companies: ICompany[]) => dispatch(setCandidatePageVacancySearchFilterMenuAction({companies}))}
                />
            </MenuItem>
            <MenuItem>
                <CustomCheckboxInput
                    labelTranslation={"pages.webVacancy.showOnlyWithContracts"}
                    checked={filterMenu.onlyWithContracts}
                    onChange={() => dispatch(
                        setCandidatePageVacancySearchFilterMenuAction({
                            onlyWithContracts: !filterMenu.onlyWithContracts,
                        })
                    )}
                />
            </MenuItem>
            <MenuItem>
                <CustomCheckboxInput
                    labelTranslation={"pages.webVacancy.showOnlyPartTime"}
                    checked={filterMenu.onlyPartTime}
                    onChange={() => dispatch(
                        setCandidatePageVacancySearchFilterMenuAction({
                            onlyPartTime: !filterMenu.onlyPartTime,
                        })
                    )}
                />
            </MenuItem>
            <MenuItem>
                <LegacyEmployeeAutocompleteDropDown
                    label={translate('pages.vacancy.properties.responsibleEmployee')}
                    value={filterMenu.responsibleEmployee}
                    onChange={(event, value: IEmployee) =>
                        dispatch(
                            setCandidatePageVacancySearchFilterMenuAction({
                                responsibleEmployee: value,
                            })
                        )
                    }
                />
            </MenuItem>
            <MenuItem>
                <BranchMultipleAutocomplete
                    value={filterMenu.branches || []}
                    onlyWithCompanies
                    onChange={(branches) =>  dispatch(setCandidatePageVacancySearchFilterMenuAction({ branches }))}
                />
            </MenuItem>
        </CustomMenu>
    );
};

export default CustomFilterMenuCandidateVacancySearch;
