import React, { useState } from 'react';
import { ESuggestionProgressStatus, ISuggestion } from '../../../definitions/entities.definition';
import { EIcons } from '../../../components/Icons/IconFactory';
import CustomClickableIcon from '../../../components/CustomInput/CustomClickableIcon';
import { translate } from '../../../translation/translate.utils';
import RenderSuggestionListItem from './RenderSuggestionListItem';
import CustomDropZone from '../../../components/CustomDragAndDrop/CustomDropZone';
import { EEntityView } from '../../../definitions/ui.definitions';
import ProgressStatusCellRenderer from '../../../components/CustomCellRenderer/ProgressStatusCellRenderer';

interface IProps {
    groupIdentifier: string;
    suggestions: ISuggestion[];
    parentView: EEntityView;
    groupId?: number;
    groupBy?: 'status'|'progressStatus'|'group';
    onEdit?: () => void;
}

const RenderSuggestionListGroup: React.FC<IProps> = (props) => {
    const groupIdentifier = props.groupIdentifier;
    const suggestions = props.suggestions;
    const parentView = props.parentView;
    const [expanded, setExpanded] = useState(true);
    const icon = expanded ? EIcons.ExpandLess : EIcons.ExpandMore;
    const toggleExpand = () => setExpanded(!expanded);
    const groupBy = props.groupBy || 'status';

    const collapseExpandPanel = <>
        <div
            className={"flexContainerRow"}
            onClick={toggleExpand}
            style={{cursor: 'pointer', backgroundColor: 'var(--brainz-darkest)'}}
        >
            <h4 style={{margin: 5, flex: 1}}>
                {groupBy === 'group' &&
                    <>{groupIdentifier} ({suggestions.length})</>
                }
                {groupBy === 'status' &&
                    <>{translate('pages.recruiting.suggestionStatusValues.' + groupIdentifier)} ({suggestions.length})</>
                }
                {groupBy === 'progressStatus' &&
                    <div className={"flexContainerRow alignVerticalCenter"}>
                        <ProgressStatusCellRenderer status={parseInt(groupIdentifier) as ESuggestionProgressStatus}/>
                        <div>&nbsp;({suggestions.length})</div>
                    </div>
                }
            </h4>
            {props.onEdit &&
                <CustomClickableIcon onClick={props.onEdit} icon={EIcons.Edit}/>
            }
            <CustomClickableIcon onClick={toggleExpand} icon={icon}/>
        </div>
    </>;

    if (!expanded) {
        return <>{collapseExpandPanel}</>;
    }

    if (groupBy === 'group' && parentView === EEntityView.vacancy) {
        const groupId = props.groupId as number;
        const rankingOptions = suggestions.map((suggestion): {id: number, rating: number, ranking: number, fullName: string} => {
            return {
                id: suggestion.id,
                rating: suggestion.rating,
                ranking: suggestion.ranking,
                fullName: suggestion.candidate.fullName
            }
        });

        return <>
            {collapseExpandPanel}
            <CustomDropZone
                id={groupId.toString()}
                items={suggestions}
                renderItem={(suggestion) =>
                    <RenderSuggestionListItem
                        suggestion={suggestion as ISuggestion}
                        parentView={EEntityView.vacancy}
                        rankingOptions={suggestions.length > 2 ? rankingOptions : undefined}
                    />
                }
                rootClassName={"flex1 flexContainerColumn gap5"}
            />
        </>
    }

    return <>
        {collapseExpandPanel}
        {suggestions.map((suggestion) =>
            <RenderSuggestionListItem
                key={suggestion.id}
                parentView={parentView}
                suggestion={suggestion}
                displayStatusInformation={groupBy === 'progressStatus' || parentView === EEntityView.candidate}
            />
        )}
    </>
}

export default RenderSuggestionListGroup;
