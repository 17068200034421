import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { memo, useCallback } from 'react';
import { Divider } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomTableClickedRow } from '../../redux/ui/ui.actions';
import { IStore } from '../../definitions/redux/store.definitions';
import { EEntities, TBrainzEntity } from '../../definitions/entities.definition';
import { IListActionButtonConfig, TListAction } from '../../definitions/components.definitions';
import theme from '../../theme/theme';
import CustomContextMenu from '../CustomContextMenu/CustomContextMenu';

const useStyles = makeStyles((theme) =>
    createStyles({
        row: {
            alignItems: "stretch",
            backgroundColor: theme.palette.secondary.main,
        },
        coloring: {
            backgroundColor: theme.palette.background.paper,
        },
        clicked: {
            backgroundColor: theme.palette.secondary.light,
        },
        dragging: {
            backgroundColor: theme.palette.secondary.light,
        },
        hover: {
            "&:hover": {
                backgroundColor: theme.palette.secondary.light
            },
        },
    })
);

interface IProps {
    index: number;
    entity: EEntities;
    entry: TBrainzEntity;
    coloring?: boolean;
    isDragging?: boolean;
    onDoubleClick?: TListAction;
    onClick?: TListAction;
    children?: React.ReactNode;
    listActionButtons?: IListActionButtonConfig[];
    listActionHeading?: (entity: TBrainzEntity) => string;
}

const CustomTableRow: React.FC<IProps> = memo((props) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const normalStyle = styles.row;
    const coloredStyle = `${styles.row} ${styles.coloring}`;
    const clickedRow = useSelector((store: IStore) => store.ui.clickedRow[props.entity]);
    const isRowClickedAlready = clickedRow === props.entry.id;

    const onClick = () => {
        dispatch(setCustomTableClickedRow(props.entry.id, props.entity));
        if (props.onClick) {
            props.onClick(props.entry.id, props.entry, props.index);
            return;
        }
    };

    const onDblClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        dispatch(setCustomTableClickedRow(props.entry.id, props.entity));

        if (props.onDoubleClick) {
            props.onDoubleClick(props.entry.id, props.entry, props.index);
            return;
        }
    };

    let styleToUse = normalStyle;

    if (props.coloring) {
        styleToUse = coloredStyle;
    }
    styleToUse = `${styleToUse} ${styles.hover}`;

    if (isRowClickedAlready) {
        styleToUse = `${styleToUse} ${styles.clicked}`;
    }
    if (props.isDragging) {
        styleToUse = `${styleToUse} ${styles.dragging}`;
    }

    const getDividerBackgroundColor = useCallback(() => {
        if (!isRowClickedAlready) {
            return theme.palette.secondary.light;
        }

        if (props.coloring) {
            return 'var(--brainz-darkest)';
        }

        return 'var(--brainz-darker)';
    }, [isRowClickedAlready, props.coloring]);

    return (
        <>
            <div
                className={"flexContainerRow " + styleToUse}
                onClick={onClick}
                onDoubleClick={onDblClick}
                onContextMenu={(e) => e.stopPropagation()}
            >
                <CustomContextMenu
                    index={props.index}
                    onMenuOpen={onClick}
                    entityId={props.entry.id}
                    entity={props.entry}
                    menuItems={props.listActionButtons || []}
                    menuHeading={props.listActionHeading}
                    style={{ flex:1, display: 'flex', width: '100%' }}
                >
                    {props.children}
                </CustomContextMenu>
            </div>
            {props.entry.id === 0
                ? <Divider style={{background: 'rgba(255, 255, 255, 0.5)', height: 3}} />
                : <Divider style={{background: getDividerBackgroundColor(), height: 2}} />
            }
        </>
    );
});

export default CustomTableRow;
