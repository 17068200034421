import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Chip, Tooltip } from '@material-ui/core';
import { EIcons, IconFactory } from '../Icons/IconFactory';
import styles from './WebVacancyCellRenderer.module.css';
import { darker } from '../../theme/theme';
import { setUiCloseTopDrawer, setUiEditView } from '../../redux/ui/ui.actions';
import { EEntityView } from '../../definitions/ui.definitions';

interface IProps {
    id: number;
    title: string;
    company?: string;
}

const WebVacancyCellRenderer: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();

    return (
        <Tooltip title={props.title} placement="top-start">
            <Chip
                key={props.id}
                icon={IconFactory.getIconComponent(EIcons.Work, {color: darker})}
                color="primary"
                className={styles.chip}
                label={<div className={"flexContainerColumn"}>
                    <div className={"flex1 ellipseText"}>{props.title}</div>
                    <div className={"formContent"} style={{color: 'var(--brainz-darker)',fontSize: 10}}>{props.company}</div>
                </div>
                }
                onClick={(event) => {
                    event.stopPropagation();
                    dispatch(setUiCloseTopDrawer());
                    dispatch(setUiEditView(EEntityView.webVacancy, props.id))
                }}
            />
        </Tooltip>
    );
};

export default memo(WebVacancyCellRenderer);
