import React, { useEffect, useState } from 'react';
import { EIcons, IconFactory } from '../../../components/Icons/IconFactory';
import { darker } from '../../../theme/theme';
import { useDispatch, useSelector } from 'react-redux';
import CustomDashboardWidgetPaper from '../../../components/CustomPaper/CustomDashboardWidgetPaper';
import {
    EEntities,
    IBattery,
    IEmployeeLeagueRecord,
    IEmployeePartial,
} from '../../../definitions/entities.definition';
import { ECellAlign, ICustomTableColumnConfig } from '../../../definitions/components.definitions';
import EmployeeCellRenderer from '../../../components/CustomCellRenderer/EmployeeCellRenderer';
import CustomTable from '../../../components/CustomTable/CustomTable';
import { ClientApi } from '../../../requests/ClientApi';
import { getEmployeeLeagueRouteConfig } from '../../../requests/routes';
import { getToken } from '../../../selectors/app.selectors';
import { Badge, Tooltip } from '@material-ui/core';
import {
    setDashboardPageChartSelectionAction,
    setDashboardPageChartSelectionRecruitingsViewOpenAction,
} from '../../../redux/dashboardPage/dashboardPage.actions';
import Battery from './Battery';

const LeagueTable: React.FC = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [reload, setReload] = useState(false);
    const [entries, setEntries] = useState<IEmployeeLeagueRecord[]>([]);
    const token = useSelector(getToken);

    const load = () => {
        setLoading(true);
        ClientApi.request(getEmployeeLeagueRouteConfig, {
            token
        }).then((result: IEmployeeLeagueRecord[]) => {
            setReload(false);
            setLoading(false);
            setEntries(result);
        }).catch(() => {
            setReload(false);
            setLoading(false);
        });
    }

    useEffect(() => {
        load();
    }, [dispatch]);

    useEffect(() => {
        if (!reload) {
            return;
        }
        load();
    }, [reload]);

    const columnConfig: ICustomTableColumnConfig = {
        ranking: {
            header: <></>,
            property: "ranking",
            disableOrder: true,
            align: ECellAlign.right,
            width: 15,
            cellRenderer: (value) => {
                if (value === 0) {
                    return <>T.&nbsp;</>;
                }
                return <>
                    {value}.&nbsp;
                </>;
            },
        },
        employee: {
            header: <>&nbsp;<br/>MA</>,
            disableOrder: true,
            property: "employee",
            align: ECellAlign.center,
            width: 40,
            cellRenderer: (employee: IEmployeePartial, entity) => {
                return <>
                    <EmployeeCellRenderer employee={employee} />
                </>;
            },
        },
        monthStatus: {
            width: 50,
            header: <>&nbsp;<br/>Monat</>,
            align: ECellAlign.center,
            disableOrder: true,
            property: "monthStatus",
            cellRenderer: (value: IBattery) => {
                return <Battery battery={value}/>
            }
        },
        currentStatus: {
            width: 85,
            header: <>&nbsp;<br/>Umsatz</>,
            align: ECellAlign.center,
            disableOrder: true,
            property: "currentStatus",
            cellRenderer: (value: IBattery) => {
                return <Battery battery={value} size={'medium'}/>
            }
        },
        countContract: {
            width: 20,
            disableOrder: true,
            header: <><Tooltip title={"V - Vermittlungen"}><div>&nbsp;<br/>V</div></Tooltip></>,
            align: ECellAlign.center,
            property: "countContract"
        },
        statusByMonth: {
            header: <>
                <div className={"flexContainerColumn"}>
                    <span style={{marginLeft:10}}>Monate</span>
                    <table>
                        <tr>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>J</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>F</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>M</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>A</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>M</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>J</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>J</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>A</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>S</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>O</td>
                            <td align={"center"} style={{width: 26, borderRight: '1px solid #bbb'}}>N</td>
                            <td align={"center"} style={{width: 26}}>D</td>
                        </tr>
                    </table>

                </div>

            </>,
            disableOrder: true,
            property: 'statusByMonth',
            cellRenderer: (value: {[month: number] : IBattery}, entity) => {
                const entityCast = entity as IEmployeeLeagueRecord;
                return <table>
                    <tr>
                        {Object.entries(value).map((value, index) => {
                            const status = value[1].status;
                            const month = parseInt(value[0]);

                            if (month-1 > (new Date()).getMonth()) {
                                return <td key={index} style={{width: 26, borderRight: index < 11 ? '1px solid #bbb' : ''}}>
                                    <Tooltip title={
                                        <div className={"flexContainerColumn alignVerticalCenter alignHorizontalCenter"}>
                                            <span>Monat liegt in der Zukunft</span>
                                        </div>
                                    }>
                                        {IconFactory.getIconComponent(EIcons.Dot, {opacity: 0.2})}
                                    </Tooltip>
                                </td>;
                            }


                            let iconEl = <></>
                            let title = '';
                            if (status === 'overTop') {
                                title = "TOP-Ziel übertroffen";
                                iconEl = <div key={index} className={'charged'}></div>;
                            }
                            if (status === 'top') {
                                title = "TOP-Ziel erreicht";
                                iconEl = IconFactory.getIconComponent(EIcons.Dot, {color: '#30b455'});
                            }
                            if (status === 'nearToTop') {
                                title = "TOP-Ziel FAST erreicht";
                                iconEl = IconFactory.getIconComponent(EIcons.Dot, {color: '#88b136'});
                            }
                            if (status === 'min') {
                                title = "Mindest-Ziel erreicht";
                                iconEl = IconFactory.getIconComponent(EIcons.Dot, {color: '#EFAF13'});
                            }
                            if (status === 'nearToMin') {
                                title = "Mindest-Ziel FAST erreicht";
                                iconEl = IconFactory.getIconComponent(EIcons.Dot, {color: '#f17f19'});
                            }
                            if (status === 'low') {
                                title = "Mindest-Ziel NICHT erreicht";
                                iconEl = IconFactory.getIconComponent(EIcons.Dot, {color: '#bbb'});
                            }
                            if (status === 'empty') {
                                title = "Kein Umsatz generiert";
                                iconEl = IconFactory.getIconComponent(EIcons.Cancel, {color: '#bbb'});
                            }

                            const open = () => {
                                dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(true));
                                dispatch(setDashboardPageChartSelectionAction({
                                    employeeId: entityCast.employee.id > 0 ? entityCast.employee.id : undefined,
                                    employeeName: entityCast.employee.id > 0 ? entityCast.employee.firstName : undefined,
                                    year: (new Date()).getFullYear(),
                                    month
                                }));
                            }

                            return <td
                                key={index}
                                onClick={open}
                                className={"hover"}
                                style={{width: 26, borderRight: index < 11 ? '1px solid #bbb' : ''}}
                            >
                                <Tooltip title={
                                    <div className={"flexContainerColumn alignVerticalCenter alignHorizontalCenter"}>
                                        <span>{title}</span>
                                        <Battery battery={value[1]} size={'medium'} />
                                    </div>
                                }>
                                    {value[1].best
                                        ?
                                        <Badge
                                            badgeContent={
                                                <img
                                                    style={{marginTop:-9, marginLeft: -7}}
                                                    src={"/images/krone.png"}
                                                    width={22}
                                                />
                                            }
                                        >
                                            {iconEl}
                                        </Badge>
                                        :
                                        <div>
                                            {iconEl}
                                        </div>
                                    }
                                </Tooltip>
                            </td>;
                        })}
                    </tr>
                </table>

            }
        },
        yearStatus: {
            width: 50,
            header: <>&nbsp;<br/>Gesamt</>,
            align: ECellAlign.center,
            disableOrder: true,
            property: "yearStatus",
            cellRenderer: (value: IBattery) => {
                return <Battery battery={value}/>
            }
        },
    };

    return <>
        <CustomDashboardWidgetPaper
            icon={EIcons.List}
            headline={'Brainz Liga ' + (new Date()).getFullYear()}
            style={{minHeight: 420, minWidth: 750}}
        >
            <CustomTable
                config={{
                    noBodyBorder: true,
                    color: darker,
                    entity: EEntities.EmployeePerformance,
                    loading,
                    columnConfig,
                    onRowDoubleClick: (id, entity) => {
                        const entityCast = entity as IEmployeeLeagueRecord;
                        dispatch(setDashboardPageChartSelectionRecruitingsViewOpenAction(true));
                        dispatch(setDashboardPageChartSelectionAction({
                            employeeId: entityCast.employee.id > 0 ? entityCast.employee.id : undefined,
                            employeeName: entityCast.employee.id > 0 ? entityCast.employee.firstName : undefined,
                            year: (new Date()).getFullYear()
                        }));
                    },
                    sortedEntries: entries,
                    onReload: () => {
                        setReload(true);
                    }
                }}
            />
        </CustomDashboardWidgetPaper>
    </>;
}

export default LeagueTable;
