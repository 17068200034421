import React, { useState } from 'react';
import PaperRow from '../../../components/CustomPaper/PaperRow';
import { translate } from '../../../translation/translate.utils';
import CustomDialogActions from '../../../components/CustomInput/CustomDialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { getToken, getValidationErrorsFromStore } from '../../../selectors/app.selectors';
import { IStore } from '../../../definitions/redux/store.definitions';
import CustomCheckboxInput from '../../../components/CustomInput/CustomCheckboxInput';
import CustomNumberInput from '../../../components/CustomInput/CustomNumberInput';
import CustomTextField from '../../../components/CustomInput/CustomTextField';
import { ClientApi } from '../../../requests/ClientApi';
import { postSuggestionGroupRouteConfig, putSuggestionGroupRouteConfig } from '../../../requests/routes';
import { ISuggestionGroup } from '../../../definitions/entities.definition';
import { getSuggestionsForVacancyAction, getVacancyAction } from '../../../redux/entities/entities.actions';
import { getVacancyPageRequestObjectForSuggestionsInEditView } from '../../../selectors/recruitingSuggestion.selectors';
import DeleteSuggestionGroupView from './DeleteSuggestionGroupView';

interface IProps {
    vacancyId: number;
    groupId?: number;
    onClose: () => void;
    onAdd: (suggestionGroup: ISuggestionGroup) => void;
}

const AddEditSuggestionForCandidateForm: React.FC<IProps> = (props) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const vacancyId = props.vacancyId;
    const groupId = props.groupId;
    const vacancy = useSelector((store: IStore) => store.entities.vacancies.byId[vacancyId]);
    const errors = useSelector(getValidationErrorsFromStore);
    const group = groupId ? vacancy.groups.filter((group) => group.id === groupId)[0] : null;
    const suggestionRequestObject = useSelector(getVacancyPageRequestObjectForSuggestionsInEditView);

    const [title, setTitle] = useState<string>(group?.title || "");
    const [setDefault, setIsDefault] = useState(false);
    const [ranking, setRanking] = useState(group?.ranking || vacancy.groups.length+1);
    const [deleteViewOpen, setDeleteViewOpen] = useState(false);

    const onSave = () => {
        if (groupId) {
            ClientApi.request(putSuggestionGroupRouteConfig, {
                token,
                groupId,
                title,
                setDefault,
                ranking
            }).then(() => {
                dispatch(getVacancyAction(token, vacancyId, false)).then(() =>
                    dispatch(getSuggestionsForVacancyAction(token, vacancyId, suggestionRequestObject))
                );
                props.onClose()
            });
            return;
        }

        ClientApi.request(postSuggestionGroupRouteConfig, {
            token,
            vacancyId,
            title,
            setDefault,
            ranking
        }).then((result: ISuggestionGroup) => {
            dispatch(getVacancyAction(token, vacancyId, false));
            props.onClose();
            props.onAdd(result)
        });
    }

    return <>
        <DeleteSuggestionGroupView
            open={deleteViewOpen}
            vacancyId={vacancyId}
            groupId={groupId || 0}
            onClose={() => {
                setDeleteViewOpen(false);
                props.onClose();
            }}
        />
        <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
            <PaperRow>
                <CustomNumberInput
                    width={80}
                    label={'Ranking'}
                    value={ranking}
                    onChange={(value) => setRanking(value)}
                    error={Boolean(errors.ranking)}
                    helperText={errors.ranking}
                />
                <CustomTextField
                    autoFocus
                    required
                    label={'Titel der Gruppe'}
                    value={title}
                    onChange={(value) => setTitle(value)}
                    error={Boolean(errors.title)}
                    helperText={errors.title}
                />
            </PaperRow>
            {!groupId &&
                <PaperRow>
                    <CustomCheckboxInput
                        checked={setDefault}
                        onChange={() => setIsDefault(!setDefault)}
                        label={"als neue Standard-Gruppe festlegen"}
                    />
                </PaperRow>
            }
            <CustomDialogActions
                onClose={props.onClose}
                buttons={[{
                    void: () => setDeleteViewOpen(true),
                    hidden: !groupId,
                    label: translate('misc.buttons.delete')
                }, {
                    void: onSave,
                    label: translate('misc.buttons.save')
                }]}
            />
        </div>
    </>;
};

export default AddEditSuggestionForCandidateForm;
