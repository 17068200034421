import { ESortingOptions } from '../../definitions/components.definitions';
import {
    ECandidateEditViewTabs,
    ICandidateFilterApiProperties,
    ICandidateFilterMenu,
    ICandidateForm,
} from '../../definitions/candidatePage.definitions';
import { IApiBaseFilter, ISorting } from '../../definitions/app.definitions';
import {
    IAddAddressToCandidatePageForm,
    IAddMailToCandidatePageForm,
    IAddPhoneToCandidatePageForm,
    IAddUrlToCandidatePageForm,
    IDeleteAddressFromCandidatePageForm,
    IDeleteMailFromCandidatePageForm,
    IDeletePhoneFromCandidatePageForm,
    IDeleteUrlFromCandidatePageForm,
    ISetCandidateEditViewActiveTabAction,
    ISetCandidatePageAddDialogAction,
    ISetCandidatePageCandidateToDeleteAction,
    ISetCandidatePageCreateBirthdayMailAction,
    ISetCandidatePageCvResultToImportAction,
    ISetCandidatePageDocumentToEditAction,
    ISetCandidatePageEditViewActivityFastSearchAction,
    ISetCandidatePageFastSearchAction,
    ISetCandidatePageFilterAction,
    ISetCandidatePageFilterMenuAction,
    ISetCandidatePageForm,
    ISetCandidatePageRecruitingListFilterAction,
    ISetCandidatePageRecruitingListFilterMenuAction,
    ISetCandidatePageRecruitingSortingOptionAction,
    ISetCandidatePageResetVacancySearchFilterAction,
    ISetCandidatePageSortingOptionAction,
    ISetCandidatePageToggleFieldForIgnoreValidation,
    ISetCandidatePageVacancySearchFilterAction,
    ISetCandidatePageVacancySearchFilterMenuAction,
    ISetCandidatePageVacancySearchSortingOptionAction,
    IUpdateAddressInCandidatePageForm,
    IUpdateMailInCandidatePageForm,
    IUpdatePhoneInCandidatePageForm,
    IUpdateUrlInCandidatePageForm,
} from '../../definitions/redux/actions.definition';
import { IRecruitingFilter, IRecruitingFilterMenu } from '../../definitions/recruitingPage.definitions';
import {
    ICandidateAddress,
    ICandidateMailAddress,
    ICandidatePhoneNumber,
    ICandidateUrl,
    ICvParseResult,
} from '../../definitions/entities.definition';
import { FileObject } from 'material-ui-dropzone';
import { IWebVacancyFilter, IWebVacancyFilterMenu } from '../../definitions/webVacancyPage.definitions';

export const CANDIDATE_PAGE_SET_FILTER = "CANDIDATE_PAGE_SET_FILTER";
export const CANDIDATE_PAGE_SET_FORM = "CANDIDATE_PAGE_SET_FORM_VALUE";
export const CANDIDATE_PAGE_SET_SORTING_OPTION = "CANDIDATE_PAGE_SET_SORTING_OPTION";
export const CANDIDATE_PAGE_SET_FAST_SEARCH = "CANDIDATE_PAGE_SET_FAST_SEARCH";
export const CANDIDATE_PAGE_SET_ADD_DIALOG = "CANDIDATE_PAGE_SET_ADD_DIALOG";
export const CANDIDATE_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT = "CANDIDATE_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT";
export const CANDIDATE_PAGE_SET_CANDIDATE_TO_DELETE = "CANDIDATE_PAGE_SET_CANDIDATE_TO_DELETE";
export const CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER = "CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER";
export const CANDIDATE_PAGE_SET_FILTER_MENU = "CANDIDATE_PAGE_SET_FILTER_MENU";
export const CANDIDATE_PAGE_SET_RECRUITING_LIST_SORTING = "CANDIDATE_PAGE_SET_RECRUITING_LIST_SORTING";
export const CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER = "CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER";
export const CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER_MENU = "CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER_MENU";
export const CANDIDATE_PAGE_SET_ACTIVE_TAB_OF_EDIT_CANDIDATE_VIEW = "CANDIDATE_PAGE_SET_ACTIVE_TAB_OF_EDIT_CANDIDATE_VIEW";
export const CANDIDATE_PAGE_SET_CV_RESULT_TO_IMPORT = "CANDIDATE_PAGE_SET_CV_RESULT_TO_IMPORT";
export const CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER = "CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER";
export const CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER_MENU = "CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER_MENU";
export const CANDIDATE_PAGE_SET_VACANCY_SEARCH_SORTING = "CANDIDATE_PAGE_SET_VACANCY_SEARCH_SORTING";
export const CANDIDATE_PAGE_RESET_VACANCY_SEARCH_FILTER = "CANDIDATE_PAGE_RESET_VACANCY_SEARCH_FILTER";
export const CANDIDATE_PAGE_SET_CREATE_BIRTHDAY_MAIL = "CANDIDATE_PAGE_SET_CREATE_BIRTHDAY_MAIL";
export const CANDIDATE_PAGE_TOGGLE_FIELD_FOR_IGNORE_VALIDATION = "CANDIDATE_PAGE_TOGGLE_FIELD_FOR_IGNORE_VALIDATION";
export const CANDIDATE_PAGE_RESET_FIELDS_FOR_IGNORE_VALIDATION = "CANDIDATE_PAGE_RESET_FIELDS_FOR_IGNORE_VALIDATION";
export const CANDIDATE_PAGE_DELETE_CANDIDATE_MAIL_FROM_FORM = "CANDIDATE_PAGE_DELETE_CANDIDATE_MAIL_FROM_FORM";
export const CANDIDATE_PAGE_ADD_CANDIDATE_MAIL_TO_FORM = "CANDIDATE_PAGE_ADD_CANDIDATE_MAIL_TO_FORM";
export const CANDIDATE_PAGE_UPDATE_CANDIDATE_MAIL_IN_FORM = "CANDIDATE_PAGE_UPDATE_CANDIDATE_MAIL_IN_FORM";
export const CANDIDATE_PAGE_DELETE_CANDIDATE_ADDRESS_FROM_FORM = "CANDIDATE_PAGE_DELETE_CANDIDATE_ADDRESS_FROM_FORM";
export const CANDIDATE_PAGE_ADD_CANDIDATE_ADDRESS_TO_FORM = "CANDIDATE_PAGE_ADD_CANDIDATE_ADDRESS_TO_FORM";
export const CANDIDATE_PAGE_UPDATE_CANDIDATE_ADDRESS_IN_FORM = "CANDIDATE_PAGE_UPDATE_CANDIDATE_ADDRESS_IN_FORM";
export const CANDIDATE_PAGE_DELETE_CANDIDATE_PHONE_FROM_FORM = "CANDIDATE_PAGE_DELETE_CANDIDATE_PHONE_FROM_FORM";
export const CANDIDATE_PAGE_ADD_CANDIDATE_PHONE_TO_FORM = "CANDIDATE_PAGE_ADD_CANDIDATE_PHONE_TO_FORM";
export const CANDIDATE_PAGE_UPDATE_CANDIDATE_PHONE_IN_FORM = "CANDIDATE_PAGE_UPDATE_CANDIDATE_PHONE_IN_FORM";
export const CANDIDATE_PAGE_DELETE_CANDIDATE_URL_FROM_FORM = "CANDIDATE_PAGE_DELETE_CANDIDATE_URL_FROM_FORM";
export const CANDIDATE_PAGE_ADD_CANDIDATE_URL_TO_FORM = "CANDIDATE_PAGE_ADD_CANDIDATE_URL_TO_FORM";
export const CANDIDATE_PAGE_UPDATE_CANDIDATE_URL_IN_FORM = "CANDIDATE_PAGE_UPDATE_CANDIDATE_URL_IN_FORM";

export const setCandidatePageDeletePhoneFromFormAction = (index: number): IDeletePhoneFromCandidatePageForm => ({
    type: CANDIDATE_PAGE_DELETE_CANDIDATE_PHONE_FROM_FORM,
    payload: index
});

export const setCandidatePageAddPhoneToFormAction = (mail: ICandidatePhoneNumber): IAddPhoneToCandidatePageForm => ({
    type: CANDIDATE_PAGE_ADD_CANDIDATE_PHONE_TO_FORM,
    payload: mail
});

export const setCandidatePageUpdatePhoneInFormAction = (index: number, phoneNumber: ICandidatePhoneNumber): IUpdatePhoneInCandidatePageForm => ({
    type: CANDIDATE_PAGE_UPDATE_CANDIDATE_PHONE_IN_FORM,
    payload: {
        index,
        phoneNumber
    }
});

export const setCandidatePageDeleteMailFromFormAction = (index: number): IDeleteMailFromCandidatePageForm => ({
    type: CANDIDATE_PAGE_DELETE_CANDIDATE_MAIL_FROM_FORM,
    payload: index
});

export const setCandidatePageAddMailToFormAction = (mail: ICandidateMailAddress): IAddMailToCandidatePageForm => ({
    type: CANDIDATE_PAGE_ADD_CANDIDATE_MAIL_TO_FORM,
    payload: mail
});

export const setCandidatePageUpdateMailInFormAction = (index: number, mail: ICandidateMailAddress): IUpdateMailInCandidatePageForm => ({
    type: CANDIDATE_PAGE_UPDATE_CANDIDATE_MAIL_IN_FORM,
    payload: {
        index,
        mail
    }
});

export const setCandidatePageDeleteAddressFromFormAction = (index: number): IDeleteAddressFromCandidatePageForm => ({
    type: CANDIDATE_PAGE_DELETE_CANDIDATE_ADDRESS_FROM_FORM,
    payload: index
});

export const setCandidatePageAddAddressToFormAction = (address: ICandidateAddress): IAddAddressToCandidatePageForm => ({
    type: CANDIDATE_PAGE_ADD_CANDIDATE_ADDRESS_TO_FORM,
    payload: address
});

export const setCandidatePageUpdateAddressInFormAction = (index: number, address: ICandidateAddress): IUpdateAddressInCandidatePageForm => ({
    type: CANDIDATE_PAGE_UPDATE_CANDIDATE_ADDRESS_IN_FORM,
    payload: {
        index,
        address
    }
});

export const setCandidatePageDeleteUrlFromFormAction = (index: number): IDeleteUrlFromCandidatePageForm => ({
    type: CANDIDATE_PAGE_DELETE_CANDIDATE_URL_FROM_FORM,
    payload: index
});

export const setCandidatePageAddUrlToFormAction = (address: ICandidateUrl): IAddUrlToCandidatePageForm => ({
    type: CANDIDATE_PAGE_ADD_CANDIDATE_URL_TO_FORM,
    payload: address
});

export const setCandidatePageUpdateUrlInFormAction = (index: number, url: ICandidateUrl): IUpdateUrlInCandidatePageForm => ({
    type: CANDIDATE_PAGE_UPDATE_CANDIDATE_URL_IN_FORM,
    payload: {
        index,
        url
    }
});

export const setCandidatePageFormAction = (candidateFormChange: ICandidateForm): ISetCandidatePageForm => ({
    type: CANDIDATE_PAGE_SET_FORM,
    payload: candidateFormChange
});

export const resetCandidatePageFieldsForIgnoreValidation = () => ({
    type: CANDIDATE_PAGE_RESET_FIELDS_FOR_IGNORE_VALIDATION
});

export const setCandidatePageToggleFieldForIgnoreValidation = (field: string): ISetCandidatePageToggleFieldForIgnoreValidation => ({
    type: CANDIDATE_PAGE_TOGGLE_FIELD_FOR_IGNORE_VALIDATION,
    payload: field,
});

export const setCandidatePageCreateBirthdayMailAction = (id: number): ISetCandidatePageCreateBirthdayMailAction => ({
    type: CANDIDATE_PAGE_SET_CREATE_BIRTHDAY_MAIL,
    payload: id,
});

export const setCandidatePageVacancySearchFilterAction = (filter: Partial<IWebVacancyFilter>): ISetCandidatePageVacancySearchFilterAction => ({
    type: CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER,
    payload: filter
});

export const setCandidatePageVacancySearchFilterMenuAction = (filter: Partial<IWebVacancyFilterMenu>): ISetCandidatePageVacancySearchFilterMenuAction => ({
    type: CANDIDATE_PAGE_SET_VACANCY_SEARCH_FILTER_MENU,
    payload: filter
});

export const resetCandidatePageVacancySearchFilterAction = (): ISetCandidatePageResetVacancySearchFilterAction => ({
    type: CANDIDATE_PAGE_RESET_VACANCY_SEARCH_FILTER,
});

export const setCandidatePageVacancySearchSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCandidatePageVacancySearchSortingOptionAction => ({
    type: CANDIDATE_PAGE_SET_VACANCY_SEARCH_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCandidatePageCvToImportAction = (cvParserResult?: ICvParseResult, cvDocument?: FileObject): ISetCandidatePageCvResultToImportAction => ({
    type: CANDIDATE_PAGE_SET_CV_RESULT_TO_IMPORT,
    payload: !cvParserResult || !cvDocument ? undefined : {
        cvParserResult: cvParserResult,
        cvDocument: cvDocument
    },
});

export const setCandidateEditViewActiveTabAction = (tab: ECandidateEditViewTabs): ISetCandidateEditViewActiveTabAction => ({
    type: CANDIDATE_PAGE_SET_ACTIVE_TAB_OF_EDIT_CANDIDATE_VIEW,
    payload: tab,
});

export const setCandidatePageSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCandidatePageSortingOptionAction => ({
    type: CANDIDATE_PAGE_SET_SORTING_OPTION,
    payload: {
        propertyToSort,
        sortOption,
    },
});

export const setCandidatePageFilterAction = (filter: Partial<ICandidateFilterApiProperties>, sorting?: ISorting): ISetCandidatePageFilterAction => ({
    type: CANDIDATE_PAGE_SET_FILTER,
    payload: {
        filter,
        sorting
    },
});

export const setCandidatePageFilterMenuAction = (filterMenu: Partial<ICandidateFilterMenu>): ISetCandidatePageFilterMenuAction => ({
    type: CANDIDATE_PAGE_SET_FILTER_MENU,
    payload: filterMenu,
});

export const setCandidatePageAddDialogAction = (open: boolean): ISetCandidatePageAddDialogAction => ({
    type: CANDIDATE_PAGE_SET_ADD_DIALOG,
    payload: open,
});

export const setCandidatePageFastSearchAction = (fastSearchValue: string): ISetCandidatePageFastSearchAction => ({
    type: CANDIDATE_PAGE_SET_FAST_SEARCH,
    payload: fastSearchValue,
});

export const setCandidatePageCandidateToDeleteAction = (candidateId: number): ISetCandidatePageCandidateToDeleteAction => ({
    type: CANDIDATE_PAGE_SET_CANDIDATE_TO_DELETE,
    payload: candidateId,
});

export const setCandidatePageDocumentToEditAction = (documentId: number): ISetCandidatePageDocumentToEditAction => ({
    type: CANDIDATE_PAGE_SET_EDIT_VIEW_CURRENT_DOCUMENT,
    payload: documentId,
});

export const setCandidatePageActivityFilterAction = (filter: Partial<IApiBaseFilter>): ISetCandidatePageEditViewActivityFastSearchAction => ({
    type: CANDIDATE_PAGE_SET_EDIT_VIEW_ACTIVITY_FILTER,
    payload: filter
});

export const setCandidatePageRecruitingListFilterAction = (filter: Partial<IRecruitingFilter>): ISetCandidatePageRecruitingListFilterAction => ({
    type: CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER,
    payload: filter
});

export const setCandidatePageRecruitingListFilterMenuAction = (filter: Partial<IRecruitingFilterMenu>): ISetCandidatePageRecruitingListFilterMenuAction => ({
    type: CANDIDATE_PAGE_SET_RECRUITING_LIST_FILTER_MENU,
    payload: filter
});

export const setCandidatePageRecruitingsSortingOptionAction = (propertyToSort: string, sortOption: ESortingOptions): ISetCandidatePageRecruitingSortingOptionAction => ({
    type: CANDIDATE_PAGE_SET_RECRUITING_LIST_SORTING,
    payload: {
        propertyToSort,
        sortOption,
    },
});
